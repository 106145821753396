import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { remove } from 'lodash';
import { ApplicationsState, Application } from './types';
import * as actions from './actions';

export type ActionsType = ActionType<typeof actions>;

const initialState: ApplicationsState = {
  data: [],
  hasLoaded: false,
};

const reducer: Reducer<ApplicationsState> = (state = initialState, action: ActionsType): ApplicationsState => {
  switch (action.type) {
    case getType(actions.fetchApplicationsAction):
      return { ...state, ...action.payload };
    case getType(actions.deleteApplicationAction):
      return {
        ...state,
        data: remove(state.data, (app: Application) => app.id !== action.payload),
      };
    case getType(actions.createApplicationAction):
      return { ...state, data: [action.payload, ...state.data] };
    case getType(actions.updateApplicationAction):
      return {
        ...state,
        data: state.data.map((project: Application) => (project.id === action.payload.id ? action.payload : project)),
      };
    case getType(actions.addCertificateAction):
      return {
        ...state,
        data: state.data.map((app: Application) => {
          if (app.id === action.payload.application_id) {
            return { ...app, certificates: app.certificates ? [...app.certificates, action.payload] : [action.payload] };
          }
          return app;
        }),
      };
    case getType(actions.updateCertificateAction):
      return {
        ...state,
        data: state.data.map((app: Application) => {
          if (app.id === action.payload.application_id) {
            return {
              ...app,
              certificates: app.certificates
                ? app.certificates.map(cert => {
                    if (cert.id === action.payload.id) {
                      return action.payload;
                    }
                    return cert;
                  })
                : [action.payload],
            };
          }
          return app;
        }),
      };
    default: {
      return state;
    }
  }
};

export { reducer as applicationsReducer };

import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { BillingState } from './types';
import * as actions from './actions';

export type ActionsType = ActionType<typeof actions>;

const initialState: BillingState = {
  offers: [],
  paymentProfile: undefined,
  paymentMethods: [],
  userSubscription: {},
  userInvoices: [],
  hasLoaded: false,
};

const reducer: Reducer<BillingState> = (state = initialState, action: ActionsType): BillingState => {
  switch (action.type) {
    case getType(actions.fetchOffersAction):
      return { ...state, ...action.payload };
    case getType(actions.fetchPaymentMethodsAction):
      return { ...state, ...action.payload };
    case getType(actions.fetchUserSubscriptionAction):
      return { ...state, ...action.payload };
    case getType(actions.fetchPaymentProfileAction):
      return { ...state, ...action.payload };
    case getType(actions.fetchUserInvoicesAction):
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

export { reducer as billingReducer };

import styled from 'styled-components';
import backArrow from '../../assets/icons/back-arrow.svg';
import backArrowDisabled from '../../assets/icons/back-arrow-disabled.svg';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';

export const IconBack = styled.div`
  height: 18px;
  width: 10px;
  background: url('${backArrowDisabled}') center no-repeat;
`;

export const BackButtonStyled = styled(IDPartnerButton)`
  border: 1px solid #9da3b2 !important;

  &:hover {
    border: 1px solid #2c5aec !important;
    ${IconBack} {
      background: url('${backArrow}') center no-repeat;
    }
  }
`;

import styled from 'styled-components';
import { IDPartnerButton } from '../idpartner-button/idpartner-button';

export const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 200px;
  padding: 10px;
  box-sizing: initial;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  position: relative;
  border: 2px solid #e0e2e6;
`;

export const SaturationWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`;

export const ColorBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ColorBlock = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 16px;
  border-radius: 8px;
  flex-shrink: 0;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const HueWrapper = styled.div`
  position: relative;
  height: 10px;
  width: 100%;
`;

export const StyledButton = styled(IDPartnerButton)`
  height: 32px;
`;

import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { AuthState } from './types';
import * as authActions from './actions';

export type AuthActions = ActionType<typeof authActions>;

const initialState: AuthState = {
  token: null,
  errors: '',
  account: {
    email: null,
    email_verified_at: null,
    id: null,
    company_name: null,
    company_url: null,
    phone: null,
    password: null,
    first_name: null,
    last_name: null,
    lei: null,
    lei_verified_at: null,
    merchant_code: null,
    domain_name: null,
    domain_verification_token: null,
    domain_verified_at: null,
    totp_enabled: false,
    terms_agreed: false,
    kyb_verified_at: null,
  },
};

const reducer: Reducer<AuthState> = (state = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case getType(authActions.authAsyncActions.failure): {
      return { ...state, errors: action.payload };
    }
    case getType(authActions.authAsyncActions.success): {
      return { ...state, token: action.payload };
    }
    case getType(authActions.fetchAccountAction): {
      return { ...state, account: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authReducer };

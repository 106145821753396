import styled from 'styled-components';
import copyIcon from '../../../assets/icons/copy-icon.svg';
import checkIcon from '../../../assets/icons/copy-check-black.svg';

export const StyledChildren = styled.div`
  margin-right: 5px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url('${copyIcon}') center no-repeat;
`;

export const CheckOutlinedStyledIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url('${checkIcon}') center no-repeat;
`;

export const CopyRow = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;

  ${StyledIcon} {
    //opacity: 0;
    transition: opacity 0.3s;
  }

  // &:hover {
  //   ${StyledIcon} {
  //     opacity: 1;
  //   }
  // }
`;

import styled, { css } from 'styled-components';
import { bodyLargeRegular, h1, h3 } from '../../common.css';
import statusSvg from '../../assets/icons/verification-status.svg';
import statusPassedSvg from '../../assets/icons/verification-status-passed.svg';

export const VerificationsListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const VerificationsListForm = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
  text-align: center;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const VerificationsListItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: left;
`;

export const VerificationItemContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 40px;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #d9dde5;
  background: #fff;
  box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, 0.05);

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ItemMarker = styled.div<{ isPassed: boolean }>`
  width: 50px;
  height: 50px;
  background: url('${statusSvg}') center no-repeat;
  margin-right: 50px;
  flex-shrink: 0;
  ${props =>
    props.isPassed &&
    css`
      background: url('${statusPassedSvg}') center no-repeat;
    `}
`;

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 32px;
  max-width: 400px;
`;

export const ItemButtonWrapper = styled.div`
  width: 104px;
`;

export const ItemTitle = styled.div`
  ${h3};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 8px;
`;

export const ItemDescription = styled.div`
  color: ${props => props.theme['gray-400']};
  ${bodyLargeRegular};
`;

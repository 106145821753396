import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { UsersResponse } from './types';
import { Api } from '../../modules/utils/api';

import { withAuth } from '../util';
import { getMembersAction, getMoreMembersAction } from './actions';

export const fetchMembers = (cursor: string | undefined, pageSize: number) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getGroupMembers(undefined, pageSize).then((result: AxiosResponse<UsersResponse>) => {
      dispatch(getMembersAction(result.data));
    }),
  );

export const fetchMoreMembers = (cursor: string | undefined, pageSize: number) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getGroupMembers(cursor, pageSize).then((result: AxiosResponse<UsersResponse>) => {
      dispatch(getMoreMembersAction(result.data));
    }),
  );

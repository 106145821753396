import React, { FC, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PageHeader } from '../page-header/page-header';
import { IDPartnerAlert } from '../ui/idpartner-alert/idpartner-alert';
import * as El from './billing-form.css';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { Subscription } from '../subscription/subscription';
import { PaymentMethod } from '../payment-method/payment-method';
import { AddPaymentMethodForm } from '../add-payment-method-form/add-payment-method-form';
import { Api } from '../../modules/utils/api';

import { ApplicationState } from '../../redux/store';

import { fetchPaymentMethods as fetchPaymentMethodsRedux, fetchPaymentProfile as fetchPaymentProfileRedux, fetchUserSubscription as fetchUserSubscriptionRedux } from '../../redux/billing/async';
import { Invoice } from '../invoice/invoice';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const mapStateToProps = ({ billing }: ApplicationState) => ({
  userSubscription: billing.userSubscription,
  paymentMethods: billing.paymentMethods,
  paymentProfile: billing.paymentProfile,
  userInvoices: billing.userInvoices,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPaymentProfile: bindActionCreators(fetchPaymentProfileRedux, dispatch),
  fetchUserSubscription: bindActionCreators(fetchUserSubscriptionRedux, dispatch),
  fetchPaymentMethods: bindActionCreators(fetchPaymentMethodsRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Billing: FC<AllProps> = ({ paymentProfile, paymentMethods, userSubscription, userInvoices, fetchPaymentProfile, fetchUserSubscription, fetchPaymentMethods }) => {
  const [isShowForm, setIsShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleError = (error: string) => {
    setErrorMessage(error);
    fetchPaymentMethods();
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 5000);
  };

  const handleCancelSubscription = async (id: string) => {
    const api = new Api();
    await api.cancelSubscription(id);
    fetchUserSubscription();
  };

  const handleAddPaymentMethod = async () => {
    setIsShowForm(false);
    fetchPaymentMethods();
  };

  const paymentMethod = paymentMethods?.find(method => method.id === userSubscription?.default_payment_method);
  const nextInvoiceAtDate = new Date(userSubscription?.current_period_end * 1000).toDateString();

  return (
    <>
      <PageHeader title="Billing" />
      {errorMessage && <IDPartnerAlert type="error" showIcon description={errorMessage} />}
      <El.SectionWrapper>
        <El.FormSubtitle>Payment account</El.FormSubtitle>
        <El.AccountWrapper>
          <El.AccountRow>
            <div>Name:</div>
            <div>{paymentProfile.name}</div>
          </El.AccountRow>
          <El.AccountRow>
            <div>Email:</div>
            <div>{paymentProfile.email}</div>
          </El.AccountRow>
        </El.AccountWrapper>
      </El.SectionWrapper>

      <El.SectionWrapper>
        <El.FormSubtitle>Subscription</El.FormSubtitle>
        <Subscription
          name={userSubscription.plan?.nickname}
          defaultPaymentMethod={paymentMethod ? `${paymentMethod?.card.brand} **** ${paymentMethod?.card.last4}` : ''}
          nextInvoiceAt={nextInvoiceAtDate}
          onCancel={() => handleCancelSubscription(userSubscription?.id)}
          status={userSubscription?.status}
          amountRemaining={userSubscription?.upcoming_invoice?.amount_remaining}
        />
      </El.SectionWrapper>

      <El.SectionWrapper>
        <El.FormSubtitle>Invoices</El.FormSubtitle>
        <El.InvoicesWrapper>
          {userInvoices?.map((invoice: any) => (
            <Invoice
              name={invoice.number}
              status={invoice.status ? 'paid' : 'pending'}
              createdAt={invoice.created}
              invoiceLink={invoice.invoice_pdf}
              amountDue={invoice.amount_due}
              amountPaid={invoice.amount_paid}
            />
          ))}
        </El.InvoicesWrapper>
      </El.SectionWrapper>

      <El.SectionWrapper>
        <El.FormSubtitle>Payment methods</El.FormSubtitle>
        {paymentMethods?.length ? (
          <El.PaymentMethodsWrapper>
            {paymentMethods.map(method => (
              <PaymentMethod
                key={method.id}
                cardBrand={method.card.brand}
                cardLast4={method.card.last4}
                holderName={method.billing_details.name}
                expiresData={`${method.card.exp_month}/${method.card.exp_year}`}
              />
            ))}
          </El.PaymentMethodsWrapper>
        ) : (
          <div>No payment methods added</div>
        )}
      </El.SectionWrapper>

      <El.SectionWrapper>
        {!isShowForm && <IDPartnerButton onClick={() => setIsShowForm(true)}>Add new payment method</IDPartnerButton>}
        {isShowForm && (
          <Elements stripe={stripePromise}>
            <AddPaymentMethodForm onSuccess={handleAddPaymentMethod} onError={handleError} />
          </Elements>
        )}
      </El.SectionWrapper>
    </>
  );
};

export const BillingForm = connect(mapStateToProps, mapDispatchToProps)(Billing);

export const addProtocolToUrl = (url: string) => {
  if (!url) {
    return url;
  }
  const hasProtocol = /^(http(s)?:\/\/)/gim;
  if (url.match(hasProtocol)) {
    return url;
  }
  return `https://${url}`;
};

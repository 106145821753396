import styled, { css } from 'styled-components';
import { bodySmallRegular, h2 } from '../../common.css';
import { EventType } from '../../redux/logs/types';

export const LogListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const getStylesByEventType = (eventType: EventType) => {
  switch (eventType) {
    case 'verification.failed':
      return css`
        color: ${props => props.theme.error};
        background: ${props => props.theme['red-transparent']};
      `;
    case 'verification.started':
    case 'verification.payment_processing_started':
      return css`
        color: ${props => props.theme['warning-color']};
        background: ${props => props.theme['alert-warning']};
      `;
    case 'verification.idtoken_issued':
    case 'verification.payment_details_info_issued':
    case 'verification.userinfo_issued':
      return css`
        color: ${props => props.theme.success};
        background: ${props => props.theme['green-transparent']};
      `;
    default:
      return css`
        color: ${props => props.theme['gray-900']};
        background: ${props => props.theme['gray-transparent']};
      `;
  }
};

export const Status = styled.div<{ status: EventType }>`
  background: ${props => props.theme['green-transparent']};
  border-radius: 4px;
  padding: 2px 8px;
  color: ${props => props.theme.success};
  text-align: center;
  display: inline-block;
  text-transform: capitalize;

  ${props => getStylesByEventType(props.status)}
`;

export const LogsStatsItemContainer = styled.div`
  padding: 16px;
  background: ${props => props.theme['gray-50']};
  border: 1px solid ${props => props.theme['border-100']};
  border-radius: 8px;
  height: 82px;
  width: 100%;
  text-align: center;
`;

export const LogsStatsTitle = styled.div`
  ${h2}
`;

export const LogsStatsDescritpion = styled.div`
  margin-top: 2px;
  ${bodySmallRegular};
  color: ${props => props.theme['gray-400']};
`;

export const LogsStats = styled.div`
  display: flex;
  margin-bottom: 32px;

  ${LogsStatsItemContainer}:not(:last-child) {
    margin-right: 16px;
  }
`;

export const LogsDownloadButton = styled.div`
  margin-bottom: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
`;

export const RightCorner = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin-right: 16px;
`;

export const PeriodsWrapper = styled.div`
  margin-right: 16px;
`;

export const DatepickerWrapper = styled.div`
  width: 280px;
`;

import React, { FC } from 'react';
import { Application } from '../../../../redux/applications/types';
import * as El from './applications-grid.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { AppType } from '../table-heading/types';
import { Icon } from '../../../../components/icon/icon';
import plusWhiteIcon from '../../../../assets/icons/add-button-white.svg';
import { AccountType } from '../../../../redux/auth/types';
import { VerificationsList } from '../../../../components/verifications-list/verifications-list';
import { ApplicationItem } from './application-item';

type ApplicationsGridProps = {
  applications: Application[];
  onSelectApp: (app: Application['id']) => void;
  onDeleteApp: (appId: Application['id']) => void;
  currentAppType: AppType;
  onNewAppButtonClick: () => void;
  account: AccountType;
};
export const ApplicationsGrid: FC<ApplicationsGridProps> = ({ applications, onSelectApp, currentAppType, onNewAppButtonClick, account, onDeleteApp }) => {
  const isAccountProductionReady = currentAppType === 'production' && account.domain_verified_at && account.merchant_code && account.kyb_verified_at;
  const isProductionApplication = currentAppType === 'production';
  if (isProductionApplication && !isAccountProductionReady) {
    return (
      <El.NotReadyForProdWrapper>
        <El.TextWrapper>
          <El.ProdAccessIcon />
          <El.NoAppsTitle>Unlock Your Path to Production</El.NoAppsTitle>
          <El.NoAppsText>
            To Gain Access to Production Banks, Complete the Following Requirements. When You&apos;re Ready to Begin, Please Contact <a href="mailto:support@idpartner.com.">support@idpartner.com</a>.
          </El.NoAppsText>
        </El.TextWrapper>

        <VerificationsList />
      </El.NotReadyForProdWrapper>
    );
  }

  if (!applications.length) {
    return (
      <El.NoAppsScreenWrapper>
        <El.NoAppsIcon />
        <El.NoAppsTitle>{currentAppType === 'production' ? 'No Production App Yet!' : 'No Sandbox Apps Created'}</El.NoAppsTitle>
        <El.NoAppsText>
          {currentAppType === 'production'
            ? "Create your first Production App with IDPartner's trusted network and secure digital identity verification for your users."
            : "Test and refine your identity verification processes. Initiate your first Sandbox App using IDPartner's trusted framework."}
        </El.NoAppsText>
        <IDPartnerButton icon={<Icon url={plusWhiteIcon} iconWidth="24" iconHeight="24" />} type="primary" onClick={onNewAppButtonClick}>
          {currentAppType === 'production' ? 'CREATE PRODUCTION APPLICATION' : 'CREATE SANDBOX APPLICATION'}
        </IDPartnerButton>
      </El.NoAppsScreenWrapper>
    );
  }

  return (
    <El.ApplicationsGridContainer>
      {applications.map(app => (
        <ApplicationItem application={app} onSelectApp={onSelectApp} onDeleteApp={onDeleteApp} />
      ))}
    </El.ApplicationsGridContainer>
  );
};

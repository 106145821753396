import styled from 'styled-components';
import { h1 } from '../../common.css';

export const AuthSuccessStyled = styled.div`
  max-width: 448px;
  margin-left: auto;
  margin-right: auto;
`;

export const Illustration = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export const Title = styled.div`
  ${h1};
  margin-bottom: 16px;
  text-align: center;
`;

export const Message = styled.div`
  font-size: 18px;
  font-family: Public Sans;
  line-height: 28px;
  color: ${({ theme }) => theme['gray-400']};
  margin-bottom: 48px;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
`;

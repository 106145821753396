import styled from 'styled-components';
import { Menu } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import applicationsIconSvg from '../../../../assets/icons/applications-icon.svg';
import documentationIconSvg from '../../../../assets/icons/documentation.svg';
import externalLinkIcon from '../../../../assets/icons/external-link-icon.svg';
import logsIcon from '../../../../assets/icons/logs-icon.svg';
import buildIcon from '../../../../assets/icons/build-icon.svg';
import onboardingIcon from '../../../../assets/icons/onboarding-icon.svg';
import trustPlatformIcon from '../../../../assets/icons/trust-platform-icon.svg';
import billingIcon from '../../../../assets/icons/billing-icon.svg';

export const SidebarMenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    margin-bottom: 0px !important;
    padding: 8px 8px 8px 32px !important;
  }
  & .ant-menu-title-content {
    display: flex;
    align-items: flex-end;

    & a {
      font-family: Public Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 154%;
    }
  }
`;

const SidebarIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
export const ApplicationsIcon = styled(SidebarIcon)`
  background: url('${applicationsIconSvg}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const TeamIcon = styled(TeamOutlined)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  &:hover {
    background: none;
  }
`;

export const DocumentationIcon = styled(SidebarIcon)`
  background: url('${documentationIconSvg}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const BuildIcon = styled(SidebarIcon)`
  background: url('${buildIcon}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const ExternalLinkIcon = styled.div`
  width: 16px;
  height: 16px;
  background: url('${externalLinkIcon}') center no-repeat;
  position: absolute;
  top: 0;
  right: -12px;
`;

export const LogsIcon = styled(SidebarIcon)`
  background: url('${logsIcon}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const TrustPlatformIcon = styled(SidebarIcon)`
  background: url('${trustPlatformIcon}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const OnboardingIcon = styled(SidebarIcon)`
  background: url('${onboardingIcon}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const BillingIcon = styled(SidebarIcon)`
  background: url('${billingIcon}') center no-repeat;
  &:hover {
    background: none;
  }
`;

export const LinkContainer = styled.a`
  position: relative;
  display: block;
`;

export const SidebarMenuStyled = styled(Menu)`
  border: none;
  background: ${props => props.theme['darkblue-900']};

  ${SidebarMenuItem} {
    display: flex;
    align-items: center;
    background: none;
    height: 64px;
    padding: 12px 18px;
    padding-left: 44px;
    cursor: pointer;

    &:hover,
    &.selected {
      background: ${props => props.theme['blue-900']};
    }

    a {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      span {
        margin-right: 10px;
      }
    }
  }
`;

import styled from 'styled-components';
import { Layout } from 'antd';
import logoutIcon from '../../../../assets/icons/logout.svg';

const { Sider, Content } = Layout;

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const StyledSider = styled(Sider)`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  border-right: 1px solid ${props => props.theme['border-primary-color']};
  justify-content: space-between;
  background: ${props => props.theme['darkblue-900']};
  color: #ffffff;
  overflow-y: auto;
  height: 100%;
`;

export const SiderContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledContent = styled(Content)`
  overflow-y: auto;
`;

export const Logo = styled.div`
  display: flex;
  margin-bottom: 46px;
  padding-left: 32px;
`;

export const SidebarMenuContainer = styled.div``;

export const BottomBlockContainer = styled.div`
  padding: 0 24px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const LogoutButton = styled.a`
  color: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 154%;
  padding-left: 28px;
  background-image: url('${logoutIcon}');
  background-position: left;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
`;

export const PageWrapper = styled.div`
  padding: 40px 64px;
`;

import React, { FC, useState } from 'react';
import { Form } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import * as El from './otp-validate-form.css';
import { IDPartnerAlert } from '../ui/idpartner-alert/idpartner-alert';
import { IDPartnerForm } from '../ui/idpartner-form/idpartner-form';
import { IDPartnerInput } from '../ui/idpartner-input/idpartner-input';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { BackButton } from '../back-button/back-button';

export const OtpValidateForm: FC = () => {
  const [error, setError] = useState(null);
  const history = useHistory();

  const onFinish = async (values: any) => {
    try {
      setError(null);
      await axios.post(`${process.env.DASHBOARD_API_SERVICE_URL}/auth/totp/validate`, values, { withCredentials: true });
      history.push('/');
    } catch (e) {
      setError(e.response.data.error);
    }
  };

  const handleBackClick = () => {
    history.push('/');
  };

  return (
    <>
      <El.FormTitle>Enter Security Code</El.FormTitle>
      {error && <IDPartnerAlert showIcon type="error" description={error} />}
      <IDPartnerForm>
        <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
          <El.FormDescription>Please type the code displayed on your authenticator app from your device.</El.FormDescription>
          <Form.Item name="token" rules={[{ required: true, message: 'Please enter a token!' }]}>
            <IDPartnerInput placeholder="Enter 6-digit code" />
          </Form.Item>
          <Form.Item>
            <El.ButtonsWrapper>
              <BackButton onClick={handleBackClick} />
              <IDPartnerButton type="primary" htmlType="submit" block>
                SUBMIT
              </IDPartnerButton>
            </El.ButtonsWrapper>
          </Form.Item>
        </Form>
      </IDPartnerForm>
    </>
  );
};

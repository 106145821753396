import styled from 'styled-components';
import { bodySmallMedium } from '../../../../../common.css';
import tooltipIcon from '../../../../../assets/icons/tooltip-icon-big.svg';

export const AddCertificateFormStyled = styled.div`
  & .ant-form-item-optional {
    font-size: 0;
    display: flex !important;
    align-items: end !important;

    &:after {
      content: 'Optional';
      ${bodySmallMedium};
      color: ${props => props.theme['gray-300']};
    }
  }
`;

export const TerminalWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Label = styled.div`
  display: flex;
  color: #232733;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  gap: 8px;
`;

export const LabelNumber = styled.div`
  border-radius: 100px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #232733;

  /* Body Small */
  font-family: Public Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 21.56px */
`;

export const TooltipIcon = styled.div`
  background: url('${tooltipIcon}') center no-repeat;
  width: 24px;
  height: 24px;
`;

export const Footer = styled.div`
  margin-top: 48px;
  display: flex;
  width: 100%;
  gap: 16px;
`;

import React, { FC } from 'react';
import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as El from './auth-layout.css';

export const AuthLayout: FC<React.PropsWithChildren<any>> = ({ children }) => (
  <Layout>
    <Content>
      <Row>
        <Col xs={24} sm={12}>
          <El.FormContainer>
            <El.TopSpace />
            <El.FormWrapper>
              <El.Logo />
              {children}
            </El.FormWrapper>
          </El.FormContainer>
        </Col>
        <Col xs={24} sm={12}>
          <El.ImageContainer>
            <El.ImageFooter>
              <El.Link href="https://www.idpartner.com/privacy-policy" target="_blank">
                Privacy Policy
              </El.Link>
              <El.Divider />
              <El.Link href="https://www.idpartner.com/terms-of-service" target="_blank">
                Terms and conditions
              </El.Link>
            </El.ImageFooter>
          </El.ImageContainer>
        </Col>
      </Row>
    </Content>
  </Layout>
);

import styled from 'styled-components';
import searchIcon from '../../../../assets/icons/search-icon.svg';

export const TableHeadingStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media screen and (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

export const Col = styled.div``;

export const SearchIcon = styled.div`
  width: 18px;
  height: 18px;
  background: url('${searchIcon}') center no-repeat;
  margin-right: 10px;
`;

export const RightCorner = styled.div`
  display: flex;
  gap: 15px;
`;

import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as El from './pages.css';
import { fetchAccount as fetchAccountRedux, updateAccount as updateAccountRedux } from '../redux/auth/async';
import { ApplicationState } from '../redux/store';
import { IDPartnerButton } from '../components/ui/idpartner-button/idpartner-button';
import { IDPartnerModal } from '../components/ui/idpartner-modal/idpartner-modal';
import { AsyncComponent } from '../components/async-component/async-component';

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccount: bindActionCreators(updateAccountRedux, dispatch),
  fetchAccount: bindActionCreators(fetchAccountRedux, dispatch),
});

type AllProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const TermsAndConditionsPage: FC<AllProps> = ({ account, updateAccount, fetchAccount }) => {
  const history = useHistory();
  const onAgree = async () => {
    await updateAccount({
      ...account,
      terms_agreed: true,
    });
    history.push('/');
  };
  const onDisagree = () => {
    window.location.replace(`${process.env.DASHBOARD_API_SERVICE_URL}/auth/logout`);
  };

  return (
    <AsyncComponent showError={false} message="" getAsyncActions={() => [fetchAccount(account.id)]}>
      <El.TermsWrapper>
        <IDPartnerModal
          open={true}
          onCancel={onDisagree}
          footer={
            <El.TermsButtonsWrapper>
              <IDPartnerButton size="large" type="default" block onClick={onDisagree}>
                I don’t agree
              </IDPartnerButton>
              <IDPartnerButton size="large" type="primary" block onClick={onAgree}>
                I agree
              </IDPartnerButton>
            </El.TermsButtonsWrapper>
          }
        >
          <El.FormTitle>User agreement update</El.FormTitle>
          <El.TermsConditionsText>
            Please read our{' '}
            <a href={process.env.TERMS_URL} target="_blank">
              Sandbox Terms and Conditions
            </a>{' '}
            and click the "I agree" button.
          </El.TermsConditionsText>
        </IDPartnerModal>
      </El.TermsWrapper>
    </AsyncComponent>
  );
};

export const TermsAndConditions = connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsPage);

import styled from 'styled-components';
import { ReloadOutlined } from '@ant-design/icons';
import arrowWhite from './assets/white-arrow-right.svg';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ImageContainer = styled.div`
  margin-left: 10px;
  max-height: 100%;
  height: 100%;
  width: auto;
  justify-content: start;
  display: flex;
  align-items: center;
`;
export const Image = styled.img`
  max-height: inherit;
  max-width: 100%;
  padding: 5px 0;
`;
export const BrandText = styled.div`
  font-size: 18px;
  margin-left: 15px;

  font-weight: 500;
  text-align: left;
  flex: 1;
`;

export const ApplicationName = styled.span`
  margin-left: 5px;
`;

export const SwitchIcon = styled.div`
  vertical-align: top;
  display: inline-block;
  position: relative;
  font-size: 12px;
  margin-left: 2px;
`;

export const Button = styled.div`
  height: 56px;
  max-height: 56px;
  cursor: pointer;
  width: 100% !important;
  background-color: ${props => props.theme.backgroundColor};
  border-radius: ${props => props.theme.borderRadius};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border: 1px solid transparent;
  font-size: ${props => props.theme.fontSize};
  font-weight: bold;
  min-width: ${props => props.theme.minWidth};
  padding: 5px 10px;

  &:hover {
    opacity: 0.8;
    curson: pointer;
  }
`;

export const TextContainer = styled.div`
  color: ${props => props.theme.textColor};
  height: 100%;
  white-space: nowrap;
  align-content: center;
  align-items: center;
  font-weight: bold;
  font-size: clamp(5px, 7vw, 20px);
  display: flex;
  width: 100%;
  width: 100%;
  display: flex;
  position: relative;
`;
export const StyledIcon = styled(ReloadOutlined)`
  color: ${props => props.theme.textColor};
  margin-left: 5px;
`;

export const LeftCornerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonArrow = styled.div`
  background: url('${arrowWhite}') center no-repeat;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
`;

export const About = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  font-weight: 400;
  color: rgb(170, 174, 182);
  margin-top: 16px;
  margin-bottom: 12px;
`;
export const Footer = styled.div`
  height: 28px;

  align-items: center;
  justify-content: space-between;
`;

import { createAction } from 'typesafe-actions';
import { ProvidersTypes, Provider } from './types';

export const fetchProvidersAction = createAction(ProvidersTypes.FETCH_PROVIDERS, resolve => (data: { data: Provider[]; hasLoaded: boolean }) => resolve(data));

export const updateProviderAction = createAction(ProvidersTypes.UPDATE_PROVIDER, resolve => (model: Provider) => resolve(model));

export const createProviderAction = createAction(ProvidersTypes.CREATE_PROVIDER, resolve => (model: Provider) => resolve(model));

export const deleteProviderAction = createAction(ProvidersTypes.DELETE_PROVIDER, resolve => (id: number) => resolve(id));

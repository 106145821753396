import React, { FC, PropsWithChildren, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as El from './code-copy.css';

export const CodeCopy: FC<PropsWithChildren> = ({ children }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboard text={children as string} onCopy={handleCopy}>
      <El.CodeWrapper>
        <El.CodeTextWrapper>{children}</El.CodeTextWrapper>
        <El.CopyButton isChecked={copied} />
      </El.CodeWrapper>
    </CopyToClipboard>
  );
};

import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import mixpanel from 'mixpanel-browser';
import { AsyncComponent } from '../../../async-component/async-component';
import { ApplicationState } from '../../../../redux/store';
import { fetchAccount } from '../../../../redux/auth/async';
import { resetError } from '../../../../redux/error/actions';
import { PropsFromDispatch, PropsFromState, TMainLayoutProps } from './types';
import { StyledSider, StyledContent, StyledLayout, Logo, SidebarMenuContainer, BottomBlockContainer, SiderContent, LogoutButton } from './main-layout.css';
import logo from '../../../../assets/logo-white.svg';
import { SidebarMenu } from '../sidebar-menu/sidebar-menu';
import { SidebarUserAccount } from '../sidebar-user-account/sidebar-user-account';

const mapStateToProps = ({ auth, error }: ApplicationState) => ({
  account: auth.account,
  token: auth.token,
  errorMessage: error.message,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccount: bindActionCreators(fetchAccount, dispatch),
  resetError: bindActionCreators(resetError, dispatch),
});

type AllProps = PropsFromState & PropsFromDispatch & TMainLayoutProps;

const MainLayout = ({ errorMessage, resetError, children, fetchAccount, account }: AllProps) => {
  useEffect(() => {
    if (errorMessage) {
      notification.error({
        message: 'Something went wrong',
        description: errorMessage,
        onClose: () => {
          resetError();
        },
      });
    }
  }, [errorMessage]);

  return (
    <AsyncComponent showError={false} message="" getAsyncActions={() => [fetchAccount(account.id)]}>
      <StyledLayout>
        <StyledSider width={274} breakpoint="lg" collapsedWidth="0">
          <SiderContent>
            <div>
              <Logo>
                <img src={logo} alt="IDPartner Logo" />
              </Logo>
              <SidebarMenuContainer>
                <SidebarMenu />
              </SidebarMenuContainer>
            </div>

            <BottomBlockContainer>
              <SidebarUserAccount firstName={account.first_name} lastName={account.last_name} companyName={account.company_name} email={account.email} />
              <LogoutButton onClick={() => mixpanel.track('dashboard_client:click:logout')} href={`${process.env.DASHBOARD_API_SERVICE_URL}/auth/logout`}>
                Sign out
              </LogoutButton>
            </BottomBlockContainer>
          </SiderContent>
        </StyledSider>
        <StyledContent>{children}</StyledContent>
      </StyledLayout>
    </AsyncComponent>
  );
};
export const MainLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(MainLayout);

const isTrue = (value: string | boolean | undefined): boolean => {
  return (typeof value === 'string' && value?.toLowerCase() === 'true') || (typeof value === 'boolean' && value);
};

const isFeatureFlagEnabled = (featureFlagName: string): boolean => {
  return isTrue(featureFlagName ? process.env[featureFlagName] : undefined);
};

const isFeatureFlagEnabledForUser =
  (featureFlagName: string) =>
  (email: string): boolean => {
    const commaSeparatedEmailList = process.env[featureFlagName];

    if (!commaSeparatedEmailList || commaSeparatedEmailList === 'none') {
      return false;
    }

    if (commaSeparatedEmailList === 'all') {
      return true;
    }

    const emailList = commaSeparatedEmailList.split(',').map(email => email.trim());
    return emailList.includes(email.trim());
  };

export const featureFlags = {
  isOnboardingEnabled: isFeatureFlagEnabled('FEATURE_FLAG_ONBOARDING_ENABLED'),
  isLogsEnabled: isFeatureFlagEnabled('FEATURE_FLAG_LOGS_ENABLED'),
  isMFAEnabled: isFeatureFlagEnabled('FEATURE_FLAG_MFA_ENABLED'),
  isBillingEnabled: isFeatureFlagEnabled('FEATURE_FLAG_BILLING_ENABLED'),
  isTrustPlatformEnabled: isFeatureFlagEnabled('FEATURE_FLAG_TRUST_PLATFORM_ENABLED'),
  isAllowPortForAppUrls: isFeatureFlagEnabled('FEATURE_FLAG_ALLOW_PORT_APP_URLS'),
  isAllowAuthModeSelection: isFeatureFlagEnabled('FEATURE_FLAG_AUTH_MODE_SELECTION'),
  isMTLSEnabled: isFeatureFlagEnabled('FEATURE_FLAG_MTLS_ENABLED'),
  isTeamsEnabled: isFeatureFlagEnabled('FEATURE_FLAG_TEAMS_ENABLED'),
  isBankServicesConfigEnabledFoUser: isFeatureFlagEnabledForUser('FEATURE_FLAG_BANK_SERVICES_CONFIG_USERS_ENABLED'),
};

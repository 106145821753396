import React, { FC, Fragment } from 'react';
import { DeleteOutlined, EditOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export type ActionButtonsProps = {
  onRemove: () => void;
  onEdit: () => void;
  onDuplicate: () => void;
  onDownload: () => void;
  typeName: string;
};

export const ProviderActionButtons: FC<React.PropsWithChildren<ActionButtonsProps>> = ({ onEdit, onRemove, onDuplicate, onDownload, typeName }) => (
  <Fragment>
    <Tooltip placement="top" title={`Edit ${typeName}`}>
      <EditOutlined style={{ marginRight: 10, fontSize: 18 }} onClick={onEdit} />
    </Tooltip>

    <Tooltip placement="top" title={`Duplicate ${typeName}`}>
      <CopyOutlined style={{ marginRight: 10, fontSize: 18 }} onClick={onDuplicate} />
    </Tooltip>

    <Tooltip placement="top" title={`Download ${typeName}`}>
      <DownloadOutlined style={{ marginRight: 10, fontSize: 18 }} onClick={onDownload} />
    </Tooltip>

    <Tooltip placement="top" title={`Delete ${typeName}`}>
      <DeleteOutlined style={{ fontSize: 18 }} onClick={onRemove} />
    </Tooltip>
  </Fragment>
);

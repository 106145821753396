import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import * as El from './verifications-list.css';
import { VerificationItemProps, VerificationsListProps } from './types';
import { ApplicationState } from '../../redux/store';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';

const VerificationsListComponent: FC<VerificationsListProps> = ({ account }) => {
  const history = useHistory();
  const VerificationItem: FC<VerificationItemProps> = ({ title, description, isPassed, url }) => (
    <El.VerificationItemContainer key={title}>
      <El.ItemMarker isPassed={isPassed} />
      <El.ItemText>
        <El.ItemTitle>{title}</El.ItemTitle>
        <El.ItemDescription dangerouslySetInnerHTML={{ __html: description }} />
      </El.ItemText>
      <El.ItemButtonWrapper>
        <IDPartnerButton type="primary" onClick={() => url && history.push(url)} hidden={!url} block>
          {isPassed ? 'EDIT' : 'COMPLETE'}
        </IDPartnerButton>
      </El.ItemButtonWrapper>
    </El.VerificationItemContainer>
  );
  return (
    <El.VerificationsListContainer>
      <El.VerificationsListForm>
        <El.VerificationsListItems>
          <VerificationItem
            title="Domain Verification"
            description="Ensure the ability to access and modify DNS records for domain ownership confirmation."
            isPassed={Boolean(account.domain_verified_at)}
            url="/onboarding/dns"
          />
          <VerificationItem
            title="Merchant Category Code"
            description="Determine the category of products or services your business provides."
            isPassed={Boolean(account.merchant_code)}
            url="/onboarding/merchant"
          />
          <VerificationItem
            title="Know Your Business"
            description={
              'Confirm your business\'s legitimacy and compliance by providing business details. Reach out to us at <a href="mailto:support@idpartner.com">support@idpartner.com</a> for thorough verification.'
            }
            isPassed={Boolean(account.kyb_verified_at)}
          />
          <VerificationItem
            title="Legal Entity Identifier"
            description="Identify the legal entity that will engage in financial transactions."
            isPassed={Boolean(account.lei_verified_at)}
            url="/onboarding/lei"
          />
        </El.VerificationsListItems>
      </El.VerificationsListForm>
    </El.VerificationsListContainer>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

export const VerificationsList = connect(mapStateToProps, null)(withRouter(VerificationsListComponent));

import styled from 'styled-components';

export const ApplicationsPage = styled.div`
  padding-top: 32px;
  padding-bottom: 40px;
  position: relative;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

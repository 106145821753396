import styled from 'styled-components';
import { bodyLargeRegular, h1 } from '../../common.css';

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
  width: 100%;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
`;

export const FormDescription = styled(FormText)`
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 64px;
`;

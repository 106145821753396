import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { BillingOfferType, UserInvoice, UserSubscriptionType } from './types';
import { Api } from '../../modules/utils/api';

import { withAuth } from '../util';
import { fetchOffersAction, fetchPaymentMethodsAction, fetchPaymentProfileAction, fetchUserInvoicesAction, fetchUserSubscriptionAction } from './actions';
import { PaymentMethod } from '@stripe/stripe-js';

export const fetchOffers = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getOffersList().then((result: AxiosResponse<BillingOfferType[]>) => {
      dispatch(fetchOffersAction({ offers: result.data }));
    }),
  );

export const fetchPaymentMethods = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getPaymentMethodsList().then((result: AxiosResponse<PaymentMethod[]>) => {
      dispatch(fetchPaymentMethodsAction({ paymentMethods: result.data }));
    }),
  );

export const fetchUserSubscription = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getUserSubscription().then((result: AxiosResponse<UserSubscriptionType>) => {
      dispatch(fetchUserSubscriptionAction({ userSubscription: result.data }));
    }),
  );

export const fetchPaymentProfile = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getPaymentAccount().then((result: AxiosResponse<UserSubscriptionType[]>) => {
      dispatch(fetchPaymentProfileAction({ paymentProfile: result.data }));
    }),
  );

export const fetchUserInvoices = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getUserInvoicesList().then((result: AxiosResponse<UserInvoice[]>) => {
      dispatch(fetchUserInvoicesAction({ userInvoices: result.data }));
    }),
  );

import { Store, createStore, applyMiddleware } from 'redux';

import promiseMiddleware from 'redux-promise';

import thunk from 'redux-thunk';

import requestMiddleware from './middleware/authMiddleware';
import { ApplicationState, rootReducer } from './store';

export default (initialState?: ApplicationState) => {
  const store: Store<ApplicationState> = createStore(rootReducer, initialState, applyMiddleware(requestMiddleware(), thunk, promiseMiddleware));

  return { store };
};

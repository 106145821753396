import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as El from './pages.css';
import { fetchAccount as fetchAccountRedux } from '../redux/auth/async';
import { ApplicationState } from '../redux/store';
import { IDPartnerButton } from '../components/ui/idpartner-button/idpartner-button';
import { IDPartnerModal } from '../components/ui/idpartner-modal/idpartner-modal';
import { AsyncComponent } from '../components/async-component/async-component';
import { Api } from '../modules/utils/api';

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccount: bindActionCreators(fetchAccountRedux, dispatch),
});

type AllProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const EmailNotVerified: FC<AllProps> = ({ account, fetchAccount }) => {
  const [isEmailSended, setIsEmailSended] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    if (error) {
      setIsError(true);
      const parsedError = JSON.parse(error)?.error;
      setErrorText(parsedError || 'Something went wrong');
    }
  }, []);

  useEffect(() => {
    if (account.email_verified_at) {
      setTimeout(() => {
        history.push('/');
      }, 2000);
    }
  }, [account]);

  const onAgree = async () => {
    const api = new Api();
    try {
      await api.resendVerificationEmail();
    } catch (e) {
      console.error(e);
    }
    setIsEmailSended(true);
  };

  const onDisagree = () => {
    window.location.replace(`${process.env.DASHBOARD_API_SERVICE_URL}/auth/logout`);
  };

  const Content = () => {
    if (isError) {
      return (
        <IDPartnerModal
          open={true}
          closable={false}
          footer={
            <El.TermsButtonsWrapper>
              <IDPartnerButton size="large" type="default" block onClick={onDisagree}>
                Logout
              </IDPartnerButton>
              {!isEmailSended && (
                <IDPartnerButton size="large" type="primary" block onClick={onAgree}>
                  Resend
                </IDPartnerButton>
              )}
            </El.TermsButtonsWrapper>
          }
        >
          <El.FormTitle>Email verification error</El.FormTitle>
          <El.TermsConditionsText>{errorText}</El.TermsConditionsText>
        </IDPartnerModal>
      );
    }
    if (!account.email_verified_at) {
      return (
        <IDPartnerModal
          open={true}
          onCancel={onDisagree}
          footer={
            <El.TermsButtonsWrapper>
              <IDPartnerButton size="large" type="default" block onClick={onDisagree}>
                Logout
              </IDPartnerButton>
              {!isEmailSended && (
                <IDPartnerButton size="large" type="primary" block onClick={onAgree}>
                  Resend
                </IDPartnerButton>
              )}
            </El.TermsButtonsWrapper>
          }
        >
          {isEmailSended ? (
            <>
              <El.FormTitle>Confirm Your Email Address</El.FormTitle>
              <El.TermsConditionsText>We&apos; ve Sent You an Email for Verification</El.TermsConditionsText>
            </>
          ) : (
            <>
              <El.FormTitle>Email Verification Pending</El.FormTitle>
              <El.TermsConditionsText>Please check your email and complete the verification process to access your account.</El.TermsConditionsText>
            </>
          )}
        </IDPartnerModal>
      );
    }

    return (
      <IDPartnerModal open={true} closable={false} footer={[]}>
        <El.FormTitle>Email Successfully Verified</El.FormTitle>
        <El.TermsConditionsText>You will now be redirected to your dashboard.</El.TermsConditionsText>
      </IDPartnerModal>
    );
  };

  return (
    <AsyncComponent showError={false} message="" getAsyncActions={() => [fetchAccount(account.id)]}>
      <El.TermsWrapper>
        <Content />
      </El.TermsWrapper>
    </AsyncComponent>
  );
};

export const EmailNotVerifiedPage = connect(mapStateToProps, mapDispatchToProps)(EmailNotVerified);

import { AccountType } from '../../redux/auth/types';

export interface VerificationItemProps {
  title: string;
  description: string;
  isPassed: boolean;
  url: string;
}

export interface VerificationsListProps {
  account: AccountType;
}

export enum DNS_CHECK_STEPS {
  DOMAIN = 'domain',
  OWNERSHIP = 'ownership',
}

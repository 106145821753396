import React, { FC } from 'react';
import * as El from './language.css';
import { IDPartnerInput } from '../../../components/ui/idpartner-input/idpartner-input';
import nodejs from '../../../assets/icons/nodejs.svg';
import java from '../../../assets/icons/java.svg';
import ruby from '../../../assets/icons/ruby.svg';
import python from '../../../assets/icons/python.png';
import { LanguagesTypes } from '../../../redux/applications/types';

const languages = [
  {
    name: 'Node JS',
    slug: 'javascript',
    image: nodejs,
  },
  {
    name: 'Java',
    slug: 'java',
    image: java,
  },
  {
    name: 'Ruby',
    slug: 'ruby',
    image: ruby,
  },
  {
    name: 'Python',
    slug: 'python',
    image: python,
  },
];

type LanguageProps = {
  selectedLanguage: LanguagesTypes;
  onChangeLanguage: (lang: LanguagesTypes) => void;
  newAppName: string;
  onAppNameChange: (appName: string) => void;
  onSkipLanguageStep: (e: any) => void;
};
export const Language: FC<LanguageProps> = ({ onSkipLanguageStep, selectedLanguage, onChangeLanguage, newAppName, onAppNameChange }) => (
  <El.LanguageWrapper>
    <El.LanguageTitle>Welcome to Quick Start Setup!</El.LanguageTitle>
    <El.LanguageSubTitle>Get started quickly by selecting a language for your sample template. </El.LanguageSubTitle>
    <El.ItemWrapper>
      <El.ItemLabel>Application Name</El.ItemLabel>
      <IDPartnerInput value={newAppName} onChange={(e: any) => onAppNameChange(e.target.value)} />
    </El.ItemWrapper>
    <El.ItemWrapper>
      <El.ItemLabel>Select Language</El.ItemLabel>
      <El.LanguagesCard>
        {languages.map(lang => (
          <El.LanguageItem onClick={() => onChangeLanguage(lang.slug as LanguagesTypes)} isActive={lang.slug === selectedLanguage}>
            <El.LanguageImage image={lang.image} />
            <El.LanguageName>{lang.name}</El.LanguageName>
          </El.LanguageItem>
        ))}
      </El.LanguagesCard>
    </El.ItemWrapper>
    <El.SkipStep>
      <El.SkipHeader>Prefer to do it yourself?</El.SkipHeader>

      <El.SkipButton onClick={onSkipLanguageStep}>Skip and Enter Details Manually</El.SkipButton>
    </El.SkipStep>
  </El.LanguageWrapper>
);

import { combineReducers, Dispatch, Action, AnyAction } from 'redux';

import { authReducer } from './auth/reducer';
import { AuthState } from './auth/types';

import { errorReducer } from './error/reducer';

import { ErrorsState } from './error/types';
import { ApplicationsState } from './applications/types';

import { applicationsReducer } from './applications/reducer';
import { VerificationEventsState } from './logs/types';
import { logsReducer } from './logs/reducer';
import { BillingState } from './billing/types';
import { billingReducer } from './billing/reducer';
import { ProvidersState } from './providers/types';
import { providersReducer } from './providers/reducer';
import { groupReducer } from './group/reducer';
import { GroupState } from './group/types';

// The top-level state object
export interface ApplicationState {
  auth: AuthState;
  applications: ApplicationsState;
  error: ErrorsState;
  logs: VerificationEventsState;
  billing: BillingState;
  providers: ProvidersState;
  group: GroupState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = combineReducers<ApplicationState>({
  auth: authReducer,
  applications: applicationsReducer,
  error: errorReducer,
  logs: logsReducer,
  billing: billingReducer,
  providers: providersReducer,
  group: groupReducer,
});

// @ts-nocheck
import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { ErrorContainer } from '../modules/error/error.container';
import { NotFoundPage } from '../pages/404';
import { Login } from '../pages/login';
import { Signup } from '../pages/signup';
import { ResetPassword } from '../pages/reset-password';
import { ResetSubmit } from '../pages/reset-submit';
import { featureFlags } from '../utils/featureFlags';
import { OtpRegister } from '../pages/otp-register';
import { OtpValidate } from '../pages/otp-validate';
import { TermsAndConditions } from '../pages/terms-and-conditions';
import { EmailNotVerifiedPage } from '../pages/email-not-verified';

export const Routes: React.FC<React.PropsWithChildren> = () => (
  <Router>
    <Switch>
      <Route exact path="/404" component={NotFoundPage} />
      <Route exact path="/error" component={ErrorContainer} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/signup/:token" component={Signup} />
      <Route exact path="/login/reset" component={ResetPassword} />
      <Route exact path="/login/reset/:token" component={ResetSubmit} />
      {featureFlags.isMFAEnabled && <Route exact path="/otp/register" component={OtpRegister} />}
      {featureFlags.isMFAEnabled && <Route exact path="/otp/validate" component={OtpValidate} />}
      <Route exact path="/terms" component={TermsAndConditions} />
      <Route exact path="/email-verification" component={EmailNotVerifiedPage} />
      <Route path="/" component={Dashboard} />
    </Switch>
  </Router>
);

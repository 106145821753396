import styled from 'styled-components';

export const UserAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 16px;
`;

export const UserAvatarWrapper = styled.div`
  background: ${props => props.theme['gray-200']};
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Visby CF, Public Sans, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

export const UserName = styled.div`
  color: #fff;
  text-align: center;
  font-family: Public Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const UserEmail = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

export const UserCompany = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const AccountButton = styled.a`
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: border-color ease-in-out;

  &:hover {
    border-color: ${props => props.theme['gray-400']};
  }
`;

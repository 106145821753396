import { MiddlewareAPI, Dispatch, Action } from 'redux';
import { AxiosPromise, AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import { Api } from '../../modules/utils/api';
import { authAsyncActions } from '../auth/actions';
import { createError } from '../error/actions';

interface RequestAction extends Action {
  request: (api: Api, dispatch: Dispatch) => AxiosPromise;
}

interface Error {
  error: string;
  error_description: string;
}

export default function requestMiddleware() {
  return (props: MiddlewareAPI<Dispatch, ApplicationState>) => (next: Dispatch) => (action: RequestAction) => {
    const { dispatch, getState } = props;
    const { request } = action;

    if (!request) {
      return next(action);
    }

    const { token } = getState().auth;
    tokenPromise(request, dispatch, token);
  };
}

export const tokenPromise = (request: (api: Api, dispatch: Dispatch) => AxiosPromise, dispatch: Dispatch, token: string) =>
  request(new Api(token), dispatch).catch((error: AxiosError<Error>) => {
    if (error.response === undefined) {
      dispatch(authAsyncActions.failure('There is a problem with your connection. Please try again'));
      throw error;
    }
    // if a 401 so tell the user to log back in
    if (error.response.status === 401) {
      authAsyncActions.failure('Your session timed out. Please login in');
      return dispatch(push(`/login${window.location.search}`));
    }
    if (error.response.status === 403) {
      return dispatch(push('/error'));
    }

    dispatch(createError(error?.response?.data?.error || 'unknown error'));

    // any other error just send back to the function that made the request and dispatch an error to the error reducer
    throw error;
  });

import styled, { css } from 'styled-components';
import { IDPartnerButton } from '../../../components/ui/idpartner-button/idpartner-button';

export const LanguageWrapper = styled.div`
  width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LanguageTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Visby CF, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;
export const LanguageSubTitle = styled.div`
  color: #000;
  font-family: Visby CF, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
`;
export const SkipHeader = styled.div`
  color: #000;
  font-family: Visby CF, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
`;

export const SkipButton = styled(IDPartnerButton)`
  margin: 0 auto;
  width: 50%;
  font-size: 15px;
`;

export const SkipStep = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const LanguagesCard = styled.div`
  border-radius: 10px;
  border: 1px solid #d9dde5;
  background: #fff;
  box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 40px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 52px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemLabel = styled.div`
  color: #232733;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const LanguageItem = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 10px;

  &:hover {
    background: rgb(240, 242, 245);
  }

  ${props =>
    props.isActive &&
    css`
      border: 1px dashed #4e5466;
      background: rgb(240, 242, 245);
    `}
`;

export const LanguageImage = styled.div<{ image: string }>`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #f0f2f5;
  background-image: url('${props => props.image}');
  background-repeat: no-repeat;
  background-position: center;
`;

export const LanguageName = styled.div`
  color: #232733;
  font-family: Public Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

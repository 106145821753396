import styled from 'styled-components';

export const DownloadJwksContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  color: #434343;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-top: 40px;
`;

export const AppStateIllustration = styled.div<{ url: string }>`
  width: 236px;
  height: 160px;
  background: url('${props => props.url}') center no-repeat;
  background-size: contain;
  margin-bottom: 48px;
`;

import styled from 'styled-components';
import { bodyLargeRegular } from '../../../../common.css';

export const ModalText = styled.div`
  ${bodyLargeRegular};
  margin-top: 15px;
  margin-bottom: 32px;

  & li {
    margin-bottom: 6px;
  }
`;

export const CodeCopyWrapper = styled.div`
  margin-top: 1px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const Steps = styled.div`
  padding: 0 10px;
`;

import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrandText, Button, ButtonArrow, Container, Image, ImageContainer, TextContainer } from './idpartner-verify-button-demo.css';

const defaultIDPartnerTheme = {
  textColor: 'white',
  backgroundColor: '#4A86E8',
};

const mediumButtonStyle = {
  iconWidth: '50px',
  fontSize: '18px',
};

const curvedButtonStyle = {
  borderRadius: '10px',
};

type VerifyButtonType = {
  prefixText?: string;
  providerName?: string;
  imageUrl?: string;
  textColor?: string;
  backgroundColor?: string;
};

export const DEFAULT_IDPARTNER = 'Select your ID partner';
export const DEFAULT_PREFIX_TEXT = 'Continue with';

export const IDPartnerVerifyButtonDemo: FC<VerifyButtonType> = ({ providerName, imageUrl, prefixText = DEFAULT_PREFIX_TEXT, textColor, backgroundColor }) => {
  const providerTheme = {
    textColor: textColor || defaultIDPartnerTheme.textColor,
    backgroundColor: backgroundColor || defaultIDPartnerTheme.backgroundColor,
  };
  const vendorText = providerName ? `${prefixText} ${providerName}` : DEFAULT_IDPARTNER;
  const buttonStyleTheme = {
    ...providerTheme,
    ...mediumButtonStyle,
    ...curvedButtonStyle,
  };

  return (
    <ThemeProvider theme={buttonStyleTheme}>
      <Button>
        <Container>
          <TextContainer>
            <ImageContainer>{imageUrl && <Image src={imageUrl} alt="Logo" />}</ImageContainer>
            <BrandText>{vendorText}</BrandText>
            <ButtonArrow />
          </TextContainer>
        </Container>
      </Button>
    </ThemeProvider>
  );
};

import styled from 'styled-components';
import { Input } from 'antd';
import { digitsSmallMedium } from '../../../common.css';

const { TextArea } = Input;

export const IDPartnerTextareaContainer = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme['base-colors-gray-3']};
  border-radius: 8px;
`;
export const IDPartnerTextareaStyled = styled(TextArea)`
  &.ant-input {
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    box-shadow: none;
    border: none;
    background-color: ${props => props.theme['system-input-background']};

    &:focus {
      outline: none;
    }
  }
`;

export const Counter = styled.div`
  position: absolute;
  right: 6px;
  bottom: 6px;
  z-index: 1;

  display: flex;
  font-family: Inter;
  ${digitsSmallMedium};
  color: ${props => props.theme['gray-300']};
`;

export const CurrentCount = styled.span`
  color: ${props => props.theme['gray-400']};
`;

export const TotalCount = styled.span`
  color: ${props => props.theme['gray-300']};
`;

import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { remove } from 'lodash';
import { ProvidersState, Provider } from './types';
import * as actions from './actions';

export type ActionsType = ActionType<typeof actions>;

const initialState: ProvidersState = {
  data: [],
  hasLoaded: false,
};

const reducer: Reducer<ProvidersState> = (state = initialState, action: ActionsType): ProvidersState => {
  switch (action.type) {
    case getType(actions.fetchProvidersAction):
      return { ...state, ...action.payload };
    case getType(actions.deleteProviderAction):
      return {
        ...state,
        data: remove(state.data, (app: Provider) => app.id !== action.payload),
      };
    case getType(actions.createProviderAction):
      return { ...state, data: [action.payload, ...state.data] };
    case getType(actions.updateProviderAction):
      return {
        ...state,
        data: state.data.map((project: Provider) => (project.id === action.payload.id ? action.payload : project)),
      };
    default: {
      return state;
    }
  }
};

export { reducer as providersReducer };

import styled from 'styled-components';

export const ApplicationWizardWrapper = styled.div`
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const WizardFooter = styled.div`
  width: 100%;
  box-shadow: 0px -3px 50px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 34px 60px;
  justify-content: space-between;
  align-items: center;
`;

export const WizardContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 40px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const FooterRightCorner = styled.div`
  display: flex;
  gap: 16px;
`;

import React, { FC, useMemo } from 'react';
import { isUndefined } from 'lodash';
import { IDPartnerModalProps } from './types';
import { IDPartnerModalDefaultFooter, IDPartnerModalStyled } from './idpartner-modal.css';
import closeModalIcon from '../../../assets/icons/close-modal-icon.svg';
import { IDPartnerButton } from '../idpartner-button/idpartner-button';

export const IDPartnerModal: FC<IDPartnerModalProps> = props => {
  const { footer, ...rest } = props;

  const defaultFooter = useMemo(() => {
    const { onCancel, onOk } = props;
    return (
      <IDPartnerModalDefaultFooter>
        <IDPartnerButton key="back" type="default" onClick={onCancel}>
          Cancel
        </IDPartnerButton>
        <IDPartnerButton key="submit" type="primary" onClick={onOk}>
          Submit
        </IDPartnerButton>
      </IDPartnerModalDefaultFooter>
    );
  }, [props]);

  return (
    <IDPartnerModalStyled
      width={614}
      {...rest}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
      footer={isUndefined(footer) ? defaultFooter : footer}
      closeIcon={<img src={closeModalIcon} alt="close" />}
    />
  );
};

import React, { FC, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IDPartnerModal } from '../../../../components/ui/idpartner-modal/idpartner-modal';
import { RevokeCertificateModalProps, RevokingStates } from './types';
import { States } from './states';
import { Api } from '../../../utils/api';
import { fetchApplications as fetchApplicationsRedux } from '../../../../redux/applications/async';

const mapDispatchToProps = (dispatch: Dispatch) => ({ fetchApplications: bindActionCreators(fetchApplicationsRedux, dispatch) });

type AllProps = RevokeCertificateModalProps & ReturnType<typeof mapDispatchToProps>;
const Container: FC<AllProps> = props => {
  const [state, setState] = useState<RevokingStates>('revoke');
  const [revokeReason, setRevokeReason] = useState('');

  const { onCancel, certificate, fetchApplications } = props;
  const handleClose = (e: any) => {
    onCancel(e);
  };

  const handleRevoke = async () => {
    const api = new Api();
    setState('loading');
    try {
      await api.revokeCertificate(certificate.application_id, certificate.id, revokeReason);
      fetchApplications();
      setState('success');
    } catch {
      setState('error');
    }
  };

  const handleReasonChange = (reason: string) => {
    setRevokeReason(reason);
  };

  const getTitleByState = (currentState: RevokingStates) => {
    switch (currentState) {
      case 'success':
        return 'Revocation Started';
      default:
        return 'Revoke Certificate';
    }
  };

  return (
    <IDPartnerModal {...props} key="create-app" title={getTitleByState(state)} footer={null} destroyOnClose>
      <States state={state} onClose={handleClose} onRevoke={handleRevoke} onReasonChange={handleReasonChange} revokeReason={revokeReason} />
    </IDPartnerModal>
  );
};

export const RevokeCertificateModal = connect(null, mapDispatchToProps)(Container);

import React, { FC, useState } from 'react';
import { Empty, Tooltip } from 'antd';
import * as El from './certificates.css';
import { Application, Certificate } from '../../../../redux/applications/types';
import { INACTIVE_STATUSES, NOT_ABLE_TO_DOWNLOAD_STATUSES, NOT_ABLE_TO_REVOKE_STATUSES, PENDING_STATUSES, REVOKED_STATUSES } from './constants';
import { prepareDate } from './utils';
import { RevokeCertificateModal } from '../../../applications/components/revoke-certificate-modal/revoke-certificate-modal';

type CertificatesProps = {
  application: Application | undefined;
};

const ActivityComponent: FC<{ cert: Certificate }> = ({ cert }) => {
  let dynamicActivityStatus = <El.StateText checkStatus="success">Active</El.StateText>;
  if (PENDING_STATUSES.includes(cert.status)) {
    dynamicActivityStatus = <El.StateText checkStatus="success">Processing</El.StateText>;
  }
  if (INACTIVE_STATUSES.includes(cert.status)) {
    dynamicActivityStatus = <El.StateText checkStatus="error">Inactive</El.StateText>;
  }
  return (
    <El.CertText>
      <El.CertTextTitle>STATUS</El.CertTextTitle>
      {dynamicActivityStatus}
    </El.CertText>
  );
};

const IssuanceComponent: FC<{ cert: Certificate }> = ({ cert }) => {
  if (PENDING_STATUSES.includes(cert.status) || !cert.valid_from) {
    return null;
  }
  return (
    <El.CertText>
      <El.CertTextTitle>ISSUED</El.CertTextTitle>
      <El.StateText>{prepareDate(new Date(cert.valid_from))}</El.StateText>
    </El.CertText>
  );
};

const ExpiringComponent: FC<{ cert: Certificate }> = ({ cert }) => {
  let dynamicExpriringStatus = <El.StateText>{prepareDate(new Date(cert.valid_to))}</El.StateText>;
  if (PENDING_STATUSES.includes(cert.status) || !cert.valid_to) {
    return null;
  }
  if (REVOKED_STATUSES.includes(cert.status)) {
    dynamicExpriringStatus = <El.StateText checkStatus="error">{prepareDate(new Date(cert.valid_to))}</El.StateText>;
  }
  if (cert.status === 'expiring') {
    dynamicExpriringStatus = <El.StateText checkStatus="warning">{prepareDate(new Date(cert.valid_to))}</El.StateText>;
  }
  return (
    <El.CertText>
      <El.CertTextTitle>EXPIRING</El.CertTextTitle>
      {dynamicExpriringStatus}
    </El.CertText>
  );
};

export const Certificates: FC<CertificatesProps> = ({ application }) => {
  const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false);
  const [selectedCertificate, setSelectedCerteficate] = useState<Certificate | undefined>();

  const handleClickDownload = (fileData: string) => {
    const element = document.createElement('a');
    const file = new Blob([fileData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'cert.pem';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const getIsHideButtons = (certStatus: Certificate['status']) =>
    PENDING_STATUSES.includes(certStatus) || (NOT_ABLE_TO_DOWNLOAD_STATUSES.includes(certStatus) && NOT_ABLE_TO_REVOKE_STATUSES.includes(certStatus));

  const handleCertificateClick = (cert: Certificate) => {
    setSelectedCerteficate(cert);
    setIsRevokeModalVisible(true);
  };

  const handleCertificateModalClose = () => {
    setSelectedCerteficate(undefined);
    setIsRevokeModalVisible(false);
  };

  const getTooltipContent = (cert: Certificate) => (
    <div>
      <El.ButtonsWrapper>
        {!NOT_ABLE_TO_DOWNLOAD_STATUSES.includes(cert.status) && <El.TooltipButton onClick={() => handleClickDownload(cert.certificate)}>Download</El.TooltipButton>}
        {!NOT_ABLE_TO_REVOKE_STATUSES.includes(cert.status) && <El.TooltipButton onClick={() => handleCertificateClick(cert)}>Revoke</El.TooltipButton>}
      </El.ButtonsWrapper>
    </div>
  );

  if (!application.certificates) {
    return (
      <Empty
        imageStyle={{
          height: 60,
        }}
        description={<span>No Certificates Created</span>}
      />
    );
  }
  return (
    <El.CertificatesWrapper>
      {application?.certificates?.map(cert => (
        <El.CertificateItem>
          <El.CertInfo>
            <El.CertIcon status={cert.status} />
            <ActivityComponent cert={cert} />
            <IssuanceComponent cert={cert} />
            <ExpiringComponent cert={cert} />
          </El.CertInfo>
          {!getIsHideButtons(cert.status) && (
            <Tooltip placement="rightTop" overlayClassName="cert-tooltip" title={getTooltipContent(cert)}>
              <El.CertAction />
            </Tooltip>
          )}
        </El.CertificateItem>
      ))}
      <El.CertTooltipGlobals />

      {isRevokeModalVisible && <RevokeCertificateModal certificate={selectedCertificate} open onCancel={handleCertificateModalClose} />}
    </El.CertificatesWrapper>
  );
};

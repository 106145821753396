import React, { FC } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MerchantCheckForm } from '../../components/merchant-check-form/merchant-check-form';
import { LeiCheckForm } from '../../components/lei-check-form/lei-check-form';
import { DnsCheckForm } from '../dns-check/dns-check-form';
import { VerificationsList } from '../../components/verifications-list/verifications-list';
import { ApplicationState } from '../../redux/store';
import { OnboardingProps } from './types';
import { getIsOnboardingPassed } from '../../utils/onboarding';

const OnboardingModule: FC<OnboardingProps> = ({ account, history }) => {
  const isOnboardingPassed = getIsOnboardingPassed(account);

  if (isOnboardingPassed && history.location.pathname !== '/onboarding') {
    history.push('/onboarding');
  }

  return (
    <Switch>
      <Route exact path="/onboarding/merchant" component={MerchantCheckForm} />
      <Route exact path="/onboarding/lei" component={LeiCheckForm} />
      <Route exact path="/onboarding/dns" component={DnsCheckForm} />
      <Route exact path="/onboarding" component={VerificationsList} />
    </Switch>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

export const Onboarding = connect(mapStateToProps, null)(withRouter(OnboardingModule));

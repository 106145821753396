import React, { FC } from 'react';
import * as El from './quick-start.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { Application, LanguagesTypes } from '../../../../redux/applications/types';
import DownloadTemplateIcon from '../../../../assets/icons/download-template.svg';

type QuickStartProps = {
  selectedLanguage: LanguagesTypes;
  application: Application;
};

const quickStartContent: Record<LanguagesTypes, any> = {
  javascript: {
    githubLink: 'https://github.com/idpartner-app/examples/tree/master/with-lib/client-secret-basic-example',
  },
  ruby: {
    githubLink: 'https://github.com/idpartner-app/ruby_oidc_client/tree/master/examples/client_secret_basic',
  },
  java: {
    githubLink: 'https://github.com/idpartner-app/java-oidc-client/tree/master/examples/client-secret-basic',
  },
  python: {
    githubLink: 'https://github.com/idpartner-app/python_oidc_client/tree/master/examples/client_secret_basic',
  },
};

export const QuickStart: FC<QuickStartProps> = ({ selectedLanguage, application }) => (
  <>
    <El.CardsWrapper>
      <El.Card>
        <El.CardTitle>
          <img src={DownloadTemplateIcon} width={30} alt="Auto install" /> Quick-Start Sample Project
        </El.CardTitle>
        <El.InstructionsParagraph>Simple steps to get started:</El.InstructionsParagraph>
        <ol>
          <li>Download the sample app.</li>
          <li>Install dependencies.</li>
          <li>Start the app.</li>
        </ol>
        <El.InstructionsParagraph>
          Click the button below to begin the quick and easy setup process, or check out the README to manually add the application configuration to the sample project.
        </El.InstructionsParagraph>
        <El.ButtonsWrapper>
          <IDPartnerButton
            type="primary"
            href={`${process.env.DASHBOARD_API_SERVICE_URL}/relying-party-example-projects?application_id=${application.id}&language=${selectedLanguage}`}
            target="_blank"
            style={{ width: '40%', margin: '0 auto', marginBottom: 20 }}
          >
            DOWNLOAD SAMPLE APP
          </IDPartnerButton>
          <IDPartnerButton href={quickStartContent[selectedLanguage].githubLink} style={{ width: '40%', margin: '0 auto' }}>
            CHECK OUT ON GITHUB
          </IDPartnerButton>
        </El.ButtonsWrapper>
      </El.Card>
    </El.CardsWrapper>
  </>
);

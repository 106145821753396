import styled from 'styled-components';
import loadingIcon from '../../assets/icons/modal-loading.svg';

export const AppStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const LoaderAnimation = styled.div`
  height: 72px;
  width: 72px;
  background: url('${loadingIcon}') center no-repeat;
  background-size: contain;
  animation: rotation 3s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const AppStateIllustration = styled.div<{ url: string }>`
  width: 236px;
  height: 160px;
  background: url('${props => props.url}') center no-repeat;
  background-size: contain;
  margin-bottom: 32px;
`;

export const LoaderText = styled.div`
  color: #193fc7;
  text-align: center;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 32px;
  margin-bottom: 48px;
`;

export const Description = styled.div`
  color: #434343;
  text-align: center;
  /* Body Medium */
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

import styled, { createGlobalStyle } from 'styled-components';
import certActionIcon from '../../../../assets/cert-action.svg';
import { Certificate } from '../../../../redux/applications/types';
import certOk from '../../../../assets/icons/cert-ok.svg';
import certProcessing from '../../../../assets/icons/cert-processing.svg';
import certError from '../../../../assets/icons/cert-error.svg';
import certRevoked from '../../../../assets/icons/cert-revoked.svg';
import certExpiring from '../../../../assets/icons/cert-expiring.svg';
import certOthers from '../../../../assets/icons/cert-others.svg';

const getIconByStatus = (status: Certificate['status']) => {
  switch (status) {
    case 'active':
      return certOk;
    case 'issuance_pending':
    case 'revocation_pending':
      return certProcessing;
    case 'expired':
    case 'revocation_failed':
    case 'issuance_failed':
      return certError;
    case 'revoked':
      return certRevoked;
    case 'expiring':
      return certExpiring;
    default:
      return certOthers;
  }
};

export const CertificatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CertificateItem = styled.div`
  border-radius: 10px;
  border: 1px solid #d9dde5;
  background: #fff;
  box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CertInfo = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
`;

export const CertIcon = styled.div<{ status: Certificate['status'] }>`
  width: 33px;
  height: 33px;
  background-image: url('${props => getIconByStatus(props.status)}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CertText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CertTextTitle = styled.div`
  color: var(--color-text-secundary, #4e5466);
  font-family: Public Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const CertTextDescription = styled.div`
  color: #000;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

type StateTextType = 'success' | 'warning' | 'error' | 'common';
const getColorByType = (type: StateTextType) => {
  switch (type) {
    case 'warning':
      return '#D2A106';
    case 'error':
      return '#DA1E28';
    case 'success':
      return '#24A148';
    default:
      return '#101319';
  }
};

export const StateText = styled(CertTextDescription)<{ checkStatus?: StateTextType }>`
  color: ${props => getColorByType(props.checkStatus)};
`;

export const CertAction = styled.div`
  height: 34px;
  width: 34px;
  background: url('${certActionIcon}') center no-repeat;
  cursor: pointer;
`;

export const TooltipButton = styled.div`
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  color: #9da3b2;

  &:hover {
    background: #f0f2f5;
    color: #2c5aec;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const CertTooltipGlobals = createGlobalStyle`
  .cert-tooltip {
    .ant-tooltip-arrow {
      display: unset !important;
      .ant-tooltip-arrow-content {
        --antd-arrow-background-color: white;
      }
    }
    .ant-tooltip-inner {
      padding: 0 !important;
      border-radius: 10px !important;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.15) !important;
      background: white !important;
    }
  }
`;

import styled from 'styled-components';

export const AppSettingsWrapper = styled.div`
  width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 48px;
`;
export const TitleText = styled.div`
  color: #000;
  font-family: Visby CF, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 113%;
`;

export const SubtitleText = styled.div`
  color: #434343;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 44px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: space-between;
`;

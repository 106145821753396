import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Result, Button } from 'antd';
import { PageWrapper } from '../components/layouts/main-layout/main-layout/main-layout.css';

export const NotFoundPage = function () {
  return (
    <PageWrapper>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" href="/" size="large" icon={<HomeOutlined />}>
            Back Home
          </Button>
        }
      />
    </PageWrapper>
  );
};

import styled from 'styled-components';
import { h2 } from '../../common.css';

export const BillingFormWrapper = styled.div``;

export const FormSubtitle = styled.div`
  ${h2};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
`;

export const PaymentMethodsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

export const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountRow = styled.div`
  display: flex;
  ${props => props.theme['gray-900']};
  & :first-child {
    margin-right: 10px;
    color: ${props => props.theme['gray-400']};
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const InvoicesWrapper = styled.div`
  max-height: 40vh;
  overflow-y: auto;
`;

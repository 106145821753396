import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AutoComplete, Form } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as El from './merchant-check-form.css';
import { IDPartnerAlert } from '../ui/idpartner-alert/idpartner-alert';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { IDPartnerForm } from '../ui/idpartner-form/idpartner-form';
import { fetchMerchantCodes, updateAccount as updateAccountRedux } from '../../redux/auth/async';
import { ApplicationState } from '../../redux/store';
import { MerchantCheckFormProps } from './types';
import { IDPartnerAutocomplete } from '../ui/idpartner-autocomplete/idpartner-autocomplete';

const { Option } = AutoComplete;

const MerchantCodeCheck: FC<MerchantCheckFormProps> = ({ history, account, updateAccount }) => {
  const [error, setError] = useState<string | undefined>();
  const [merchantCodes, setMerchantCodes] = useState([]);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchMerchantCodes().then(res => {
      setMerchantCodes(res.data);
      const result = res.data?.find(item => item.mcc === account.merchant_code);
      if (result) {
        setSelectedValue(result.mcc);
      }
    });
  }, []);

  const selectedOption = useMemo(() => merchantCodes?.find(item => item.mcc === selectedValue), [selectedValue]);

  const onVerify = async () => {
    if (account.merchant_code) {
      history.push('/onboarding');
    }
    setError(undefined);
    const validationResult = await form.validateFields();
    const isCurrentStepValid = !validationResult.errorFields || !validationResult.errorFields.length;
    if (isCurrentStepValid) {
      try {
        await updateAccount({ ...account, merchant_code: form.getFieldValue('merchant_code') });
        history.push('/onboarding');
      } catch (e) {
        console.error(e);
        const {
          response: { data },
        } = e;
        setError(data.error_description);
      }
    }
  };

  const onSearch = (query: string) => {
    if (!query) {
      setFilteredCodes(merchantCodes);
    }
    const r = new RegExp(query, 'gmi');
    setFilteredCodes(merchantCodes.filter(code => code.category.search(r) !== -1 || code.mcc.search(r) !== -1));
  };

  const onSelect = (data: string) => {
    setSelectedValue(data);
  };

  const resetSelected = () => {
    setSelectedValue(undefined);
    setFilteredCodes([]);
  };

  const onSelectedOptionClick = () => {
    resetSelected();
    form.resetFields();
  };

  return (
    <El.LeiCheckContainer>
      <El.LeiCheckForm>
        <El.FormTitle>Merchant Category Code</El.FormTitle>
        <El.FormDescription>
          A Merchant Category Code (MCC) is a 4-digit number used to classify businesses. A business MCC indicates the types of services or goods being sold to customers.
        </El.FormDescription>

        {error && (
          <El.ErrorWrapper>
            <IDPartnerAlert type="error" showIcon description={error} />
          </El.ErrorWrapper>
        )}
        <El.FormContentWrapper>
          <IDPartnerForm>
            <Form layout="vertical" requiredMark="optional" form={form}>
              <Form.Item
                label="Search for the MCC that best describes your industry"
                name="merchant_code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the code',
                  },
                  () => ({
                    validator(_, value) {
                      if (!merchantCodes.find(code => code.mcc === value)) {
                        return Promise.reject(new Error('Select code from list below'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <IDPartnerAutocomplete onChange={resetSelected} onSelect={onSelect} placeholder="Start typing" onSearch={onSearch} popupClassName="idpartner-autocomplete-popup">
                  <>
                    {selectedValue && (
                      <El.SelectedOption key="selected" onClick={onSelectedOptionClick}>
                        <El.OptionContent>
                          <El.OptionText>{selectedOption?.category}</El.OptionText>
                          <El.OptionCode>{selectedOption?.mcc}</El.OptionCode>
                        </El.OptionContent>
                      </El.SelectedOption>
                    )}
                    {filteredCodes.map(option => (
                      <Option key={option.mcc} value={option.mcc} className="idpartner-autocomplete-option">
                        <El.OptionContent>
                          <El.OptionText>{option.category}</El.OptionText>
                          <El.OptionCode>{option.mcc}</El.OptionCode>
                        </El.OptionContent>
                      </Option>
                    ))}
                  </>
                </IDPartnerAutocomplete>
              </Form.Item>
            </Form>
          </IDPartnerForm>
        </El.FormContentWrapper>
      </El.LeiCheckForm>
      <El.Footer>
        <Link to="/">
          <IDPartnerButton>BACK</IDPartnerButton>
        </Link>
        <IDPartnerButton type="primary" onClick={onVerify} disabled={Boolean(account.lei_verified_at)}>
          VERIFY
        </IDPartnerButton>
      </El.Footer>
    </El.LeiCheckContainer>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccount: bindActionCreators(updateAccountRedux, dispatch),
});

export const MerchantCheckForm = connect(mapStateToProps, mapDispatchToProps)(withRouter(MerchantCodeCheck));

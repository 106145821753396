import styled from 'styled-components';

export const AddPaymentMethodFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormRow = styled.div`
  margin-bottom: 24px;
`;

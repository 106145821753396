import styled, { css } from 'styled-components';
import { bodyLargeRegular, h3 } from '../../common.css';

export const PaymentMethodWrapper = styled.div<{ isActive?: boolean }>`
  width: 100%;
  display: flex;
  border: 2px solid ${props => props.theme['border-100']};
  border-radius: 16px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme['gray-400']};
  }

  ${props =>
    props.isActive &&
    css`
      border-color: ${props => props.theme['gray-400']};
    `}
`;

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ItemTitle = styled.div`
  ${h3};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 4px;
`;

export const ItemDescription = styled.div`
  color: ${props => props.theme['gray-400']};
  ${bodyLargeRegular};
`;

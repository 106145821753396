import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { VerificationEventsState } from './types';
import * as actions from './actions';

export type ActionsType = ActionType<typeof actions>;

const initialState: VerificationEventsState = {
  data: [],
  page: 1,
  hasLoaded: false,
};

const reducer: Reducer<VerificationEventsState> = (state = initialState, action: ActionsType): VerificationEventsState => {
  switch (action.type) {
    case getType(actions.fetchVerificationEventsAction):
      return { ...state, data: action.payload.data };
    default: {
      return state;
    }
  }
};

export { reducer as logsReducer };

import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { debounce } from 'lodash';
import * as El from './trust-platform.container.css';

import { ApplicationState } from '../../../redux/store';
import { AsyncComponent } from '../../../components/async-component/async-component';

import {
  createProvider as createProviderRedux,
  deleteProvider as deleteProviderRedux,
  fetchProviders as fetchProvidersRedux,
  updateProvider as updateProviderRedux,
} from '../../../redux/providers/async';
import { ProvidersTable } from '../components/providers-table/providers-table';
import { PageHeader } from '../../../components/page-header/page-header';
import { TableHeading } from '../components/table-heading/table-heading';
import { ApplicationsPage } from './trust-platform.container.css';
import { PageWrapper } from '../../../components/layouts/main-layout/main-layout/main-layout.css';

const mapStateToProps = ({ providers }: ApplicationState) => ({
  providers: providers.data,
  hasLoaded: providers.hasLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProviders: bindActionCreators(fetchProvidersRedux, dispatch),
  createProvider: bindActionCreators(createProviderRedux, dispatch),
  deleteProvider: bindActionCreators(deleteProviderRedux, dispatch),
  updateProvider: bindActionCreators(updateProviderRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const Admin: FC<AllProps> = props => {
  const { fetchProviders, createProvider, providers, hasLoaded, deleteProvider, updateProvider } = props;
  const [filteredProviders, setFilteredProviders] = useState(providers);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setFilteredProviders(onSearch(searchQuery));
  }, [providers]);

  const onSearch = (query: string): any[] => {
    if (!query) {
      return providers;
    }
    const r = new RegExp(query, 'gmi');
    return providers.filter(provider => provider.name.search(r) !== -1);
  };

  const debouncedSearch = useCallback(
    debounce(query => {
      setFilteredProviders(onSearch(query));
    }, 500),
    [providers],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchQuery(value);
      debouncedSearch(value);
    },
    [providers],
  );

  return (
    <AsyncComponent message="Loading Trust Platform Environments" getAsyncActions={() => [fetchProviders()]}>
      <PageWrapper>
        <PageHeader title="Trust Platform Directory" description="Register an Authorization Server with the IDPartner Network" />
        <El.InformationAlert
          message="Before you start"
          description={
            <>
              In order for the Authorization Server to be discoverable within the IDPartner Network, it needs to be registered as an Authorization Server with the IDPartner Directory.{' '}
              <a href="mailto:support@idpartner.com">Contact us</a> to get access to the IDPartner Trust Platform. Detailed instructions on how to configure and connect with existing oauth services
              can be viewed in our <a href="https://docs.idpartner.com/documentation/identity-provider-user-guide">Documentation.</a>
            </>
          }
          type="warning"
          showIcon
        />

        <ApplicationsPage>
          <TableHeading handleSearch={handleSearch} createProvider={createProvider} />

          <ProvidersTable createProvider={createProvider} updateProvider={updateProvider} deleteProvider={deleteProvider} providers={filteredProviders} hasLoaded={hasLoaded} />
        </ApplicationsPage>
      </PageWrapper>
    </AsyncComponent>
  );
};

export const TrustPlatformContainer = connect(mapStateToProps, mapDispatchToProps)(Admin);

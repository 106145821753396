import React, { FC } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { NewSubscriptionForm } from '../new-subscription-form/new-subscription-form';
import { ApplicationState } from '../../redux/store';
import {
  fetchOffers as fetchOffersRedux,
  fetchPaymentMethods as fetchPaymentMethodsRedux,
  fetchPaymentProfile as fetchPaymentProfileRedux,
  fetchUserInvoices as fetchUserInvoicesRedux,
  fetchUserSubscription as fetchUserSubscriptionRedux,
} from '../../redux/billing/async';
import { BillingForm } from '../billing-form/billing-form';
import { AsyncComponent } from '../async-component/async-component';
import { PageWrapper } from '../layouts/main-layout/main-layout/main-layout.css';

const mapStateToProps = ({ billing }: ApplicationState) => ({
  userSubscription: billing.userSubscription,
  paymentProfile: billing.paymentProfile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOffers: bindActionCreators(fetchOffersRedux, dispatch),
  fetchUserSubscription: bindActionCreators(fetchUserSubscriptionRedux, dispatch),
  fetchPaymentMethods: bindActionCreators(fetchPaymentMethodsRedux, dispatch),
  fetchPaymentProfile: bindActionCreators(fetchPaymentProfileRedux, dispatch),
  fetchUserInvoices: bindActionCreators(fetchUserInvoicesRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Billing: FC<AllProps> = ({ fetchOffers, paymentProfile, fetchPaymentMethods, fetchUserSubscription, fetchPaymentProfile, fetchUserInvoices, userSubscription }) => {
  const isShowSubscriptionFlow = !paymentProfile || paymentProfile?.deleted || !userSubscription || (typeof userSubscription === 'object' && !Object.keys(userSubscription).length);
  return (
    <AsyncComponent showError={false} message="Loading..." getAsyncActions={() => [fetchPaymentProfile(), fetchOffers(), fetchPaymentMethods(), fetchUserSubscription(), fetchUserInvoices()]}>
      <PageWrapper>{isShowSubscriptionFlow ? <NewSubscriptionForm /> : <BillingForm />}</PageWrapper>
    </AsyncComponent>
  );
};

export const BillingPage = connect(mapStateToProps, mapDispatchToProps)(Billing);

import React, { FC } from 'react';
import * as El from './payment-method.css';
import { PaymentMethodProps } from './types';

export const PaymentMethod: FC<PaymentMethodProps> = ({ cardBrand, cardLast4, holderName, expiresData, isActive, onClick }) => (
  <El.PaymentMethodWrapper isActive={isActive} onClick={onClick}>
    <El.ItemText>
      <El.ItemTitle>
        {cardBrand} **** {cardLast4}
      </El.ItemTitle>
      <El.ItemDescription>{holderName}</El.ItemDescription>
    </El.ItemText>

    <El.ItemDescription>Expires {expiresData}</El.ItemDescription>
  </El.PaymentMethodWrapper>
);

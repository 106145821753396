import styled from 'styled-components';

export const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
`;

export const ContentWrapper = styled.div`
  width: 447px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepTitle = styled.div`
  color: #000;
  font-family: Visby CF, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 113%;
  width: 100%;
  margin-bottom: 12px;
`;

export const FormDescription = styled.div`
  color: #4e5466;
  font-family: Public Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 32px;
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 64px;
  background: #f0f2f5;
  padding: 10px;
  img {
    display: flex;
    margin-top: 21px;
    margin-bottom: 21px;
    justify-content: center;
    margin: 0 auto;
  }
`;

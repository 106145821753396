import styled from 'styled-components';
import { bodySmallRegular } from '../../../common.css';

export const IDPartnerFormStyled = styled.div`
  width: 100%;

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-explain-error {
    ${bodySmallRegular};
    color: ${props => props.theme.error};
    margin-top: 6px;
    margin-bottom: 16px;
  }

  .ant-form-item-label {
    font-family: Public Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%;
    padding-bottom: 6px;
    & > label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 154%;
    }
  }
`;

import React, { FC, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as El from '../../../../components/modal-app-state/modal-loader.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { DownloadJwks } from './download-jwks/download-jwks';
import { AddCertificateForm } from './add-certificate-form/add-certificate-form';
import { Application, Certificate } from '../../../../redux/applications/types';
import { createCertificate as createCertificateRedux } from '../../../../redux/applications/async';
import certificateSuccessIcon from '../../../../assets/icons/certificate-success.svg';
import appErrorIcon from '../../../../assets/icons/app-error.svg';
import { AddCertificateModalStages } from '../add-certificate-modal/add-certificate-modal';

type AddCertificateFlowProps = {
  onClose?: (e: any) => void;
  application?: Application;
  stage: AddCertificateModalStages;
  onSubmit: (stage: AddCertificateModalStages) => void;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCertificate: bindActionCreators(createCertificateRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & AddCertificateFlowProps;

const Component: FC<AllProps> = ({ onClose, application, createCertificate, stage, onSubmit }) => {
  const [certData, setCertData] = useState(null);
  const [currentCertificate, setCurrentCertificate] = useState<Certificate>(null);
  const handleGenerateCertificateSubmit = async (data: any) => {
    setCertData(certData);
    onSubmit('certificate-loading');
    try {
      const cert = (await createCertificate(application, data)) as unknown as Certificate;
      setCurrentCertificate(cert);
      if (cert.jwks_signing_keypair) {
        onSubmit('download-jwks');
      } else {
        onSubmit('certificate-success');
      }
    } catch {
      onSubmit('certificate-error');
    }
  };

  switch (stage) {
    case 'generate-certificate':
      return <AddCertificateForm application={application} onSubmit={handleGenerateCertificateSubmit} onCancel={onClose} />;
    case 'certificate-loading':
      return (
        <El.AppStateWrapper>
          <El.LoaderAnimation />
          <El.LoaderText>Processing</El.LoaderText>
          <El.Description>A certificate is currently being created for your application. You may close this window.</El.Description>
          <IDPartnerButton block onClick={onClose} type="primary" size="large">
            CLOSE
          </IDPartnerButton>
        </El.AppStateWrapper>
      );
    case 'certificate-success':
      return (
        <El.AppStateWrapper>
          <El.AppStateIllustration url={certificateSuccessIcon} />
          <El.Description>
            The certificate issuance process has started. To reflect this change, you may need to refresh the page until the process is completed. Once issuance is completed your application will be
            ready for use.
          </El.Description>
          <IDPartnerButton block onClick={onClose} type="primary" size="large">
            CLOSE
          </IDPartnerButton>
        </El.AppStateWrapper>
      );
    case 'certificate-error':
      return (
        <El.AppStateWrapper>
          <El.AppStateIllustration url={appErrorIcon} />
          <El.Description>Unfortunately, we were unable to generate and add a certificate to your application. Please try again.</El.Description>
          <El.ButtonsWrapper>
            <IDPartnerButton block onClick={() => handleGenerateCertificateSubmit(certData)} size="large">
              TRY LATER
            </IDPartnerButton>
            <IDPartnerButton block onClick={onClose} type="primary" size="large">
              TRY AGAIN
            </IDPartnerButton>
          </El.ButtonsWrapper>
        </El.AppStateWrapper>
      );
    case 'download-jwks':
      return <DownloadJwks certificate={currentCertificate} application={application} onClose={onClose} />;
    default:
      return null;
  }
};

export const AddCertificateFlow = connect(null, mapDispatchToProps)(Component);

import React, { FC } from 'react';
import * as El from './invoice.css';
import { InvoiceProps } from './types';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';

export const Invoice: FC<InvoiceProps> = ({ name, invoiceLink, createdAt, status, amountDue, amountPaid }) => (
  <El.InvoiceWrapper>
    <El.ItemStatus status={status}>{status}</El.ItemStatus>
    <El.ItemTitle>{name}</El.ItemTitle>
    <El.ItemDescription>{`To pay: $${amountDue}`}</El.ItemDescription>
    <El.ItemDescription>{`Paid: $${amountPaid}`}</El.ItemDescription>
    <El.ItemDescription>{new Date(createdAt * 1000).toDateString()}</El.ItemDescription>
    <IDPartnerButton href={invoiceLink} target="_blank" type="primary">
      Download PDF
    </IDPartnerButton>
  </El.InvoiceWrapper>
);

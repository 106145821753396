import React, { FC, useState } from 'react';
import { AddCertificateModalProps } from './types';
import { IDPartnerModal } from '../../../../components/ui/idpartner-modal/idpartner-modal';
import { AddCertificateFlow } from '../add-certificate-flow';
import * as El from './styles';

export type AddCertificateModalStages = 'generate-certificate' | 'certificate-loading' | 'certificate-success' | 'certificate-error' | 'download-jwks';
export const AddCertificateModal: FC<AddCertificateModalProps> = props => {
  const { onCancel, application } = props;
  const [stage, setStage] = useState<AddCertificateModalStages>('generate-certificate');

  const handleSubmit = (newStage: AddCertificateModalStages) => {
    setStage(newStage);
  };

  const getModalTitleByStage = (currentStage: AddCertificateModalStages) => {
    switch (currentStage) {
      case 'generate-certificate':
        return 'Generate Certificate';
      case 'certificate-loading':
        return 'Certificate Generation Underway';
      case 'certificate-success':
        return 'Certificate Issuance Started';
      case 'certificate-error':
        return 'Certificate Generation Failed';
      case 'download-jwks':
        return 'Certificate Requested';
      default:
        return 'Generate Certificate';
    }
  };

  const getModalWidth = (currentStage: AddCertificateModalStages) => {
    const stagesWithSmallPopups: AddCertificateModalStages[] = ['certificate-loading', 'certificate-success', 'certificate-error', 'download-jwks'];
    if (stagesWithSmallPopups.includes(currentStage)) {
      return 518;
    }
    return 614;
  };

  return (
    <IDPartnerModal
      {...props}
      key="generate-certificate"
      title={<El.ModalTitle style={{ textAlign: ['generate-certificate'].includes(stage) ? 'left' : 'center' }}>{getModalTitleByStage(stage)}</El.ModalTitle>}
      width={getModalWidth(stage)}
      footer={null}
      destroyOnClose
    >
      <AddCertificateFlow stage={stage} onSubmit={handleSubmit} application={application} onClose={onCancel} />
    </IDPartnerModal>
  );
};

import { createAsyncAction, createAction } from 'typesafe-actions';

import { AuthActionTypes, AccountType } from './types';

export const authAsyncActions = createAsyncAction(AuthActionTypes.TOKEN_REQUEST, AuthActionTypes.TOKEN_RECEIVED, AuthActionTypes.TOKEN_FAILURE)<void, string, string>();

export const getUserAsyncActions = createAsyncAction(AuthActionTypes.FETCH_USER, AuthActionTypes.FETCH_USER_SUCCESS, AuthActionTypes.FETCH_USER_ERROR)<void, AccountType, string>();

export const fetchUsersAction = createAction(AuthActionTypes.FETCH_USERS, resolve => (users: AccountType[]) => resolve(users));

export const fetchAccountAction = createAction(AuthActionTypes.FETCH_ACCOUNT, resolve => (model: AccountType) => resolve(model));

export const updateAccountAction = createAction(AuthActionTypes.UPDATE_ACCOUNT, resolve => (model: AccountType) => resolve(model));

import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { EditProviderModalProps } from './types';
import { IDPartnerModal } from '../../../../components/ui/idpartner-modal/idpartner-modal';
import { ProviderForm } from '../provider-form/provider-form';
import { addProtocolToUrl } from '../../../../utils/url';
import { Provider } from '../../../../redux/providers/types';

export const EditProviderModal: FC<EditProviderModalProps> = props => {
  const { onCancel, onOk, open, onSubmit, application } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [form] = Form.useForm();

  useEffect(() => {
    setCurrentStep(1);
  }, [open]);

  useEffect(() => {}, []);

  const goToTheNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToThePrevStep = () => {
    if (currentStep <= 1) {
      return setCurrentStep(1);
    }
    setCurrentStep(currentStep - 1);
  };

  const formatUrls = (data: Provider): Provider => {
    const result: Provider = { ...data };
    const urlFields = ['url', 'site_url'];

    Object.entries(result).forEach(([field, value]: [keyof Provider, any]) => {
      if (value && urlFields.includes(field)) {
        // @ts-ignore
        result[field] = addProtocolToUrl(String(value));
      }
    });

    return result;
  };

  const handleFormSubmit = (e: React.MouseEvent<HTMLElement>) => {
    const fields = formatUrls({ ...form.getFieldsValue(), id: undefined });
    onSubmit(fields);
    if (typeof onOk === 'function') {
      onOk(e);
    }
  };

  return (
    <IDPartnerModal {...props} key="edit-app" title="Edit Trust Platform" footer={null} destroyOnClose>
      <ProviderForm
        provider={application}
        step={currentStep}
        form={form}
        onNextStep={goToTheNextStep}
        onPrevStep={goToThePrevStep}
        onSubmit={handleFormSubmit}
        onCancel={onCancel}
        submitButtonTitle="Update"
        formProps={{ preserve: false }}
      />
    </IDPartnerModal>
  );
};

import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { Application, Certificate } from './types';
import { Api } from '../../modules/utils/api';

import { withAuth } from '../util';
import { createApplicationAction, fetchApplicationsAction, updateApplicationAction, deleteApplicationAction, addCertificateAction, updateCertificateAction } from './actions';

export const fetchApplications = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.fetchApplications().then((result: AxiosResponse<Application[]>) => {
      dispatch(fetchApplicationsAction({ data: result.data, hasLoaded: true }));
    }),
  );

export const updateApplication = (application: Application) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.updateApplication(application).then((result: AxiosResponse<Application>) => {
      dispatch(updateApplicationAction(result.data));
      return result.data;
    }),
  );

export const createApplication = (application: Application) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.createApplication(application).then((result: AxiosResponse<Application>) => {
      dispatch(createApplicationAction(result.data));
      return result.data;
    }),
  );

export const deleteApplication = (id: number) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.deleteApplication(id).then((result: AxiosResponse<Application>) => {
      dispatch(deleteApplicationAction(id));
    }),
  );

export const createCertificate = (application: Application, payload: any) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.createCertificate(application, payload).then((result: AxiosResponse<Certificate>) => {
      dispatch(
        addCertificateAction({
          ...result.data,
          application_id: application.id,
        }),
      );
      return { ...result.data, application_id: application.id };
    }),
  );

export const updateCertificate = (application: Application, certificateId: Certificate['id']) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.getCertificate(application, certificateId).then((result: AxiosResponse<Certificate>) => {
      dispatch(
        updateCertificateAction({
          ...result.data,
          application_id: application.id,
        }),
      );
      return result.data;
    }),
  );

import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Form, notification } from 'antd';
import * as El from './application-wizard.css';
import { Language } from './language/language';
import { IDPartnerButton } from '../../components/ui/idpartner-button/idpartner-button';
import { AppSettings } from './app-settings/app-settings';
import { Application, LanguagesTypes } from '../../redux/applications/types';
import { createApplication as createApplicationRedux, updateApplication as updateApplicationRedux } from '../../redux/applications/async';
import { AppType } from '../applications/components/table-heading/types';

export type WizardMode = 'language' | 'app-settings';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createApplication: bindActionCreators(createApplicationRedux, dispatch),
  // deleteApplication: bindActionCreators(deleteApplicationRedux, dispatch),
  updateApplication: bindActionCreators(updateApplicationRedux, dispatch),
});

type ApplicationWizardProps = ReturnType<typeof mapDispatchToProps> & {
  wizardAppType: AppType;
  application?: Application;
  onClose: () => void;
};

export type SettingsModeType = 'quick-start' | 'settings' | 'theme' | 'certificates';

const Component: FC<ApplicationWizardProps> = ({ wizardAppType, application, onClose, updateApplication, createApplication }) => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState<LanguagesTypes>(application?.language || 'javascript');
  const [newAppName, setNewAppName] = useState('');
  const [mode, setMode] = useState<WizardMode>(application ? 'app-settings' : 'language');
  const [isLoading, setIsLoading] = useState(false);
  const [createdApplication, setCreatedApplication] = useState<Application | undefined>();
  const [isLanguageSkipped, setIsLanguageSkipped] = useState(false);
  const [settingsMode, setSettingsMode] = useState<SettingsModeType>(createdApplication ? 'quick-start' : 'settings');

  const onSkipLanguageStep = () => {
    setMode('app-settings');
    setIsLanguageSkipped(true);
  };

  const onCreateApp = async () => {
    const newAppValues: Application = {
      name: newAppName,
      logo_url: 'https://assets.idpartner.com/brandco.svg',
      redirect_url: 'http://localhost:3001/button/oauth/callback',
      origin_url: 'http://localhost:3001/button/oauth',
      logo: '',
      registration_redirect_url: '',
      authentication_callback: '',
      account_id: null,
      client_id: '',
      client_secret: '',
      created_at: '',
      id: null,
      skip_consent: false,
      updated_at: '',
      metadata: [],
      auth_mode: 'redirect',
      jwks: undefined,
      jwks_uri: undefined,
      test: wizardAppType === 'sandbox',
      token_endpoint_auth_method: 'client_secret_basic',
      jwks_behaviour: 'no_jwks',
      language,
    };

    setIsLoading(true);
    if (newAppName && language) {
      const createdApp = await createApplication(newAppValues);
      setCreatedApplication(createdApp as unknown as Application);
    }
    setIsLoading(false);

    setSettingsMode('quick-start');
    setMode('app-settings');
  };

  const onSaveOrCreate = async () => {
    setIsLoading(true);
    const formData = form.getFieldsValue();
    if (isLanguageSkipped) {
      const app = await createApplication(formData);
      form.setFieldsValue(app);
      setIsLanguageSkipped(false);
      notification.success({
        message: 'Success',
        description: 'Application Created',
      });
      onClose();
    } else {
      const app = await updateApplication({ ...formData, id: application?.id || createdApplication.id });
      form.setFieldsValue(app);
      notification.success({
        message: 'Success',
        description: 'Application Updated',
      });
    }
    setIsLoading(false);
  };

  const onBack = () => {
    if (mode === 'language' || application || createdApplication) {
      onClose();
    } else {
      setMode('language');
    }
  };

  const onSettingsChange = (key: string) => {
    setSettingsMode(key as SettingsModeType);
  };

  const modeToComponentMap = {
    language: (
      <Language
        onSkipLanguageStep={onSkipLanguageStep}
        selectedLanguage={language}
        onChangeLanguage={(lang: LanguagesTypes) => setLanguage(lang)}
        newAppName={newAppName}
        onAppNameChange={(appName: string) => setNewAppName(appName)}
      />
    ),
    'app-settings': <AppSettings selectedLanguage={language} application={application || createdApplication} form={form} settingsMode={settingsMode} onSettingsChange={onSettingsChange} />,
  };

  const footer = () => {
    if (mode === 'language') {
      return (
        <>
          <IDPartnerButton onClick={onBack}>{application ? 'BACK TO MY APPS' : 'BACK'}</IDPartnerButton>
          <El.FooterRightCorner>
            <IDPartnerButton type="primary" onClick={onCreateApp} disabled={!newAppName || !language}>
              CREATE APPLICATION
            </IDPartnerButton>
          </El.FooterRightCorner>
        </>
      );
    }
    if (mode === 'app-settings') {
      return (
        <>
          <IDPartnerButton onClick={onBack}>{application || createdApplication ? 'BACK TO MY APPS' : 'BACK'}</IDPartnerButton>
          {settingsMode === 'settings' ? (
            <IDPartnerButton type="primary" onClick={onSaveOrCreate} loading={isLoading} disabled={isLoading}>
              {isLanguageSkipped ? 'CREATE' : 'SAVE'}
            </IDPartnerButton>
          ) : (
            <div />
          )}
        </>
      );
    }
    return null;
  };

  return (
    <El.ApplicationWizardWrapper>
      <El.WizardContent>{modeToComponentMap[mode]}</El.WizardContent>
      <El.WizardFooter>{footer()}</El.WizardFooter>
    </El.ApplicationWizardWrapper>
  );
};

export const ApplicationWizard = connect(null, mapDispatchToProps)(Component);

import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AuthLayout } from '../../components/layouts/auth-layout/auth-layout';
import { IDPartnerButton } from '../../components/ui/idpartner-button/idpartner-button';

export interface Props {
  errorMessage: any;
}

const ErrorMessage = styled.div`
  font-size: 22px;
`;

const Container = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const ErrorContainer: FC<Props> = props => {
  const { errorMessage } = props;
  return (
    <AuthLayout>
      <Container>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Link to="/">
          <IDPartnerButton type="primary" size="large" style={{ marginTop: 30 }}>
            GO TO DASHBOARD
          </IDPartnerButton>
        </Link>
      </Container>
    </AuthLayout>
  );
};

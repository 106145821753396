import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { featureFlags } from '../../../../utils/featureFlags';
import { ApplicationsIcon, DocumentationIcon, ExternalLinkIcon, SidebarMenuItem, SidebarMenuStyled, LinkContainer, LogsIcon, TrustPlatformIcon, BillingIcon, TeamIcon } from './sidebar-menu.css';

export const SidebarMenu: FC = () => {
  const menuItems = [
    {
      action: <Link to="/applications">Applications</Link>,
      icon: <ApplicationsIcon />,
    },
  ];

  if (featureFlags.isTeamsEnabled) {
    menuItems.push({
      action: <Link to="/team">Team</Link>,
      icon: <TeamIcon />,
    });
  }

  if (featureFlags.isTrustPlatformEnabled) {
    menuItems.push({
      action: <Link to="/platform">Trust Platform Directory</Link>,
      icon: <TrustPlatformIcon />,
    });
  }

  if (featureFlags.isLogsEnabled) {
    menuItems.push({
      action: <Link to="/logs">Logs</Link>,
      icon: <LogsIcon />,
    });
  }

  if (featureFlags.isBillingEnabled) {
    menuItems.push({
      action: <Link to="/billing">Billing</Link>,
      icon: <BillingIcon />,
    });
  }

  menuItems.push({
    action: (
      <LinkContainer href="https://docs.idpartner.com/documentation/" target="_blank" onClick={() => mixpanel.track('dashboard_client:click:documentation')}>
        <span>Documentation</span>
        <ExternalLinkIcon />
      </LinkContainer>
    ),
    icon: <DocumentationIcon />,
  });

  return (
    <SidebarMenuStyled selectable={false}>
      {menuItems.map((item, index) => (
        <SidebarMenuItem key={index}>
          {item.icon}
          {item.action}
        </SidebarMenuItem>
      ))}
    </SidebarMenuStyled>
  );
};

import React, { FC } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import * as El from './applications-grid.css';
import { Copy } from '../../../../components/ui/copy/copy';
import { Application, Certificate } from '../../../../redux/applications/types';
import { INACTIVE_STATUSES } from '../../../application-wizard/app-settings/certificates/constants';

type ApplicationItemProps = {
  application: Application;
  onSelectApp: (id: Application['id']) => void;
  onDeleteApp: (appId: Application['id']) => void;
};
export const ApplicationItem: FC<ApplicationItemProps> = ({ application, onSelectApp, onDeleteApp }) => {
  const CertificateItem: FC<{ certificates: Certificate[] }> = ({ certificates }) => {
    if (!certificates || !certificates.length) {
      return <El.InfoDescription type="error">no active certificates</El.InfoDescription>;
    }
    const expiringCertsCount = certificates.filter(cert => cert.status === 'expiring').length;
    const inactiveCertsCount = certificates.filter(cert => INACTIVE_STATUSES.includes(cert.status)).length;
    if (inactiveCertsCount) {
      return <El.InfoDescription type="error">{inactiveCertsCount} inactive certificates</El.InfoDescription>;
    }
    if (expiringCertsCount) {
      return <El.InfoDescription type="warning">{expiringCertsCount} expiring certificates</El.InfoDescription>;
    }

    return <El.InfoDescription type="success">{certificates.length} active certificates</El.InfoDescription>;
  };

  const handleDeleteClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteApp(application.id);
  };
  return (
    <El.AppCard onClick={() => onSelectApp(application.id)}>
      <El.AppTitle>{application.name}</El.AppTitle>
      <El.AppInfoWrapper>
        <El.InfoTitle>AUTH METHOD</El.InfoTitle>
        <El.InfoDescription>{application.auth_mode}</El.InfoDescription>
      </El.AppInfoWrapper>
      <El.AppInfoWrapper>
        <El.InfoTitle>CLIENT ID</El.InfoTitle>
        <El.InfoDescription>
          <Copy hint="Copy Client ID">{application.client_id}</Copy>
        </El.InfoDescription>
      </El.AppInfoWrapper>
      {application.client_secret ? (
        <El.AppInfoWrapper>
          <El.InfoTitle>CLIENT SECRET</El.InfoTitle>
          <El.InfoDescription>
            <Copy hidden hint="Copy Client Secret">
              {application.client_secret}
            </Copy>
          </El.InfoDescription>
        </El.AppInfoWrapper>
      ) : (
        <El.AppInfoWrapper>
          <El.InfoTitle>CERTIFICATE</El.InfoTitle>
          <CertificateItem certificates={application.certificates} />
        </El.AppInfoWrapper>
      )}
      <El.DeleteButton onClick={handleDeleteClick}>
        <DeleteOutlined style={{ fontSize: 18 }} />
      </El.DeleteButton>
    </El.AppCard>
  );
};

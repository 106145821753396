import React, { FC } from 'react';
import { noop } from 'lodash';
import { AuthSuccessProps } from './types';
import { AuthSuccessStyled, Buttons, Illustration, Message, Title } from './update-password-success.css';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import lock from '../../assets/lock.svg';

export const UpdatePasswordSuccess: FC<AuthSuccessProps> = ({ onContinue = noop }) => (
  <AuthSuccessStyled>
    <Illustration>
      <img src={lock} alt="success" />
    </Illustration>
    <Title>Your Password Updated!</Title>
    <Message>Now you can sign into your account and continue using ID Partners</Message>
    <Buttons>
      <IDPartnerButton size="large" type="primary" onClick={onContinue} block>
        Log in
      </IDPartnerButton>
    </Buttons>
  </AuthSuccessStyled>
);

import React, { FC } from 'react';
import { PageHeaderProps } from './types';
import { PageDescription, PageTitle, PageHeader as PageHeaderStyled } from './page-header.css';

export const PageHeader: FC<PageHeaderProps> = ({ title, description }) => {
  return (
    <PageHeaderStyled>
      <PageTitle>{title}</PageTitle>
      {description ? <PageDescription>{description}</PageDescription> : null}
    </PageHeaderStyled>
  );
};

import styled from 'styled-components';
import { Pagination, Table } from 'antd';

export const StyledTable = styled(Table)`
  .ant-table {
    border-radius: 4px;
    border: 1px solid ${props => props.theme['neutral-40']};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    color: ${props => props.theme['neutral-90']};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .ant-table-thead > tr > th {
    font-weight: 500;
    line-height: 18px;
    color: ${props => props.theme['neutral-90']};
    background: ${props => props.theme['neutral-40']};
  }
  .ant-table-tbody > tr > td {
    font-weight: 500;
    line-height: 20px;
    padding: 14px 16px;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  .ant-table-tbody > tr:nth-child(2):hover > td,
  .ant-table-tbody > tr:nth-child(2) > td.ant-table-cell-row-hover {
    background: #f8f8f8;
  }

  & .ant-table-pagination {
    display: none;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  align-items: center;
  font-size: 14px;
`;

export const StyledPagination = styled(Pagination)`
  &.ant-pagination {
    display: flex;
    align-items: center;
  }
  & .ant-pagination-options {
    display: none;
  }
  & .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid ${props => props.theme['neutral-40']};
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    & .ant-pagination-item-link {
      border: none;
      background-color: transparent;
    }

    &.ant-pagination-item-active {
      border: 1px solid ${props => props.theme['light-blue-200']};

      & a {
        color: ${props => props.theme['light-blue-200']};
      }
    }

    &:not(.ant-pagination-disabled):hover {
      border: 2px solid ${props => props.theme['light-blue-200']};
    }
  }
`;

export const PaginationText = styled.div`
  color: ${props => props.theme['neutral-90']};
`;

export const PaginationDropdown = styled.div`
  display: flex;
  align-items: center;

  .ant-select-selector {
    background-color: ${props => props.theme['base-colors-gray-3']} !important;
    //background-color: blue !important;
  }
`;

export const DropdownText = styled(PaginationText)`
  margin-right: 12px;
`;

import React, { FC } from 'react';
import { FormInstance } from 'antd';
import * as El from './settings.css';
import { EditApplicationForm } from '../../../applications/components/edit-application-form/edit-application-form';
import { Application } from '../../../../redux/applications/types';

type SettingsProps = {
  form: FormInstance<any>;
  application?: Application;
};
export const Settings: FC<SettingsProps> = ({ form, application }) => (
  <El.SettingsWrapper>
    <EditApplicationForm isHideFormButtons form={form} application={application} />
  </El.SettingsWrapper>
);

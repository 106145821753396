import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import mixpanel from 'mixpanel-browser';
import { CreateProviderModalProps } from './types';
import { IDPartnerModal } from '../../../../components/ui/idpartner-modal/idpartner-modal';
import { ProviderForm } from '../provider-form/provider-form';

export const CreateProviderModal: FC<CreateProviderModalProps> = props => {
  const { onCancel, onOk, open, onSubmit } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [form] = Form.useForm();

  useEffect(() => {
    setCurrentStep(1);
  }, [open]);

  const goToTheNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToThePrevStep = () => {
    if (currentStep <= 1) {
      return setCurrentStep(1);
    }
    setCurrentStep(currentStep - 1);
  };

  const handleFormSubmit = (e: React.MouseEvent<HTMLElement>) => {
    onSubmit(form.getFieldsValue());
    mixpanel.track('dashboard_client:click:create_provider');
    if (typeof onOk === 'function') {
      onOk(e);
    }
  };

  return (
    <IDPartnerModal {...props} key="create-app" title="Register a Trust Platform" footer={null} destroyOnClose>
      <ProviderForm
        step={currentStep}
        form={form}
        onNextStep={goToTheNextStep}
        onPrevStep={goToThePrevStep}
        onSubmit={handleFormSubmit}
        onCancel={onCancel}
        submitButtonTitle="Create provider"
        formProps={{ preserve: false }}
      />
    </IDPartnerModal>
  );
};

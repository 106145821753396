import React, { FC, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import * as El from './lei-check-form.css';
import { IDPartnerInput } from '../ui/idpartner-input/idpartner-input';
import { IDPartnerAlert } from '../ui/idpartner-alert/idpartner-alert';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { IDPartnerForm } from '../ui/idpartner-form/idpartner-form';
import { fetchSessionAccount as fetchSessionAccountRedux, verifyLei } from '../../redux/auth/async';
import { ApplicationState } from '../../redux/store';
import { LeiCheckFormProps } from './types';

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccount: bindActionCreators(fetchSessionAccountRedux, dispatch),
});

const LeiCheck: FC<LeiCheckFormProps> = ({ history, account, fetchAccount }) => {
  const [error, setError] = useState<string | undefined>();
  const [form] = Form.useForm();

  const initialValues = {
    lei: account.lei,
  };

  const onVerify = async () => {
    setError(undefined);
    const validationResult = await form.validateFields();
    const isCurrentStepValid = !validationResult.errorFields || !validationResult.errorFields.length;
    if (isCurrentStepValid) {
      try {
        const result = await verifyLei(form.getFieldValue('lei'));
        const { verified } = result?.data;
        fetchAccount();
        if (verified) {
          history.push('/onboarding');
        } else {
          setError('Something went wrong');
        }
      } catch (e) {
        console.error(e);
        const {
          response: { data },
        } = e;
        setError(data.error_description);
      }
    }
  };

  return (
    <El.LeiCheckContainer>
      <El.LeiCheckFormWrapper>
        <El.FormTitle>Legal Entity Identifier</El.FormTitle>
        <El.FormDescription>The Legal Entity Identifier (LEI) is a 20-character, alpha-numeric code. It contains information about an entity’s ownership structure.</El.FormDescription>

        {error && (
          <El.ErrorWrapper>
            <IDPartnerAlert type="error" showIcon description={error} />
          </El.ErrorWrapper>
        )}
        <El.FormContentWrapper>
          <IDPartnerForm>
            <Form layout="vertical" requiredMark="optional" form={form} initialValues={initialValues}>
              <Form.Item
                label="Legal Entity Identifier"
                name="lei"
                rules={[
                  {
                    required: true,
                    pattern: /^[A-Z0-9]{18}\d{2}$/,
                    message: 'LEI must contain 20 characters, digits and capital letters',
                  },
                ]}
              >
                <IDPartnerInput />
              </Form.Item>
            </Form>
          </IDPartnerForm>
        </El.FormContentWrapper>
      </El.LeiCheckFormWrapper>
      <El.Footer>
        <Link to="/">
          <IDPartnerButton>BACK</IDPartnerButton>
        </Link>
        <IDPartnerButton type="primary" onClick={onVerify} disabled={Boolean(account.lei_verified_at)}>
          VERIFY
        </IDPartnerButton>
      </El.Footer>
    </El.LeiCheckContainer>
  );
};

export const LeiCheckForm = connect(mapStateToProps, mapDispatchToProps)(withRouter(LeiCheck));

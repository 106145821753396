import React, { FC } from 'react';
import * as El from './download-jwks.css';
import { Application, Certificate } from '../../../../../redux/applications/types';
import { IDPartnerButton } from '../../../../../components/ui/idpartner-button/idpartner-button';
import { IDPartnerAlert } from '../../../../../components/ui/idpartner-alert/idpartner-alert';
import certSuccessIcon from '../../../../../assets/icons/cert-success.svg';

type DownloadJwksProps = {
  application: Application;
  certificate?: Certificate;
  onClose: (e: any) => void;
};
export const DownloadJwks: FC<DownloadJwksProps> = ({ application, certificate, onClose }) => {
  const handleDownload = (e: any) => {
    if (certificate?.jwks_signing_keypair?.pemPrivateKey) {
      const cert = document.createElement('a');
      const file = new Blob([certificate.jwks_signing_keypair.pemPrivateKey], { type: 'text/plain' });
      cert.href = URL.createObjectURL(file);
      cert.download = `${application.id}_${new Date().getTime()}_signing_key.pem`;
      document.body.appendChild(cert); // Required for this to work in FireFox
      cert.click();
    }
    onClose(e);
  };
  return (
    <El.DownloadJwksContent>
      <El.AppStateIllustration url={certSuccessIcon} />
      <El.Text>Certificate requested. Download the JWKS Private Key to sign requests now. After, refresh your browser to be able to download the certificate.</El.Text>
      <IDPartnerAlert type="warning" showIcon description="This is your ONLY opportunity to download the JWKS. Secure it immediately, as you will NOT be granted access again." />
      <El.ButtonsWrapper>
        <IDPartnerButton block type="primary" onClick={handleDownload} size="large">
          DOWNLOAD JWKS PRIVATE KEY AND CLOSE
        </IDPartnerButton>
      </El.ButtonsWrapper>
    </El.DownloadJwksContent>
  );
};

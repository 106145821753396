// URI OR JWKS itself has to be provided but only one value
// eslint-disable-next-line no-unused-vars
import { FieldStatus } from '../../../modules/applications/components/edit-application-form/types';
import { featureFlags } from '../../../utils/featureFlags';
import { Application } from '../../../redux/applications/types';

const prettierFields = (obj: any, form: any) => {
  form.setFieldValue('jwks', JSON.stringify(obj, undefined, 2));
};
export const validateJWKS = ({ form, application, defaultValues }: { form: any; application: Application; defaultValues: any }): Record<'jwks' | 'jwks_uri', FieldStatus> => {
  const jwksURI = form.getFieldValue('jwks_uri');
  const jwks = form.getFieldValue('jwks');
  if (application?.token_endpoint_auth_method === 'private_key_jwt' && !jwksURI && !jwks) {
    return {
      jwks_uri: {
        validateStatus: 'error',
        errorMsg: 'JWKS URI or JWKS is required',
        isDisabled: false,
      },
      jwks: {
        validateStatus: 'error',
        errorMsg: 'JWKS URI or JWKS is required',
        isDisabled: false,
      },
    };
  }
  if (jwksURI) {
    const regexp =
      defaultValues.test || featureFlags.isAllowPortForAppUrls
        ? /https:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))/
        : /^(?:https:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (!regexp.test(jwksURI)) {
      return {
        jwks_uri: {
          validateStatus: 'error',
          errorMsg: 'JWKS URI not valid',
          isDisabled: false,
        },
        jwks: {
          validateStatus: 'success',
          errorMsg: null,
          isDisabled: Boolean(jwksURI),
        },
      };
    }
  }
  if (jwks) {
    try {
      const obj = JSON.parse(jwks);
      prettierFields(obj, form);
    } catch {
      return {
        jwks_uri: {
          validateStatus: 'success',
          errorMsg: null,
          isDisabled: Boolean(jwks),
        },
        jwks: {
          validateStatus: 'error',
          errorMsg: 'Invalid JSON syntax',
          isDisabled: false,
        },
      };
    }
  }
  return {
    jwks_uri: {
      validateStatus: 'success',
      errorMsg: null,
      isDisabled: Boolean(jwks),
    },
    jwks: {
      validateStatus: 'success',
      errorMsg: null,
      isDisabled: Boolean(jwksURI),
    },
  };
};

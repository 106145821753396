import React, { FC } from 'react';
import * as El from './idpartner-alert.css';
import { IDPartnerAlertProps } from './types';

export const IDPartnerAlert: FC<IDPartnerAlertProps> = props => {
  const { type } = props;
  const getIconByType = (iconType: IDPartnerAlertProps['type']) => {
    switch (iconType) {
      case 'info':
        return <El.InfoIcon />;
      case 'warning':
        return <El.WarningIcon />;
      case 'error':
        return <El.ErrorIcon />;
      default:
        return <El.InfoIcon />;
    }
  };
  return <El.StyledAlert {...props} icon={getIconByType(type)} />;
};

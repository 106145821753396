import React, { FC, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Api } from '../../modules/utils/api';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import * as El from './add-payment-method-form.css';
import { AddPaymentMethodFormProps } from './types';
import { Spinner } from '../async-component/async-component.css';

export const AddPaymentMethodForm: FC<AddPaymentMethodFormProps> = ({ onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    const api = new Api();

    const { data } = await api.createSetupIntent({});

    stripe
      .confirmCardSetup(data.clientSecret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(res => {
        setIsLoading(false);
        if (res.error) {
          // handle error
          console.log(res);
          onError(res.error.message);
        }
        if (res.setupIntent) {
          onSuccess();
        }
      })
      .catch(err => {
        onError(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <El.AddPaymentMethodFormWrapper>
      {isLoading && <Spinner>Loading...</Spinner>}
      <El.FormRow>
        <CardElement />
      </El.FormRow>

      <IDPartnerButton type="primary" onClick={handleSubmit}>
        Add payment method
      </IDPartnerButton>
    </El.AddPaymentMethodFormWrapper>
  );
};

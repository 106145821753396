import styled from 'styled-components';
import { AutoComplete } from 'antd';
import { bodyLargeRegular } from '../../../common.css';

export const IdpartnerAutocompleteStyled = styled(AutoComplete)`
  & > .ant-select-selector {
    & .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 16px;
    right: 0;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: ${props => props.theme['blue-primary-400']};
      border-width: 2px;
      box-shadow: none;
    }
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
    .ant-select-selector {
      border-color: ${props => props.theme.error};
      border-width: 2px;
      box-shadow: none;
    }
  }

  //
  // & .ant-select-selection-search-input {
  //   height: 48px;
  //   padding: 12px 16px;
  //   border: 2px solid ${props => props.theme['border-200']};
  //   ${bodyLargeRegular};
  //   box-shadow: none;
  //   border-radius: 8px;
  // }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
    ${bodyLargeRegular};
    //padding: 12px 16px;
    box-shadow: none;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    ${bodyLargeRegular};
    padding: 0;
    border: 2px solid ${props => props.theme['border-200']};
    box-shadow: none;
    border-radius: 8px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    ${bodyLargeRegular};
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    ${bodyLargeRegular};
    padding: 12px 16px;
  }
`;

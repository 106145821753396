import React, { FC, useMemo, useState } from 'react';
import { Col, Form, Row, Radio, Divider } from 'antd';
import { noop } from 'lodash';
import c from 'classnames';
import { ApplicationFormStyled, Footer, PreviewWrapper, PreviewTitle, Steps, TooltipIcon, ButtonsWrapper, AlertWrapper } from './provider-form.css';
import { ProviderFormProps } from './types';
import { IDPartnerInput } from '../../../../components/ui/idpartner-input/idpartner-input';
import { StepsProgress } from '../../../../components/ui/steps-progress/steps-progress';
import { IDPartnerForm } from '../../../../components/ui/idpartner-form/idpartner-form';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { Provider } from '../../../../redux/providers/types';
import { IDPartnerColorpicker } from '../../../../components/ui/idpartner-colorpicker/idpartner-colorpicker';
import { IDPartnerVerifyButtonDemo } from '../../../../components/ui/idpartner-verify-button-demo/idpartner-button-demo';
import { IDPartnerMultiselect } from '../../../../components/ui/idpartner-multiselect/idpartner-multiselect';
import { IDPartnerSwitch } from '../../../../components/ui/idpartner-switch/idpartner-switch';
import { InputsMatrix } from '../../../../components/inputs-matrix/inputs-matrix';
import { MatrixValue } from '../../../../components/inputs-matrix/types';
import { IDPartnerRadio } from '../../../../components/ui/idpartner-radio/idpartner-radio';
import { IDPartnerAlert } from '../../../../components/ui/idpartner-alert/idpartner-alert';

const TRUST_PLATFORM_IDPARTNER_HOSTED = 'idpartner';
const TRUST_PLATFORM_SELF_HOSTED = 'self';

const AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER = 'oauth_adapter';
const AUTH_CONNECTOR_TYPE_BACKCHANNEL = 'backchannel';

const OAUTH_PROTOCOL_OIDC = 'oidc';
const OAUTH_PROTOCOL_OAUTH2 = 'oauth2';

export const getHostingOptionDescription = (hostingOption: string): string => {
  if (hostingOption === TRUST_PLATFORM_SELF_HOSTED) {
    return 'Self-hosted';
  } else {
    // IDPartner hosted by default
    return 'IDPartner-hosted';
  }
};

export const ProviderForm: FC<ProviderFormProps> = props => {
  const { provider, step, form, onValuesChange = noop, onCancel = noop, onSubmit = noop, onNextStep = noop, onPrevStep = noop, submitButtonTitle, formProps = {} } = props;

  const [isFirstStepValid, setIsFirstStepValid] = useState(true);
  const [isSecondStepValid, setIsSecondStepValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const [providerName, setProviderName] = useState(provider?.name || '');
  const [imageUrl, setImageUrl] = useState(provider?.image_url || '');
  const [featuredImageUrl, setFeaturedImageUrl] = useState(provider?.featured_image_url || '');
  const [textColor, setTextColor] = useState(provider?.branding_theme?.textColor || '');
  const [backgroundColor, setBackgroundColor] = useState(provider?.branding_theme?.backgroundColor || '');
  const [metadata, setMetadata] = useState(provider?.metadata || {});
  const [trustPlatformHostingPreference, setTrustPlatformHostingPreference] = useState(provider?.bank_services_config?.trust_platform_hosting_preference || TRUST_PLATFORM_SELF_HOSTED);
  const [trustPackageVerificationEnabled, setTrustPackageVerificationEnabled] = useState(provider?.bank_services_config?.trust_package_verification_enabled || false);
  const [authConnectorType, setAuthConnectorType] = useState(provider?.bank_services_config?.auth_connector_type || AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER);
  const [oauthProtocol, setOauthProtocol] = useState(provider?.bank_services_config?.oauth_protocol || OAUTH_PROTOCOL_OAUTH2);

  const isEditForm = !!provider;

  const defaultValues: Provider = {
    name: '',
    image_url: '',
    featured_image_url: null,
    url: '',
    issuer_url: '',
    country_codes: isEditForm ? provider.countries.map(country => country.country_code) : [],
    countries: [],
    enabled: false,
    consent_required: false,
    metadata,
    branding_theme: {
      textColor: 'white',
      backgroundColor: '#4A86E8',
    },
    bank_services_config: {
      // Common
      trust_platform_hosting_preference: trustPlatformHostingPreference,
      trust_package_verification_enabled: trustPackageVerificationEnabled,
      trust_package_verification_service_url: null,
      auth_connector_type: authConnectorType,

      // OAuth
      oauth_protocol: oauthProtocol,
      oauth_server_url: null,
      oauth_authorization_path: '/auth',
      oauth_token_path: '/token',
      oauth_userinfo_path: '/me',

      // Backchannel / Out of Band
      backchannel_access_token_verification_service_url: null,
      backchannel_users_service_url: null,
      backchannel_devices_service_url: null,
      backchannel_push_authentications_service_url: null,
    },
  };

  let initialValues = defaultValues;
  if (provider) {
    initialValues = { ...defaultValues, ...provider };
  }

  if (provider && JSON.stringify(provider.bank_services_config) === '{}') {
    initialValues = { ...initialValues, bank_services_config: defaultValues.bank_services_config };
  }

  const validateForm = async (): Promise<[boolean, boolean, boolean]> => {
    if (!form) {
      return [false, false, false];
    }

    // eslint-disable-next-line no-underscore-dangle
    let _isFirstStepValid = true;
    // eslint-disable-next-line no-underscore-dangle
    let _isSecondStepValid = true;
    // eslint-disable-next-line no-underscore-dangle
    let _isFormValid = true;

    try {
      if (step === 1) {
        const firstValidationResult = await form.validateFields();
        _isFirstStepValid = !firstValidationResult.errorFields || !firstValidationResult.errorFields.length;
        setIsFirstStepValid(_isFirstStepValid);
      }

      if (step === 2) {
        const secondValidationResult = await form.validateFields();
        _isSecondStepValid = !secondValidationResult.errorFields || !secondValidationResult.errorFields.length;
        setIsSecondStepValid(_isSecondStepValid);
      }

      if (step === 3) {
        const thirdValidationResult = await form.validateFields();
        _isFormValid = !thirdValidationResult.errorFields || !thirdValidationResult.errorFields.length;
        setIsFormValid(_isFormValid);
      }

      return [_isFirstStepValid, _isSecondStepValid, _isFormValid];
    } catch (e) {
      if (step === 1) {
        _isFirstStepValid = !e.errorFields.length;
        setIsFirstStepValid(_isFirstStepValid);
      }

      if (step === 2) {
        _isSecondStepValid = !e.errorFields.length;
        setIsSecondStepValid(_isSecondStepValid);
      }

      if (step === 3) {
        _isFormValid = !e.errorFields.length;
        setIsFormValid(_isFormValid);
      }
    }

    return [_isFirstStepValid, _isSecondStepValid, _isFormValid];
  };

  const handleValuesChange = async () => {
    setProviderName(form.getFieldValue('name'));

    const newLogoUrl = form.getFieldValue('image_url');
    if (newLogoUrl !== imageUrl) {
      setImageUrl(newLogoUrl);
    }

    const newFeaturedImageUrl = form.getFieldValue('featured_image_url');
    if (newFeaturedImageUrl !== featuredImageUrl) {
      setFeaturedImageUrl(newFeaturedImageUrl);
    }

    const currentTheme = form.getFieldValue('branding_theme');
    setTextColor(currentTheme.textColor);
    setBackgroundColor(currentTheme.backgroundColor);

    const newTrustPlatformHostingPreference = form.getFieldValue('bank_services_config')?.trust_platform_hosting_preference;
    if (trustPlatformHostingPreference !== newTrustPlatformHostingPreference) {
      setTrustPlatformHostingPreference(newTrustPlatformHostingPreference);
    }

    const newTrustPackageVerificationEnabled = form.getFieldValue('bank_services_config')?.trust_package_verification_enabled;
    if (trustPackageVerificationEnabled !== newTrustPackageVerificationEnabled) {
      setTrustPackageVerificationEnabled(newTrustPackageVerificationEnabled);
    }

    const newAuthConnectorType = form.getFieldValue('bank_services_config')?.auth_connector_type;
    if (authConnectorType !== newAuthConnectorType) {
      setAuthConnectorType(newAuthConnectorType);
    }

    const newOauthProtocol = form.getFieldValue('bank_services_config')?.oauth_protocol;
    if (oauthProtocol !== newOauthProtocol) {
      setOauthProtocol(newOauthProtocol);
    }

    validateForm();
    onValuesChange();
  };

  const _onNextStep = async () => {
    const [_isFirstStepValid, _isSecondStepValid, _isFormValid] = await validateForm();

    if (step === 1 && _isFirstStepValid) {
      onNextStep();
    }

    if (step === 2 && _isSecondStepValid) {
      onNextStep();
    }

    if (step === 3 && _isFormValid) {
      onNextStep();
    }
  };

  const _onSubmit = async () => {
    const [, , _isFormValid] = await validateForm();

    if (_isFormValid) {
      onSubmit();
    }
  };

  const footer = useMemo(() => {
    if (step === 1) {
      return (
        <ButtonsWrapper>
          <IDPartnerButton size="large" onClick={onCancel} block>
            Cancel
          </IDPartnerButton>

          <IDPartnerButton size="large" type="primary" onClick={_onNextStep} disabled={!isFirstStepValid} block>
            Next
          </IDPartnerButton>
        </ButtonsWrapper>
      );
    }
    if (step === 2) {
      return (
        <ButtonsWrapper>
          <IDPartnerButton size="large" type="default" onClick={onPrevStep} block>
            Previous
          </IDPartnerButton>
          <IDPartnerButton size="large" type="primary" onClick={_onNextStep} disabled={!isSecondStepValid} block>
            Next
          </IDPartnerButton>
        </ButtonsWrapper>
      );
    }
    if (step === 3) {
      return (
        <ButtonsWrapper>
          <IDPartnerButton size="large" type="default" onClick={onPrevStep} block>
            Previous
          </IDPartnerButton>
          <IDPartnerButton size="large" type="primary" onClick={_onNextStep} disabled={!isFirstStepValid} block>
            Next
          </IDPartnerButton>
        </ButtonsWrapper>
      );
    }
    if (step === 4) {
      return (
        <Row justify="space-between">
          <Col>
            <IDPartnerButton size="large" danger onClick={onCancel}>
              Cancel
            </IDPartnerButton>
          </Col>

          <Row justify="space-between">
            <IDPartnerButton size="large" type="primary" onClick={onPrevStep} style={{ marginRight: 10 }}>
              Previous
            </IDPartnerButton>

            <IDPartnerButton size="large" type="primary" onClick={_onSubmit} disabled={!isFormValid}>
              {submitButtonTitle}
            </IDPartnerButton>
          </Row>
        </Row>
      );
    }
    return null;
  }, [props, step, isFirstStepValid, isSecondStepValid, isFormValid]);

  const countriesOptions = [
    { value: 'US', label: 'United States' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'AU', label: 'Australia' },
    { value: 'NO', label: 'Norway' },
  ];

  const handleMetadataChange = (val: MatrixValue) => {
    setMetadata(val);
    form.setFieldValue('metadata', val);
  };

  return (
    <ApplicationFormStyled>
      <Steps>
        <StepsProgress steps={['Basic Info', 'Service Config', 'Brand Colors', 'Metadata']} currentStep={step} />
      </Steps>

      <IDPartnerForm>
        <Form layout="vertical" initialValues={initialValues} form={form} onValuesChange={handleValuesChange} requiredMark="optional" {...formProps} preserve={false}>
          <div className={c({ hide: step !== 1 })}>
            <Row gutter={10}>
              <Col span="24">
                <Form.Item
                  label="Identity Provider name"
                  name="name"
                  tooltip={{
                    title: 'This is the IDP name. This value will be displayed in the account selector page where end users select their preferred IDP to verify their identity.',
                    icon: <TooltipIcon />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a name of your provider',
                    },
                  ]}
                >
                  <IDPartnerInput allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  label="Countries"
                  name="country_codes"
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one country',
                    },
                  ]}
                >
                  <IDPartnerMultiselect defaultValue={initialValues.country_codes} mode="multiple" options={countriesOptions} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span="24">
                <Form.Item
                  label="Button Image URL"
                  tooltip={{
                    title: 'The image icon to brand the IDPartner Button. Recommended aspect ratio is 1:1.',
                    icon: <TooltipIcon />,
                  }}
                  name="image_url"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a button image url',
                    },
                  ]}
                >
                  <IDPartnerInput allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span="24">
                <Form.Item
                  label="Featured Image URL"
                  tooltip={{
                    title: 'The image to showcase on the main selection page. Recommended aspect ratio is 4:1.',
                    icon: <TooltipIcon />,
                  }}
                  name="featured_image_url"
                >
                  <IDPartnerInput allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  label="Site"
                  tooltip={{ title: 'This is the Identity Provider website', icon: <TooltipIcon /> }}
                  name="url"
                  rules={[
                    {
                      required: true,
                      type: 'url',
                      message: 'Please enter an site URL',
                    },
                  ]}
                >
                  <IDPartnerInput allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item name="enabled" valuePropName="checked" label="Visibility">
                  <IDPartnerSwitch />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  name="consent_required"
                  valuePropName="checked"
                  label="Consent required"
                  tooltip={{
                    title: 'Display a consent screen to the user after selecting the Identity Provider ',
                    icon: <TooltipIcon />,
                  }}
                >
                  <IDPartnerSwitch />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className={c({ hide: step !== 2 })}>
            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  name={['bank_services_config', 'trust_platform_hosting_preference']}
                  label="Trust Platform Hosting Preference"
                  tooltip={{
                    title: 'To host the trust-platform yourself please choose "Self-hosted" otherwise select "IDPartner-hosted" to have IDPartner to host it for you',
                    icon: <TooltipIcon />,
                  }}
                  rules={[{ required: step === 2 }]}
                >
                  <Radio.Group>
                    <IDPartnerRadio value={TRUST_PLATFORM_SELF_HOSTED}>{getHostingOptionDescription(TRUST_PLATFORM_SELF_HOSTED)}</IDPartnerRadio>
                    <IDPartnerRadio value={TRUST_PLATFORM_IDPARTNER_HOSTED}>{getHostingOptionDescription(TRUST_PLATFORM_IDPARTNER_HOSTED)}</IDPartnerRadio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <AlertWrapper>
              <IDPartnerAlert
                type="info"
                description={
                  <span>
                    Please contact us at <a href="mailto:support@idpartner.com">support@idpartner.com</a> if you need to host the Trust Platform yourself or if you need to know more about the
                    IDPartner-hosted option
                  </span>
                }
              />
            </AlertWrapper>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  label="OIDC Provider URI"
                  name="issuer_url"
                  // tooltip={{
                  //   title: 'This is the endpoint where the Trust Platform (oidc-provider-service) will be exposed.',
                  //   icon: <TooltipIcon />,
                  // }}
                  rules={[
                    {
                      required: step === 2,
                      type: 'url',
                      message: 'Please enter the OIDC URI',
                    },
                  ]}
                >
                  <IDPartnerInput allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  label="Auth Connector URI"
                  name="auth_url"
                  tooltip={{
                    title: 'This endpoint will be one of the authentication connector services that the Trust Platform provides; This will either be the Out of Band or the OAuth Adapter Service',
                    icon: <TooltipIcon />,
                  }}
                  rules={[
                    {
                      required: step === 2,
                      type: 'url',
                      message: 'Please enter a Auth Connector URI',
                    },
                  ]}
                >
                  <IDPartnerInput allowClear />
                </Form.Item>
              </Col>
            </Row>

            <div className={c({ hide: trustPlatformHostingPreference !== TRUST_PLATFORM_IDPARTNER_HOSTED })}>
              <Divider orientation="left" orientationMargin="0">
                Bank Services
              </Divider>

              <Row gutter={20}>
                <Col span="24">
                  <Form.Item
                    name={['bank_services_config', 'trust_package_verification_enabled']}
                    valuePropName="checked"
                    label="Trust Package Verification Enabled"
                    tooltip={{
                      title: 'Enable the Trust Platform to perform trust package verification using the endpoint configured in "Trust Package Verification URI"',
                      icon: <TooltipIcon />,
                    }}
                  >
                    <IDPartnerSwitch />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span="24">
                  <Form.Item
                    label="Trust Package Verification URI"
                    name={['bank_services_config', 'trust_package_verification_service_url']}
                    tooltip={{
                      title:
                        'This endpoint will be hit by the Trust Platform at the beginning of the verification flow to verify whether the bank wants to work with the Relying Party that initiated the verification flow',
                      icon: <TooltipIcon />,
                    }}
                    rules={[
                      {
                        required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && trustPackageVerificationEnabled,
                        type: 'url',
                        message: 'Please enter a valid Trust Package Verification URI',
                      },
                    ]}
                  >
                    <IDPartnerInput allowClear disabled={!trustPackageVerificationEnabled} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span="24">
                  <Form.Item name={['bank_services_config', 'auth_connector_type']} label="Auth Connector Type" rules={[{ required: step === 2 }]}>
                    <Radio.Group>
                      <IDPartnerRadio value={AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER}>OAuth</IDPartnerRadio>
                      <IDPartnerRadio value={AUTH_CONNECTOR_TYPE_BACKCHANNEL}>Out of Band</IDPartnerRadio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <div className={c({ hide: authConnectorType !== AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER })}>
                <Row gutter={20}>
                  <Col span="24">
                    <Form.Item
                      name={['bank_services_config', 'oauth_protocol']}
                      label="Auth Protocol"
                      rules={[{ required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && authConnectorType === AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER }]}
                    >
                      <Radio.Group>
                        <IDPartnerRadio value={OAUTH_PROTOCOL_OAUTH2}>OAuth2</IDPartnerRadio>
                        <IDPartnerRadio value={OAUTH_PROTOCOL_OIDC}>OIDC</IDPartnerRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span="24">
                    <Form.Item
                      label="Auth Server URI"
                      name={['bank_services_config', 'oauth_server_url']}
                      rules={[
                        {
                          required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && authConnectorType === AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER,
                          type: 'url',
                          message: 'Please enter a valid Auth Server URI',
                        },
                      ]}
                    >
                      <IDPartnerInput allowClear />
                    </Form.Item>
                  </Col>
                </Row>

                <div className={c({ hide: oauthProtocol !== OAUTH_PROTOCOL_OAUTH2 })}>
                  <Row gutter={20}>
                    <Col span="24">
                      <Form.Item
                        label="OAuth Token Path"
                        name={['bank_services_config', 'oauth_token_path']}
                        rules={[
                          {
                            required:
                              step === 2 &&
                              trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED &&
                              authConnectorType === AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER &&
                              oauthProtocol === OAUTH_PROTOCOL_OAUTH2,
                            message: 'Please enter a Token Path',
                          },
                        ]}
                      >
                        <IDPartnerInput allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span="24">
                      <Form.Item
                        label="OAuth Autorization Path"
                        name={['bank_services_config', 'oauth_authorization_path']}
                        rules={[
                          {
                            required:
                              step === 2 &&
                              trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED &&
                              authConnectorType === AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER &&
                              oauthProtocol === OAUTH_PROTOCOL_OAUTH2,
                            message: 'Please enter an Authorization Path',
                          },
                        ]}
                      >
                        <IDPartnerInput allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span="24">
                      <Form.Item
                        label="OAuth User Info Path"
                        name={['bank_services_config', 'oauth_userinfo_path']}
                        rules={[
                          {
                            required:
                              step === 2 &&
                              trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED &&
                              authConnectorType === AUTH_CONNECTOR_TYPE_OAUTH_ADAPTER &&
                              oauthProtocol === OAUTH_PROTOCOL_OAUTH2,
                            message: 'Please enter an User Info Path',
                          },
                        ]}
                      >
                        <IDPartnerInput allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className={c({ hide: authConnectorType !== AUTH_CONNECTOR_TYPE_BACKCHANNEL })}>
                <Row gutter={20}>
                  <Col span="24">
                    <Form.Item
                      label="Access Token Verification URI"
                      name={['bank_services_config', 'backchannel_access_token_verification_service_url']}
                      tooltip={{
                        title: 'This endpoint will be hit by the Trust Platform to verify whether the access token sent by the mobile device during the Out of Band flow is valid',
                        icon: <TooltipIcon />,
                      }}
                      rules={[
                        {
                          required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && authConnectorType === AUTH_CONNECTOR_TYPE_BACKCHANNEL,
                          type: 'url',
                          message: 'Please enter a valid Access Token Verification URI',
                        },
                      ]}
                    >
                      <IDPartnerInput allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span="24">
                    <Form.Item
                      label="Users Service URI"
                      name={['bank_services_config', 'backchannel_users_service_url']}
                      tooltip={{
                        title: 'This endpoint will be used by the Trust Platform to fetch users during the Out of Band flow',
                        icon: <TooltipIcon />,
                      }}
                      rules={[
                        {
                          required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && authConnectorType === AUTH_CONNECTOR_TYPE_BACKCHANNEL,
                          type: 'url',
                          message: 'Please enter a valid Users Service URI',
                        },
                      ]}
                    >
                      <IDPartnerInput allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span="24">
                    <Form.Item
                      label="Devices Service URI"
                      name={['bank_services_config', 'backchannel_devices_service_url']}
                      tooltip={{
                        title: 'This endpoint will be used by the Trust Platform to create and fetch devices during the Out of Band flow',
                        icon: <TooltipIcon />,
                      }}
                      rules={[
                        {
                          required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && authConnectorType === AUTH_CONNECTOR_TYPE_BACKCHANNEL,
                          type: 'url',
                          message: 'Please enter a valid Devices Service URI',
                        },
                      ]}
                    >
                      <IDPartnerInput allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span="24">
                    <Form.Item
                      label="Push Authentications Service URI"
                      name={['bank_services_config', 'backchannel_push_authentications_service_url']}
                      tooltip={{
                        title: 'This endpoint will be used by the Trust Platform to send push authentications during the Out of Band flow',
                        icon: <TooltipIcon />,
                      }}
                      rules={[
                        {
                          required: step === 2 && trustPlatformHostingPreference === TRUST_PLATFORM_IDPARTNER_HOSTED && authConnectorType === AUTH_CONNECTOR_TYPE_BACKCHANNEL,
                          type: 'url',
                          message: 'Please enter a valid Push Authentications Service URI',
                        },
                      ]}
                    >
                      <IDPartnerInput allowClear />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className={c({ hide: step !== 3 })}>
            <Row gutter={20}>
              <Col span="24">
                <Form.Item requiredMark={false} rules={[{ required: true }]} label="Branding Text Color" name={['branding_theme', 'textColor']}>
                  <IDPartnerColorpicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="24">
                <Form.Item requiredMark={false} rules={[{ required: true }]} label="Branding Background Color" name={['branding_theme', 'backgroundColor']}>
                  <IDPartnerColorpicker />
                </Form.Item>
              </Col>
            </Row>
            <PreviewWrapper>
              <PreviewTitle>Preview:</PreviewTitle>
              <IDPartnerVerifyButtonDemo providerName={providerName} imageUrl={imageUrl} backgroundColor={backgroundColor} textColor={textColor} />
            </PreviewWrapper>
          </div>

          <div className={c({ hide: step !== 4 })}>
            <Form.Item label="Metadata" name="metadata">
              <InputsMatrix onChange={handleMetadataChange} />
            </Form.Item>
          </div>
        </Form>
      </IDPartnerForm>
      <Footer>{footer}</Footer>
    </ApplicationFormStyled>
  );
};

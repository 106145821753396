import { AuthProvider } from '@descope/react-sdk';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import configureStore from './redux/configureStore';
import { Routes } from './routes/routes';
// convert .less variables to js format so we can use it as styled components theme
// https://stackoverflow.com/questions/44063762/how-can-i-read-less-variables-in-javascript
// @ts-ignore
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import * as lessVariables from '!!./utils/less-var-loader!./variables.less';
import './theme.less';

const { store } = configureStore();

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
});

mixpanel.init(process.env.MIXPANEL_TOKEN, { ip: false });

const App = () => (
  <AuthProvider projectId={process.env.DESCOPE_PROJECT_ID}>
    <Provider store={store}>
      <ThemeProvider theme={lessVariables}>
        <Routes />
      </ThemeProvider>
    </Provider>
  </AuthProvider>
);

const RootComponent = (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <App />
  </Sentry.ErrorBoundary>
);

ReactDOM.render(RootComponent, document.getElementById('root'));

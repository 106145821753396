import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import * as El from './copy.css';

export type CopyProps = {
  hint: string;
  children: string;
  placeholder?: string;
  hidden?: boolean;
};

export class Copy extends Component<CopyProps> {
  state = {
    copied: false,
    isShow: false,
  };

  handleCopy = (e: any) => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 2000);
    });
  };

  handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleShowClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isShow: true });
  };

  render() {
    if (this.props.hidden && !this.state.isShow) {
      return (
        <El.CopyRow onClick={this.handleShowClick}>
          <El.StyledChildren>••••••••••••••••••••••</El.StyledChildren>
          <EyeOutlined />
        </El.CopyRow>
      );
    }
    return (
      <CopyToClipboard text={this.props.children} onCopy={this.handleCopy}>
        <Tooltip title={!this.state.copied ? this.props.hint : 'Copied!'}>
          <El.CopyRow onClick={this.handleClick}>
            <El.StyledChildren>{this.props.placeholder || this.props.children}</El.StyledChildren>
            {!this.state.copied ? <El.StyledIcon /> : <El.CheckOutlinedStyledIcon />}
          </El.CopyRow>
        </Tooltip>
      </CopyToClipboard>
    );
  }
}

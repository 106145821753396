import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { GroupState } from './types';

export type ActionsType = ActionType<typeof actions>;

const initialState: GroupState = {
  members: [],
  hasMore: true,
  nextCursor: null,
};

const reducer: Reducer<GroupState> = (state = initialState, action: ActionsType): GroupState => {
  switch (action.type) {
    case getType(actions.getMembersAction):
      return { ...state, members: action.payload.users, ...action.payload.paging };
    case getType(actions.getMoreMembersAction):
      return { ...state, members: [...state.members, ...action.payload.users], ...action.payload.paging };
    default: {
      return state;
    }
  }
};

export { reducer as groupReducer };

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-feature-settings: normal!important;
    font-variant: none!important;
    font-feature-settings: normal!important;
}
.hide {
    display: none;
}

a {
    color: #2C5AEC;
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,4BAA4B;IAC5B,uCAAuC;AAC3C;AACA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["* {\n    font-feature-settings: normal!important;\n    font-variant: none!important;\n    font-feature-settings: normal!important;\n}\n.hide {\n    display: none;\n}\n\na {\n    color: #2C5AEC;\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

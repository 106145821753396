import { FormProps } from 'antd';

export enum SIGNUP_STEPS {
  SECURITY = 'security',
  INFORMATION = 'information',
  SUCCESS = 'success',
}

export type StepFormProps = FormProps & {
  onNextStep: () => void;
  onPreviousStep: () => void;
  isShowOrgFields?: boolean;
  disableName?: boolean;
  isEmailPopulated?: boolean;
};

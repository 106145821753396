import styled from 'styled-components';
import backgroundImage from '../../../assets/images/auth_layout_2x.png';
import { bodyLargeRegular } from '../../../common.css';
import logoIcon from '../../../assets/logo_darkblue.svg';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
`;

export const FormWrapper = styled.div`
  width: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0 20px;
    width: 100%;
  }
`;

export const TopSpace = styled.div`
  flex-shrink: 1;
  flex-basis: 115px;
`;

export const Logo = styled.div`
  background: url('${logoIcon}') center no-repeat;
  width: 216px;
  height: 62px;
  margin-bottom: 115px;
  flex-shrink: 0;
`;

export const ImageContainer = styled.div`
  height: 100vh;
  background-image: url('${backgroundImage}');
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ImageFooter = styled.div`
  position: absolute;
  bottom: 22px;
  left: 0;
  right: 0;
  width: 100%;
  height: 24px;

  display: flex;
  justify-content: center;
  padding: 0 32px;
  z-index: 3;
  color: #f0f2f5;
  align-items: center;

  font-family: Public Sans, sans-serif;
  ${bodyLargeRegular};
`;

export const Divider = styled.div`
  height: 24px;
  width: 1px;
  background: #f0f2f5;
  margin: 0 28px;
`;

export const Link = styled.a`
  color: #f0f2f5;
  text-decoration: none;
  cursor: pointer;
  ${bodyLargeRegular};
  margin-right: 12px;

  &:first-child {
    margin-right: 4px;
  }

  &:last-child {
    margin-left: 4px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

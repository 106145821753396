import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { debounce } from 'lodash';
import { ApplicationState } from '../../../redux/store';
import { AsyncComponent } from '../../../components/async-component/async-component';

import { fetchApplications as fetchApplicationsRedux, deleteApplication as deleteApplicationRedux } from '../../../redux/applications/async';
import { PageHeader } from '../../../components/page-header/page-header';
import { TableHeading } from '../components/table-heading/table-heading';
import { ApplicationsPage } from './applications.container.css';
import { ApplicationWizard } from '../../application-wizard/application-wizard';
import { Application } from '../../../redux/applications/types';
import { ApplicationsGrid } from '../components/applications-grid/applications-grid';
import { PageWrapper } from '../../../components/layouts/main-layout/main-layout/main-layout.css';
import { AppType } from '../components/table-heading/types';
import { VerificationsList } from '../../../components/verifications-list/verifications-list';
import { DeleteAppModal } from '../components/delete-app-modal/delete-app-modal';

const mapStateToProps = ({ applications, auth }: ApplicationState) => ({
  applications: applications.data,
  account: auth.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchApplications: bindActionCreators(fetchApplicationsRedux, dispatch),
  deleteApplicaion: bindActionCreators(deleteApplicationRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export type AppsContainerState = 'grid' | 'settings';

const Component: FC<AllProps> = props => {
  const { fetchApplications, applications, account, deleteApplicaion } = props;
  const [isShowAppWizard, setIsShowAppWizard] = useState(false);
  const [isShowDeleteAppModal, setIsShowDeleteAppModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<Application | undefined>();
  const [filteredApplications, setFilteredApplications] = useState(applications);
  const [searchQuery, setSearchQuery] = useState('');
  const [appType, setAppType] = useState<AppType>('sandbox');
  const [stage, setStage] = useState<AppsContainerState>('grid');
  const [wizardAppType, setWizardAppType] = useState<AppType>('sandbox'); // New state for wizard app type

  useEffect(() => {
    setFilteredApplications(getFilteredApps(searchQuery, appType));
  }, [applications]);

  const getFilteredApps = (query: string, newAppType: AppType): any[] => {
    let filteredApps = applications;
    if (newAppType === 'production') {
      filteredApps = filteredApps.filter(app => !app.test);
    } else {
      filteredApps = filteredApps.filter(app => app.test);
    }

    if (query) {
      const r = new RegExp(query, 'gmi');
      filteredApps = filteredApps.filter(application => application.name.search(r) !== -1);
    }

    return filteredApps;
  };

  const debouncedSearch = useCallback(
    debounce(query => {
      setFilteredApplications(getFilteredApps(query, appType));
    }, 500),
    [applications, appType],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchQuery(value);
      debouncedSearch(value);
    },
    [applications, appType],
  );

  const handleAppTypeChange = (newAppType: AppType) => {
    setAppType(newAppType);
    setFilteredApplications(getFilteredApps(searchQuery, newAppType));
  };

  const handleCreateApp = (newAppType: AppType) => {
    setWizardAppType(newAppType);
    setIsShowAppWizard(true);
  };

  const handleCloseWizard = () => {
    setSelectedApplication(undefined);
    setIsShowAppWizard(false);
  };

  const handleEditButtonClick = (id: number) => {
    const selectedApp = applications.find(a => a.id === id);
    setSelectedApplication(selectedApp || null);

    if (selectedApp) {
      setIsShowAppWizard(true);
    }
  };

  const handleDeleteAppClick = (id: Application['id']) => {
    const foundApp = applications.find(app => app.id === id);
    if (foundApp) {
      setSelectedApplication(foundApp);
      setIsShowDeleteAppModal(true);
    }
  };

  const handleDeleteAppSubmit = async () => {
    await deleteApplicaion(selectedApplication.id);
    setSelectedApplication(null);
    setIsShowDeleteAppModal(false);
  };

  const handleCloseDeleteAppModal = () => {
    setSelectedApplication(null);
    setIsShowDeleteAppModal(false);
  };

  const handleSettingsClick = () => {
    setStage('settings');
  };

  if (isShowAppWizard) {
    return <ApplicationWizard wizardAppType={wizardAppType} application={selectedApplication} onClose={handleCloseWizard} />;
  }

  return (
    <AsyncComponent message="Loading Applications" getAsyncActions={() => [fetchApplications()]}>
      <PageWrapper>
        <ApplicationsPage>
          {stage === 'grid' ? (
            <>
              <PageHeader title="Applications" description={<>Integrate Bank Authentication into Your App by Creating an Application. Explore Our Documentation for More Details.</>} />
              <ApplicationsPage>
                <TableHeading
                  applications={filteredApplications}
                  account={account}
                  currentAppType={appType}
                  onChangeAppType={handleAppTypeChange}
                  onSettingsClick={handleSettingsClick}
                  handleSearch={handleSearch}
                  onNewAppButtonClick={() => handleCreateApp(appType)}
                />
                <ApplicationsGrid
                  applications={filteredApplications}
                  onSelectApp={handleEditButtonClick}
                  onDeleteApp={handleDeleteAppClick}
                  currentAppType={appType}
                  onNewAppButtonClick={() => handleCreateApp(appType)}
                  account={account}
                />
                {isShowDeleteAppModal && <DeleteAppModal open onSubmit={handleDeleteAppSubmit} onCancel={handleCloseDeleteAppModal} />}
              </ApplicationsPage>
            </>
          ) : (
            <>
              <PageHeader title="Settings" description={<>Some text for settings momo mo momo moo mom mo moom mo momo om mo momo momo mo</>} />
              <ApplicationsPage>
                <VerificationsList />
              </ApplicationsPage>
            </>
          )}
        </ApplicationsPage>
      </PageWrapper>
    </AsyncComponent>
  );
};

export const ApplicationsContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

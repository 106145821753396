import styled from 'styled-components';
import { bodyLargeRegular } from '../../common.css';

export const ModalItem = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ErrorText = styled.div`
  color: red;
  margin-top: 4px;
`;

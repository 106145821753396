import React, { FC, useEffect, useMemo, useState } from 'react';
import { IDPartnerModal } from '../../../../components/ui/idpartner-modal/idpartner-modal';
import { ButtonsWrapper, CodeCopyWrapper, ModalText, Steps } from './download-provider-trust-platform-modal.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { DownloadProviderTrustPlatformModalProps } from './types';
import { CodeCopy } from '../../../../components/code-copy/code-copy';
import DownloadTemplateIcon from '../../../../assets/icons/download-template.svg';
import { getHostingOptionDescription } from '../provider-form/provider-form';
import { StepsProgress } from '../../../../components/ui/steps-progress/steps-progress';

export const DownloadProviderTrustPlatformModal: FC<DownloadProviderTrustPlatformModalProps> = props => {
  const { onCancel, provider } = props;
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setCurrentStep(1);
  }, [open]);

  useEffect(() => {}, []);

  const goToTheNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToThePrevStep = () => {
    if (currentStep <= 1) {
      return setCurrentStep(1);
    }
    setCurrentStep(currentStep - 1);
  };

  const footer = useMemo(() => {
    if (currentStep === 1) {
      return (
        <ButtonsWrapper>
          <IDPartnerButton size="large" onClick={onCancel} block>
            Cancel
          </IDPartnerButton>

          <IDPartnerButton size="large" type="primary" onClick={goToTheNextStep} block>
            Next
          </IDPartnerButton>
        </ButtonsWrapper>
      );
    }
    if (currentStep === 2) {
      return (
        <ButtonsWrapper>
          <IDPartnerButton size="large" type="default" onClick={goToThePrevStep} block>
            Previous
          </IDPartnerButton>
          <IDPartnerButton size="large" type="primary" onClick={goToTheNextStep} block>
            Next
          </IDPartnerButton>
        </ButtonsWrapper>
      );
    }
    if (currentStep === 3) {
      return (
        <ButtonsWrapper>
          <IDPartnerButton size="large" type="default" onClick={goToThePrevStep} block>
            Previous
          </IDPartnerButton>
          <IDPartnerButton size="large" type="primary" onClick={onCancel} block>
            Accept
          </IDPartnerButton>
        </ButtonsWrapper>
      );
    }
    return null;
  }, [props, currentStep]);

  return (
    <IDPartnerModal
      {...props}
      key="download-provider-trust-platform"
      title={
        <div>
          <img src={DownloadTemplateIcon} width={30} alt="Download Icon" /> <span>Download Trust Platform</span>
        </div>
      }
      footer={null}
      destroyOnClose
    >
      <Steps>
        <StepsProgress steps={['Trust Platform Config', 'Mobile App Config', 'Next Steps']} currentStep={currentStep} />
      </Steps>

      {currentStep === 1 && (
        <ModalText>
          {`Steps to get the ${getHostingOptionDescription(provider?.bank_services_config?.trust_platform_hosting_preference)} Trust Platform example running locally:`}
          <br />
          <br />
          <ol>
            <li>
              <a href={`${process.env.DASHBOARD_API_SERVICE_URL}/trust_platform_examples/download/${provider.id}`} target="_blank" rel="noreferrer">
                Download
              </a>{' '}
              and unzip the Trust Platform
            </li>
            <li>
              Go to the unzipped Trust Platform folder in your console:
              <CodeCopyWrapper>
                <CodeCopy>cd /path/to/unzipped/trust-platform</CodeCopy>
              </CodeCopyWrapper>
            </li>
            <li>
              Setup the Trust Platform. Run the following in your console:
              <CodeCopyWrapper>
                <CodeCopy>./scripts/setup_local.sh</CodeCopy>
              </CodeCopyWrapper>
            </li>
            <li>
              Start the Trust Plaform. Run the following in your console:
              <CodeCopyWrapper>
                <CodeCopy>docker compose up</CodeCopy>
              </CodeCopyWrapper>
            </li>
          </ol>
        </ModalText>
      )}

      {currentStep === 2 && (
        <ModalText>
          Steps to configure IDPatner's sample mobile app. Skip this section if you did not choose the out of band integration.
          <br />
          <br />
          <ol>
            <li>
              Download IDPartner's{' '}
              <a href="https://testflight.apple.com/join/6AABDAVh" target="_blank" rel="noreferrer">
                iOS
              </a>{' '}
              sample mobile app.
            </li>
            <li>
              Configure the mobile app's User Service URI with the value:
              <CodeCopyWrapper>
                <CodeCopy>{provider?.bank_services_config?.backchannel_users_service_url || process.env.TRUST_PLATFORM_MOCK_BANK_USERS_SERVICE_URL}</CodeCopy>
              </CodeCopyWrapper>
            </li>
          </ol>
        </ModalText>
      )}

      {currentStep === 3 && (
        <ModalText>
          Congratulations on getting the Trust Platform running!
          <br />
          <br />
          Please continue to <a href="https://docs.idpartner.com/documentation/relying-party-user-guide">register a Relying Party</a> and test the integration.
          <br />
          <br />
          If you encounter any issue or if you need help please contact us at <a href="mailto:support@idpanrtner.com">support@idpartner.com</a>.
          <br />
          <br />
        </ModalText>
      )}

      {footer}
    </IDPartnerModal>
  );
};

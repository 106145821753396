import styled from 'styled-components';
import { Alert } from 'antd';
import infoSvg from '../../../assets/icons/info-icon.svg';
import warningSvg from '../../../assets/icons/warning-icon.svg';
import errorSvg from '../../../assets/icons/error-icon.svg';

('');

export const StyledAlert = styled(Alert)`
  &.ant-alert {
    border-radius: 4px;
    padding: 16px;
    border: none;
    width: 100%;

    & .ant-alert-content {
      & .ant-alert-description {
        font-family: 'Public Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: ${props => props.theme['gray-900']};
      }
    }
  }
  &.ant-alert-info {
    background: ${props => props.theme['alert-info']};
  }
  &.ant-alert-warning {
    background: ${props => props.theme['alert-warning']};
    border: 1px solid #c7d9a3;
    color: #836820;
    font-family: Public Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    & .ant-alert-content {
      & .ant-alert-description {
        color: rgb(131, 104, 32);
      }
    }
  }
  &.ant-alert-error {
    background: ${props => props.theme['alert-error']};
  }
`;

const AlertIcon = styled.div`
  width: 20px;
  height: 20px;
`;
export const InfoIcon = styled(AlertIcon)`
  background: url('${infoSvg}') center no-repeat;
`;
export const WarningIcon = styled(AlertIcon)`
  background: url('${warningSvg}') center no-repeat;
`;
export const ErrorIcon = styled(AlertIcon)`
  background: url('${errorSvg}') center no-repeat;
`;

import React, { FC, useState } from 'react';
import { Form, Tooltip } from 'antd';
import { noop } from 'lodash';
import { AddCertificateFormStyled, Footer, Label, LabelNumber, TerminalWrapper, TooltipIcon } from './add-certificate-form.css';
import { AddCertificateFormProps } from './types';
import { IDPartnerForm } from '../../../../../components/ui/idpartner-form/idpartner-form';
import { IDPartnerButton } from '../../../../../components/ui/idpartner-button/idpartner-button';
import { IDPartnerUploader } from '../../../../../components/ui/idpartner-uploader/idpartner-uploader';
import { Application } from '../../../../../redux/applications/types';
import { Api } from '../../../../utils/api';
import { IDPartnerInput } from '../../../../../components/ui/idpartner-input/idpartner-input';
import { CodeCopy } from '../../../../../components/code-copy/code-copy';

export const AddCertificateForm: FC<AddCertificateFormProps> = props => {
  const { application, onValuesChange = noop, onCancel = noop, onSubmit = noop, formProps = {} } = props;
  const [isUploadInProgress, setIsUploadInProgress] = useState(true);
  const [isValidForm, setIsValidForm] = useState(false);
  const [form] = Form.useForm();

  const defaultValues: any = {
    file_name: undefined,
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    const formData = form.getFieldsValue();
    onSubmit(formData);
  };

  const getPresignedPostData = (application: Application) => {
    const api = new Api();
    return api.getCertificateUploadURL(application.id);
  };

  const uploadFileToS3 = (presignedPostData: any, file: any, onProgress: Function) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();

      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });

      // Actual file has to be appended last.
      formData.append('file', file);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', presignedPostData.url, true);
      xhr.send(formData);
      xhr.onprogress = function (event) {
        const { loaded, total } = event;
        onProgress(
          {
            percent: Math.round((loaded / total) * 100),
          },
          file,
        );
      };
      xhr.onload = function () {
        this.status === 204 ? resolve(this.response) : reject(this.responseText);
      };
    });

  const handleUploadRequest = async ({ file, onError, onSuccess, onProgress }: any) => {
    try {
      setIsUploadInProgress(true);

      // Step 1 - get pre-signed POST data.
      const { data: presignedPostData } = await getPresignedPostData(application);

      // Step 2 - upload the file to S3.
      const response = await uploadFileToS3(presignedPostData, file, onProgress);

      // Step 3 - set file_name and mark upload as completed
      form.setFieldValue('file_name', presignedPostData.fields.key);
      setIsUploadInProgress(false);
      setIsValidForm(true);
      onSuccess(response);
    } catch (e) {
      setIsUploadInProgress(false);
      setIsValidForm(false);
      onError(e);
    }
  };

  return (
    <AddCertificateFormStyled>
      <IDPartnerForm>
        <Form layout="vertical" initialValues={defaultValues} form={form} onValuesChange={onValuesChange} {...formProps} preserve={false}>
          <Label>
            <LabelNumber>1</LabelNumber>
            <span>Generate CSR</span>
            <Tooltip placement="top" title="Generate CSR">
              <TooltipIcon />
            </Tooltip>
          </Label>
          <TerminalWrapper>
            <CodeCopy>
              {`openssl req -new -newkey rsa:2048 -nodes -keyout ${application.client_id}.key -out ${application.client_id}_${Date.now()}.csr -subj "/CN=${application.client_id}" -sha256`}
            </CodeCopy>
          </TerminalWrapper>

          <Label>
            <LabelNumber>2</LabelNumber>
            <span>Upload CSR/PEM</span>
            <Tooltip placement="top" title="Upload CSR/PEM">
              <TooltipIcon />
            </Tooltip>
          </Label>
          <Form.Item name="file_name" rules={[{ required: true }]} hidden>
            <IDPartnerInput />
          </Form.Item>
          <IDPartnerUploader disabled={!isUploadInProgress} customRequest={handleUploadRequest} />
        </Form>
      </IDPartnerForm>
      <Footer>
        <IDPartnerButton block size="large" type="default" onClick={onCancel}>
          CANCEL
        </IDPartnerButton>

        <IDPartnerButton block size="large" type="primary" onClick={handleFormSubmit} disabled={!isValidForm}>
          GENERATE CERTIFICATE
        </IDPartnerButton>
      </Footer>
    </AddCertificateFormStyled>
  );
};

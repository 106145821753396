import { Alert } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { bodyLargeRegular } from '../../../common.css';

export const ApplicationsPage = styled.div`
  padding-top: 32px;
  padding-bottom: 40px;
`;

export const StyledLink = styled(Link)`
  color: black;
  text-decoration: underline;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-900']};
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const InformationAlert = styled(Alert)`
  width: 100%;
  margin-top: 20px;

  & .ant-alert-message {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }
  & .ant-alert-description {
    font-size: 16px;
    line-height: 24px;
  }

  &.ant-alert-warning {
    .ant-alert-message {
      color: #836820;
    }
    .ant-alert-description {
      color: #836820;
    }
  }

  &.ant-alert-info {
    background-color: #e6f6ff;
    border: 1px solid #91d5ff;

    .ant-alert-icon {
      color: #1890ff;
    }
  }
`;

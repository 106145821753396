import React, { FC, useState } from 'react';
import { IDPartnerTableProps } from './types';
import { PaginationText, PaginationDropdown, PaginationWrapper, StyledTable, DropdownText, StyledPagination } from './idpartner-table.css';
import { IDPartnerDropdown } from '../idpartner-dropdown/idpartner-dropdown';

export const IDPartnerTable: FC<IDPartnerTableProps> = (props: IDPartnerTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { dataSource, onGetMore, onPageSizeChange, hasMore } = props;

  const fromItemCalculated = pageSize * (currentPage - 1) + 1;
  const fromItem = fromItemCalculated <= dataSource.length ? fromItemCalculated : 1;
  const nextStep = pageSize * (currentPage - 1) + pageSize;
  const toItem = nextStep > dataSource.length ? dataSource.length : nextStep;

  const handlePaginationChange = (newPageIndex: number) => {
    setCurrentPage(newPageIndex);
    const pagesCount = Math.ceil(dataSource.length / pageSize);
    const isMoveForward = currentPage < newPageIndex && pagesCount < newPageIndex;
    onGetMore && isMoveForward && onGetMore();
  };

  const handlePageSizeChange = (newPageSize: string) => {
    setPageSize(Number(newPageSize));
    onPageSizeChange && onPageSizeChange(+newPageSize);
  };

  return (
    <>
      <StyledTable
        {...props}
        pagination={{
          current: currentPage,
          pageSize,
        }}
      />
      {Boolean(dataSource.length) && (
        <PaginationWrapper>
          <PaginationText>{`${fromItem}-${toItem} of ${dataSource.length} items`}</PaginationText>
          <StyledPagination total={hasMore ? dataSource.length + 1 : dataSource.length} pageSize={pageSize} current={currentPage} onChange={handlePaginationChange} />
          <PaginationDropdown>
            <DropdownText>Rows per page</DropdownText>
            <IDPartnerDropdown
              onChange={handlePageSizeChange}
              value={pageSize.toString()}
              items={[
                {
                  name: '10',
                  value: '10',
                },
                {
                  name: '20',
                  value: '20',
                },
                {
                  name: '30',
                  value: '30',
                },
              ]}
            >
              <div>{pageSize}</div>
            </IDPartnerDropdown>
          </PaginationDropdown>
        </PaginationWrapper>
      )}
    </>
  );
};

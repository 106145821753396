import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import * as El from './otp-register-form.css';
import { IDPartnerAlert } from '../ui/idpartner-alert/idpartner-alert';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { IDPartnerForm } from '../ui/idpartner-form/idpartner-form';
import { IDPartnerInput } from '../ui/idpartner-input/idpartner-input';

export const OtpRegisterForm: FC = () => {
  const [qrCode, setQRCode] = useState();
  const [error, setError] = useState(null);
  const history = useHistory();

  const setupQRCode = async () => {
    const { data } = await axios.post(`${process.env.DASHBOARD_API_SERVICE_URL}/auth/totp/register`, {}, { withCredentials: true });
    setQRCode(data.qrcode_url);
  };

  useEffect(() => {
    setupQRCode();
  }, []);

  const onFinish = async (values: any) => {
    try {
      setError(null);
      const response = await axios.post(`${process.env.DASHBOARD_API_SERVICE_URL}/auth/totp/verify`, values, { withCredentials: true });
      if (response.data.verified) {
        history.push('/');
      }
    } catch (e) {
      setError(e.response.data.error);
    }
  };

  return (
    <El.SignupContainer>
      <El.ContentWrapper>
        {error && <IDPartnerAlert showIcon type="error" description={error} />}
        <El.StepTitle>Step 1: Scan QR Code to Activate Multi-Factor Authentication</El.StepTitle>
        <El.FormDescription>Open your preferred authentication app and scan the QR code below:</El.FormDescription>
        <El.QrCodeContainer>
          <img src={qrCode} width="50%" alt="register otp" />
        </El.QrCodeContainer>

        <El.StepTitle>Step 2: Enter Security Code</El.StepTitle>
        <El.FormDescription>Input the 6-digit code generated by your app to verify setup</El.FormDescription>
        <IDPartnerForm>
          <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
            <Form.Item name="token" rules={[{ required: true, message: 'Please enter a token!' }]}>
              <IDPartnerInput placeholder="Enter 6-Digit Code" style={{ marginBottom: 8 }} />
            </Form.Item>
            <Form.Item>
              <IDPartnerButton type="primary" block htmlType="submit" size="large">
                VERIFY AND ACTIVATE MFA
              </IDPartnerButton>
            </Form.Item>
          </Form>
        </IDPartnerForm>
      </El.ContentWrapper>
    </El.SignupContainer>
  );
};

import React, { FC, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { IDPartnerTable } from '../../../../components/ui/idpartner-table/idpartner-table';
import { EditProviderModal } from '../edit-provider-modal/edit-provider-modal';
import { DeleteProviderModal } from '../delete-provider-modal/delete-provider-modal';
import { DownloadProviderTrustPlatformModal } from '../download-provider-trust-platform-modal/download-provider-trust-platform-modal';
import * as El from './providers-table.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { CreateProviderModal } from '../create-provider-modal/create-provider-modal';
import { ApplicationsTableProps } from './types';
import { Provider } from '../../../../redux/providers/types';
import { DuplicateProviderModal } from '../duplicate-provider-modal/duplicate-provider-modal';
import { ProviderActionButtons } from '../../../../components/provider-action-buttons/provider-action-buttons';
import plusWhiteIcon from '../../../../assets/icons/add-button-white.svg';
import { Icon } from '../../../../components/icon/icon';
import { getHostingOptionDescription } from '../provider-form/provider-form';

export const ProvidersTable: FC<React.PropsWithChildren<ApplicationsTableProps>> = ({ providers, deleteProvider, updateProvider, createProvider, hasLoaded }) => {
  const [selectedProvider, setSelectedProvider] = useState<Provider | undefined>();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateProviderVisible, setIsCreateProviderVisible] = useState(false);
  const [isDuplicateProviderVisible, setIsDuplicateProviderVisible] = useState(false);
  const [isDownloadProviderTrustPlatformVisible, setIsDownloadProviderTrustPlatformVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(!hasLoaded);

  useEffect(() => {
    setIsLoading(!hasLoaded);
  }, [hasLoaded]);

  const handleEditButtonClick = (id: number) => {
    const selectedApp = providers.find(a => a.id === id);
    setSelectedProvider(selectedApp || null);

    if (selectedApp) {
      setIsEditModalVisible(true);
    }
  };

  const handleDeleteButtonClick = (id: number) => {
    const selectedApp = providers.find(a => a.id === id);
    setSelectedProvider(selectedApp || null);

    if (selectedApp) {
      setIsDeleteModalVisible(true);
    }
  };

  const handleDuplicateButtonClick = (id: number) => {
    const selectedApp = providers.find(a => a.id === id);
    setSelectedProvider(selectedApp || null);
    if (selectedApp) {
      setIsDuplicateProviderVisible(true);
    }
  };

  const handleDownloadTrustPlatformButtonClick = (id: number) => {
    const selectedApp = providers.find(a => a.id === id);
    setSelectedProvider(selectedApp || null);

    if (selectedApp) {
      setIsDownloadProviderTrustPlatformVisible(true);
    }
  };

  const handleStatusFilter = (value: string, record: Provider) => record.environment === value;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Hosting Preference',
      render: (bankServicesConfig: any, provider: Provider, index: number) => getHostingOptionDescription(provider.bank_services_config?.trust_platform_hosting_preference),
      width: '30%',
    },
    {
      title: 'Status',
      dataIndex: 'environment',
      key: 'environment',
      filters: [
        { text: 'Development', value: 'development' },
        { text: 'Sandbox', value: 'sandbox' },
        { text: 'Production', value: 'production' },
      ],
      onFilter: handleStatusFilter,
    },
    {
      width: '150px',
      title: '',
      key: 'action',
      render: (entity: Provider) => (
        <ProviderActionButtons
          typeName="Trust Platform"
          onRemove={() => handleDeleteButtonClick(entity.id)}
          onEdit={() => handleEditButtonClick(entity.id)}
          onDuplicate={() => handleDuplicateButtonClick(entity.id)}
          onDownload={() => handleDownloadTrustPlatformButtonClick(entity.id)}
        />
      ),
    },
  ];

  const handleEditModalCancel = () => {
    setSelectedProvider(undefined);
    setIsEditModalVisible(false);
  };

  const handleDeleteModalCancel = () => {
    setSelectedProvider(undefined);
    setIsDeleteModalVisible(false);
  };

  const handleDuplicateModalCancel = () => {
    setSelectedProvider(undefined);
    setIsDuplicateProviderVisible(false);
  };

  const handleDownloadTrustPlatformModalCancel = () => {
    setSelectedProvider(undefined);
    setIsDownloadProviderTrustPlatformVisible(false);
  };

  const handleEdit = (data: Provider) => {
    updateProvider(selectedProvider.id, { ...data });
    // TODO: add real processing and success states
    setTimeout(() => {
      setIsEditModalVisible(false);
      setSelectedProvider(undefined);
    }, 500);
  };

  const handleDelete = () => {
    deleteProvider(selectedProvider.id);
    setTimeout(() => {
      setIsDeleteModalVisible(false);
      setSelectedProvider(undefined);
    }, 500);
  };

  const handleDuplicate = () => {
    // const { id, enabled, created_at, updated_at, uuid, connected_account_id, environment, countries, ...providerTemplate } = selectedProvider;
    const { name, consent_required, issuer_url, image_url, featured_image_url, url, auth_url, branding_theme, metadata } = selectedProvider;
    const newProvider = {
      name: `${name} copy`,
      country_codes: selectedProvider.countries.map(country => country.country_code),
      enabled: false,
      consent_required,
      issuer_url,
      image_url,
      featured_image_url,
      url,
      auth_url,
      branding_theme,
      metadata,
    };
    createProvider(newProvider);
    setTimeout(() => {
      setIsDuplicateProviderVisible(false);
      setSelectedProvider(undefined);
    }, 500);
  };

  const handleCreate = (formData: Provider) => {
    createProvider(formData);
    // TODO: add real action to show processing and success states;
    setTimeout(() => {
      setIsCreateProviderVisible(false);
    }, 500);
  };

  return (
    <Fragment>
      <IDPartnerTable
        locale={{
          emptyText: (
            <El.NoDataWrapper>
              <El.NoDataIcon />
              <El.NoDataDescription>
                Check out our <a href="https://docs.idpartner.com/documentation/identity-provider-user-guide">Documentation</a> how to register a Trust Platform with the IDPartner Network
              </El.NoDataDescription>
              <Row>
                <Col>
                  <IDPartnerButton icon={<Icon url={plusWhiteIcon} iconWidth="24" iconHeight="24" />} type="primary" onClick={() => setIsCreateProviderVisible(true)}>
                    REGISTER TRUST PLATFORM
                  </IDPartnerButton>
                </Col>
              </Row>
            </El.NoDataWrapper>
          ),
        }}
        dataSource={providers}
        columns={columns}
        loading={isLoading}
        showHeader={providers.length !== 0}
        dropdownPrefixCls="idpartner-filter-dropdown"
      />
      {isEditModalVisible ? <EditProviderModal application={selectedProvider} open={isEditModalVisible} onCancel={handleEditModalCancel} onSubmit={handleEdit} /> : null}
      {selectedProvider && <DeleteProviderModal onSubmit={handleDelete} provider={selectedProvider} open={isDeleteModalVisible} onCancel={handleDeleteModalCancel} />}
      {selectedProvider && <DuplicateProviderModal onSubmit={handleDuplicate} provider={selectedProvider} open={isDuplicateProviderVisible} onCancel={handleDuplicateModalCancel} />}
      {isCreateProviderVisible ? <CreateProviderModal open={isCreateProviderVisible} onCancel={() => setIsCreateProviderVisible(false)} onSubmit={handleCreate} /> : null}
      {selectedProvider && <DownloadProviderTrustPlatformModal provider={selectedProvider} open={isDownloadProviderTrustPlatformVisible} onCancel={handleDownloadTrustPlatformModalCancel} />}
    </Fragment>
  );
};

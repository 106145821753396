import styled from 'styled-components';
import uploadIcon from '../../../assets/icons/upload-icon.svg';
import { IDPartnerButton } from '../idpartner-button/idpartner-button';

export const UploadIcon = styled.div`
  background: url('${uploadIcon}') center no-repeat;
  width: 29px;
  height: 29px;
`;

export const UploadButton = styled(IDPartnerButton)`
  border-color: #808799;
  color: #808799;
  padding: 4px 14px;
  width: 150px;
`;

import React, { FC } from 'react';
import * as El from './subscription.css';
import { SubscriptionProps } from './types';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';

export const Subscription: FC<SubscriptionProps> = ({ name, status, defaultPaymentMethod, nextInvoiceAt, amountRemaining, onCancel }) => (
  <El.SubscriptionWrapper>
    <El.Status status={status}>{status}</El.Status>
    <El.ItemTitle>{name}</El.ItemTitle>
    <El.ItemDescription>{defaultPaymentMethod}</El.ItemDescription>
    <El.ItemDescription>{`Next $${amountRemaining / 100} invoice at ${nextInvoiceAt}`}</El.ItemDescription>

    <IDPartnerButton danger onClick={onCancel}>
      Cancel
    </IDPartnerButton>
  </El.SubscriptionWrapper>
);

import styled, { css } from 'styled-components';

export const MembersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  justify-content: flex-end;
`;

const getStylesByEventType = (eventType: string | undefined) => {
  if (eventType) {
    return css`
      color: ${props => props.theme.success};
      background: ${props => props.theme['green-transparent']};
    `;
  }

  return css`
    color: ${props => props.theme['warning-color']};
    background: ${props => props.theme['yellow-transparent']};
  `;
};

export const Status = styled.div<{ status: string | undefined }>`
  background: ${props => props.theme['green-transparent']};
  border-radius: 4px;
  padding: 2px 8px;
  color: ${props => props.theme.success};
  text-align: center;
  display: inline-block;
  text-transform: capitalize;

  ${props => getStylesByEventType(props.status)}
`;

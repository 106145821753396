import React, { FC } from 'react';
import * as El from './sidebar-user-account.css';
import { SidebarUserAccountProps } from './types';

export const SidebarUserAccount: FC<SidebarUserAccountProps> = ({ firstName, lastName, companyName, email }) => (
  <El.UserAccountWrapper>
    <El.UserAvatarWrapper>{firstName ? firstName?.[0] : lastName?.[0]}</El.UserAvatarWrapper>
    <El.UserName>{`${firstName || ''} ${lastName || ''}`}</El.UserName>
  </El.UserAccountWrapper>
);

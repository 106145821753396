import styled from 'styled-components';
import { bodyLargeRegular, h1 } from '../../common.css';

export const AuthSuccessStyled = styled.div``;

export const Illustration = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
`;

export const Title = styled.div`
  ${h1};
  margin-bottom: 16px;
  text-align: center;
`;

export const Message = styled.div`
  ${bodyLargeRegular};
  color: ${({ theme }) => theme['gray-400']};
  margin-bottom: 24px;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
`;

import styled, { css } from 'styled-components';
import stepMark from '../../../assets/icons/step-mark.svg';

const circleDiameter = 16;
const spaceForText = 30;

export const StepsProgressStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Line = styled.div`
  background: ${({ theme }) => theme['gray-100']};
  height: 1px;
  width: 100%;
  margin: ${circleDiameter / 2 - 1 + spaceForText}px 0;
  overflow: hidden;
  &.passed {
    background-color: #000000;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: ${spaceForText}px;
`;

export const StepPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme['gray-400']};
  width: ${circleDiameter}px;
  height: ${circleDiameter}px;
  background: ${props => props.theme.white};
  border-radius: ${circleDiameter / 2}px;
  border: 1px solid ${props => props.theme['blue-100']};

  &.current {
    border: 1px solid #000000;
  }
  &.passed {
    border: 1px solid #000000;
    background-color: #000000;
    background-image: url('${stepMark}');
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const StepName = styled.div<{ isFirstStep: boolean; isLastStep: boolean; isNotPassed: boolean }>`
  position: absolute;
  top: 0;
  font-family: Public Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${props => props.theme['system-secondary-text']};
  ${props =>
    props.isFirstStep &&
    css`
      left: 0;
    `}

  ${props =>
    props.isLastStep &&
    css`
      left: unset;
      right: 0;
    `}
  
  ${props =>
    props.isNotPassed &&
    css`
      color: ${props => props.theme['system-placeholder-text']};
    `}
`;

import React, { FC } from 'react';
import { Upload } from 'antd';
import { IDPartnerUploaderProps } from './types';
import * as El from './idpartner-uploader.css';

export const IDPartnerUploader: FC<IDPartnerUploaderProps> = props => (
  <Upload {...props}>
    <El.UploadButton icon={<El.UploadIcon />}>UPLOAD CSR</El.UploadButton>
  </Upload>
);

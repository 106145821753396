import styled from 'styled-components';

export const InputsMatrixGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 5fr 1fr;
  gap: 4px;
`;

export const AddRow = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
`;

export const NoDataRow = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

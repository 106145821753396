import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { bodyLargeRegular, bodySmallRegular, h1 } from '../../common.css';
import logoIcon from '../../assets/logo_darkblue.svg';
import { IDPartnerAlert } from '../../components/ui/idpartner-alert/idpartner-alert';

export const ContentWrapper = styled.div`
  width: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    width: 100%;
  }
`;

export const LoginRedesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.div`
  background: url('${logoIcon}') center no-repeat;
  width: 216px;
  height: 62px;
  margin-bottom: 56px;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
  text-align: center;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
`;

export const FormDescription = styled(FormText)`
  margin-bottom: 24px;
  text-align: center;
`;

export const FormForgotPasswordText = styled(FormText)`
  margin-bottom: 16px;
  color: #434343;
  opacity: 0.5;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LabeledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #232733;
  margin-bottom: 8px;
`;

export const LoginInput = styled(LabeledInput)`
  margin-bottom: 24px;
`;

export const PasswordInput = styled(LabeledInput)`
  margin-bottom: 32px;
`;

export const StyledAlert = styled(IDPartnerAlert)`
  margin-bottom: 24px;
`;

export const ForgotPasswordLink = styled(Link)`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2654f5;
`;

export const StyledAlertLink = styled(Link)`
  margin-top: 8px;
  ${bodySmallRegular};
  margin-left: 4px;
  color: ${props => props.theme['gray-900']};
  text-decoration: underline;
`;

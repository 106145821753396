import styled from 'styled-components';
import logoIcon from '../../assets/logo.svg';
import { bodyLargeRegular, h1 } from '../../common.css';

export const ResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 336px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.div`
  background: url('${logoIcon}') center no-repeat;
  width: 141px;
  height: 40px;
  margin-bottom: 56px;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
  text-align: center;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
`;

export const FormDescription = styled(FormText)`
  margin-bottom: 24px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 64px;
  width: 100%;
`;

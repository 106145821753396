import React, { FC, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import * as El from './new-subscription-form.css';
import { StepsProgress } from '../ui/steps-progress/steps-progress';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { SUBSCRIPTION_STEPS } from './types';
import { AddPaymentMethodForm } from '../add-payment-method-form/add-payment-method-form';
import { PaymentMethod } from '../payment-method/payment-method';
import { Api } from '../../modules/utils/api';
import { IDPartnerAlert } from '../ui/idpartner-alert/idpartner-alert';
import { ApplicationState } from '../../redux/store';
import {
  fetchPaymentMethods as fetchPaymentMethodsRedux,
  fetchPaymentProfile as fetchPaymentProfileRedux,
  fetchUserInvoices as fetchUserInvoicesRedux,
  fetchUserSubscription as fetchUserSubscriptionRedux,
} from '../../redux/billing/async';
import { notification } from 'antd';

const dnsCheckFlow = [SUBSCRIPTION_STEPS.SUBSCRIPTION, SUBSCRIPTION_STEPS.PAYMENT];
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const mapStateToProps = ({ billing }: ApplicationState) => ({
  offers: billing.offers,
  paymentMethods: billing.paymentMethods,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserSubscription: bindActionCreators(fetchUserSubscriptionRedux, dispatch),
  fetchPaymentMethods: bindActionCreators(fetchPaymentMethodsRedux, dispatch),
  fetchUserInvoices: bindActionCreators(fetchUserInvoicesRedux, dispatch),
  fetchPaymentProfile: bindActionCreators(fetchPaymentProfileRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const NewSubscription: FC<AllProps> = ({ offers, paymentMethods, fetchPaymentMethods, fetchUserSubscription, fetchUserInvoices, fetchPaymentProfile }) => {
  const [currentStep, setCurrentStep] = useState(dnsCheckFlow[0]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isShowForm, setIsShowForm] = useState(false);
  const offerDetails = offers[0];

  const onNextStep = () => {
    setCurrentStep(prev => {
      const currentStepIndex = dnsCheckFlow.indexOf(prev);
      return dnsCheckFlow[currentStepIndex + 1];
    });
  };

  const onPaymentMethodSelect = (paymentMethodId: string | undefined) => {
    setIsShowForm(false);
    if (selectedPaymentMethod === paymentMethodId) {
      setSelectedPaymentMethod(undefined);
    } else {
      setSelectedPaymentMethod(paymentMethodId);
    }
  };

  const handleError = (error: string) => {
    setErrorMessage(error);
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 5000);
  };

  const onSubscribe = () => {
    const api = new Api();
    api
      .subscribeUser({ paymentMethod: selectedPaymentMethod, priceId: offerDetails.id })
      .then(() => {
        fetchPaymentMethods();
        fetchUserSubscription();
        fetchUserInvoices();
        fetchPaymentProfile();
        notification.success({
          message: 'Subscription successful',
        });
      })
      .catch(err => {
        handleError(err.response.data.message);
      });
  };

  const onMethodSuccessfullyAdded = () => {
    setIsShowForm(false);
    fetchPaymentMethods();
  };

  const handleBackClick = () => {
    setSelectedPaymentMethod(undefined);
    setIsShowForm(false);
    setCurrentStep(prev => {
      const currentStepIndex = dnsCheckFlow.indexOf(prev);
      return dnsCheckFlow[currentStepIndex - 1];
    });
  };

  return (
    <El.FormContainer>
      <El.FormWrapper>
        <El.FormTitle>Subscribing</El.FormTitle>
        <El.FormDescription>Subscribe to pay for the service</El.FormDescription>
        <El.StepsWrapper>
          <StepsProgress steps={['Subscription Info', 'Payment methods']} currentStep={dnsCheckFlow.indexOf(currentStep) + 1} />
        </El.StepsWrapper>

        {errorMessage && <IDPartnerAlert type="error" showIcon description={errorMessage} />}

        <El.FormContentWrapper isHidden={currentStep !== SUBSCRIPTION_STEPS.SUBSCRIPTION}>
          <El.OfferInfoWrapper>
            <El.ItemTitle>{offerDetails?.nickname}</El.ItemTitle>
            <El.Price>{`$${offerDetails?.unit_amount / 100} ${offerDetails?.recurring.usage_type === 'metered' ? 'per verification' : 'per month'}`}</El.Price>
            <El.ItemDescription>{offerDetails?.type}</El.ItemDescription>
          </El.OfferInfoWrapper>

          <IDPartnerButton type="primary" onClick={onNextStep}>
            Subscribe
          </IDPartnerButton>
        </El.FormContentWrapper>

        <El.FormContentWrapper isHidden={currentStep !== SUBSCRIPTION_STEPS.PAYMENT}>
          <El.LinkToSignIn onClick={handleBackClick}>
            <El.ArrowIcon />
            <span>Back</span>
          </El.LinkToSignIn>
          <El.FormSubtitle>Choose a payment method or add a new one</El.FormSubtitle>
          <El.PaymentMethodsWrapper>
            {paymentMethods.length ? (
              paymentMethods.map(method => (
                <PaymentMethod
                  key={method.id}
                  cardBrand={method.card.brand}
                  cardLast4={method.card.last4}
                  holderName={method.billing_details.name}
                  expiresData={`${method.card.exp_month}/${method.card.exp_year}`}
                  onClick={() => onPaymentMethodSelect(method.id)}
                  isActive={selectedPaymentMethod === method.id}
                />
              ))
            ) : (
              <span>You don&apos;t have any payment methods yet</span>
            )}
            {isShowForm && !selectedPaymentMethod && (
              <El.NewPaymentMethodWrapper>
                <Elements stripe={stripePromise}>
                  <AddPaymentMethodForm onSuccess={onMethodSuccessfullyAdded} onError={handleError} />
                </Elements>
              </El.NewPaymentMethodWrapper>
            )}
          </El.PaymentMethodsWrapper>

          <El.ButtonsWrapper>
            {Boolean(paymentMethods.length) && !isShowForm && (
              <IDPartnerButton disabled={!selectedPaymentMethod} type="primary" onClick={onSubscribe} block>
                Subscribe
              </IDPartnerButton>
            )}
            {!isShowForm && !selectedPaymentMethod && (
              <IDPartnerButton onClick={() => setIsShowForm(true)} block>
                Add new payment method
              </IDPartnerButton>
            )}
          </El.ButtonsWrapper>
        </El.FormContentWrapper>
      </El.FormWrapper>
    </El.FormContainer>
  );
};

export const NewSubscriptionForm = connect(mapStateToProps, mapDispatchToProps)(NewSubscription);

import styled from 'styled-components';
import { bodySmallMedium } from '../../../../common.css';
import tooltipIcon from '../../../../assets/icons/tooltip-icon.svg';

export const ApplicationFormStyled = styled.div`
  & .ant-form-item-optional {
    font-size: 0;
    display: flex !important;
    align-items: end !important;

    &:after {
      content: 'Optional';
      ${bodySmallMedium};
      color: ${props => props.theme['gray-300']};
    }
  }
`;

export const TooltipIcon = styled.div`
  background: url('${tooltipIcon}') center no-repeat;
  width: 16px;
  height: 16px;
`;

export const AlertWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Footer = styled.div`
  margin-top: 64px;
  display: flex;
  gap: 16px;
`;

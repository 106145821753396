import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { Api } from '../../modules/utils/api';
import { AccountType } from './types';
import { withAuth } from '../util';
import { fetchAccountAction } from './actions';

export type LoginResult = {
  account: AccountType;
  token: string;
};
export type LoginCallbackResultType = {
  callback: LoginResult;
};

export const login = (email: string, password: string) => async () => {
  const api = new Api();
  const { data } = await api.loginUser(email, password);
  return data;
};

export const descopeLogin = (sessionToken: string, email: string) => async () => {
  const api = new Api();
  const { data } = await api.descopeLoginUser(sessionToken, email);
  return data;
};

export const reset = async (email: string) => {
  const api = new Api();
  await api.resetPasswordRequest(email);
};
export const createAccount = async (account: AccountType) => {
  const api = new Api();
  await api.createAccount(account);
};

export const createAccountWithToken = async (token: string, account: AccountType) => {
  const api = new Api();
  await api.createGroupMember(token, account);
};

export const passwordChange = async (token: string, password: string) => {
  const api = new Api();
  await api.changePassword(token, password);
};

export const fetchAccount = (id: number) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.fetchAccount(id).then((result: AxiosResponse<AccountType>) => {
      dispatch(fetchAccountAction(result.data));
    }),
  );
export const fetchSessionAccount = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.fetchSessionAccount().then((result: AxiosResponse<AccountType>) => {
      dispatch(fetchAccountAction(result.data));
    }),
  );

export const updateAccount = (account: AccountType) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.updateAccount(account).then((result: AxiosResponse<AccountType>) => {
      dispatch(fetchAccountAction(result.data));
    }),
  );

export const fetchMerchantCodes = async () => {
  const api = new Api();
  const result = await api.fetchMerchantCodes();
  return result;
};

export const verifyLei = async (lei: string) => {
  const api = new Api();
  const result = await api.verifyLei(lei);
  return result;
};

export const generateDomainToken = async (domainName: string) => {
  const api = new Api();
  const result = await api.generateDomainToken(domainName);
  return result;
};

export const verifyDomainToken = async () => {
  const api = new Api();
  await api.verifyDomainToken();
};

import { Descope, getSessionToken } from '@descope/react-sdk';
import mixpanel from 'mixpanel-browser';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { IDPartnerButton } from '../../components/ui/idpartner-button/idpartner-button';
import { descopeLogin as loginCall } from '../../redux/auth/async';
import { ApplicationState } from '../../redux/store';
import { featureFlags } from '../../utils/featureFlags';
import * as El from './login-form-redesign.css';

const LoginRedesign: FC<AllProps> = ({ login, history }) => {
  const handleSignup = () => {
    history.push('/signup');
  };

  const descopeOnSuccess = async (e: any) => {
    const sessionToken = getSessionToken();
    try {
      const user = (await login(sessionToken, e.detail.user.email)) as any;
      if (!user.email_verified_at) {
        return history.push('/email-verification');
      }
      if (featureFlags.isMFAEnabled) {
        if (!user.totp_enabled) {
          return history.push('/otp/register');
        }
        return history.push('/otp/validate');
      }
      mixpanel.track('dashboard_client:click:login:successful');
      return history.push('/');
    } catch (error) {
      mixpanel.track('dashboard_client:click:login:unsuccessful');
      console.error('Error posting to backend:', error);
      return undefined;
    }
  };

  const descopeOnError = (error: any) => {
    console.error('Error posting to backend:', error);
  };

  return (
    <El.ContentWrapper>
      <El.FormTitle>Welcome!</El.FormTitle>
      <Descope flowId="sign-in" theme="light" onSuccess={descopeOnSuccess} onError={descopeOnError} />

      <El.FormForgotPasswordText>Don’t have an account yet?</El.FormForgotPasswordText>
      <El.ButtonWrapper>
        <IDPartnerButton block onClick={handleSignup}>
          SIGN UP
        </IDPartnerButton>
      </El.ButtonWrapper>
    </El.ContentWrapper>
  );
};

export interface PropsFromDispatch {
  login: typeof loginCall;
}
type AllProps = PropsFromDispatch & RouteComponentProps;

const mapStateToProps = ({ auth }: ApplicationState) => ({
  errors: auth.errors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: bindActionCreators(loginCall, dispatch),
});

export const LoginFormRedesign = connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginRedesign));

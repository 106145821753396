import styled from 'styled-components';
import { bodyLargeRegular, h2 } from '../../../../common.css';
import noDataIconSvg from '../../../../assets/icons/no-data-icon.svg';

export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoDataIcon = styled.div`
  width: 48px;
  height: 48px;
  background: url('${noDataIconSvg}') center no-repeat;
  margin-bottom: 24px;
`;

export const NoDataTitle = styled.div`
  ${h2};
  margin-bottom: 12px;
`;

export const NoDataDescription = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
  text-align: center;
  margin-bottom: 24px;
`;

import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { IDPartnerTable } from '../ui/idpartner-table/idpartner-table';
import * as El from './logs-list.css';
import { SearchIcon } from '../../modules/applications/components/table-heading/table-heading.css';
import { IDPartnerInput } from '../ui/idpartner-input/idpartner-input';
import { AsyncComponent } from '../async-component/async-component';
import { ApplicationState } from '../../redux/store';
import { fetchVerificationEvents as fetchVerificationEventsRedux } from '../../redux/logs/async';
import { EventType, VerificationEvent } from '../../redux/logs/types';
import { fetchApplications as fetchApplicationsRedux } from '../../redux/applications/async';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';

const mapStateToProps = ({ logs, applications }: ApplicationState) => ({
  verificationEvents: logs.data,
  applications: applications.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchVerificationEvents: bindActionCreators(fetchVerificationEventsRedux, dispatch),
  fetchApplications: bindActionCreators(fetchApplicationsRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const getStatusTextByEventType = (eventType: EventType) => {
  switch (eventType) {
    case 'verification.failed':
      return 'Failed';
    case 'verification.idtoken_issued':
      return 'IDToken Issued';
    case 'verification.userinfo_issued':
      return 'User Info Issued';
    case 'verification.payment_details_info_issued':
      return 'Payment Details Issued';
    case 'verification.payment_processing_started':
      return 'Payment Started';
    case 'verification.started':
      return 'Started';
    default:
      return eventType;
  }
};

const LogsListComponent: FC<AllProps> = props => {
  const { verificationEvents, fetchVerificationEvents, applications, fetchApplications } = props;
  const [transactions, setTransactions] = useState<VerificationEvent[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<VerificationEvent[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setTransactions(verificationEvents);
  }, [verificationEvents]);

  useEffect(() => {
    setFilteredTransactions(onSearch(searchQuery));
  }, [transactions]);

  const columns: ColumnsType<VerificationEvent> = [
    {
      title: 'Provider name',
      dataIndex: 'identity_provider_name',
      sorter: (a, b) => a.identity_provider_name.localeCompare(b.identity_provider_name),
    },
    {
      title: 'Status',
      dataIndex: 'event_type',
      sorter: (a, b) => a.event_type.localeCompare(b.event_type),
      render: eventType => <El.Status status={eventType}>{getStatusTextByEventType(eventType)}</El.Status>,
    },
    {
      title: 'Timestamp',
      dataIndex: 'created_at',
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: timestamp => moment(new Date(timestamp)).format('MM/DD/YYYY hh:mm:ss A'),
    },
  ];

  const onSearch = (query: string): any[] => {
    if (!query) {
      return transactions;
    }
    const r = new RegExp(query, 'gmi');
    return transactions.filter(transact => transact.identity_provider_name.search(r) !== -1);
  };

  const debouncedSearch = useCallback(
    debounce(query => {
      setFilteredTransactions(onSearch(query));
    }, 500),
    [transactions, applications],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchQuery(value);
      debouncedSearch(value);
    },
    [transactions, applications],
  );

  const mainStats = useMemo(
    () => [
      {
        title: 'Started Transactions',
        value: transactions.filter(transaction => transaction.event_type === 'verification.started').length,
      },
      {
        title: 'Successful Transactions',
        value: transactions.filter(transaction => transaction.event_type === 'verification.idtoken_issued').length,
      },
      {
        title: 'Failed Transactions',
        value: transactions.filter(transaction => transaction.event_type === 'verification.failed').length,
      },
    ],
    [transactions],
  );

  const paymentStats = useMemo(
    () => [
      {
        title: 'Payment Details Issued',
        value: transactions.filter(transaction => transaction.event_type === 'verification.payment_details_info_issued').length,
      },
      {
        title: 'Payment Started',
        value: transactions.filter(transaction => transaction.event_type === 'verification.payment_processing_started').length,
      },
    ],
    [transactions],
  );

  const downloadLogs = () => {
    const url = `${process.env.DASHBOARD_API_SERVICE_URL}/verification_events/csv`;
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <AsyncComponent message="Loading Logs" getAsyncActions={() => [fetchApplications(), fetchVerificationEvents({})]}>
      <El.LogListWrapper>
        <El.LogsDownloadButton>
          <IDPartnerButton type="primary" onClick={downloadLogs}>
            EXPORT
          </IDPartnerButton>
        </El.LogsDownloadButton>
        <El.LogsStats>
          {mainStats.map(item => (
            <El.LogsStatsItemContainer>
              <El.LogsStatsTitle>{item.value}</El.LogsStatsTitle>
              <El.LogsStatsDescritpion>{item.title}</El.LogsStatsDescritpion>
            </El.LogsStatsItemContainer>
          ))}
        </El.LogsStats>
        <El.LogsStats>
          {paymentStats.map(item => (
            <El.LogsStatsItemContainer>
              <El.LogsStatsTitle>{item.value}</El.LogsStatsTitle>
              <El.LogsStatsDescritpion>{item.title}</El.LogsStatsDescritpion>
            </El.LogsStatsItemContainer>
          ))}
        </El.LogsStats>
        <El.FiltersWrapper>
          <El.SearchWrapper>
            <IDPartnerInput onChange={handleSearch} allowClear prefix={<SearchIcon />} width="100%" size="small" placeholder="Search..." />
          </El.SearchWrapper>

          {/* <El.RightCorner>
            <El.PeriodsWrapper>
              <IDPartnerButtonTabs items={tabItems} activeItemSlug={period} onClick={handlePeriodChange} />
            </El.PeriodsWrapper>

            <El.DatepickerWrapper>
              <IDPartnerDatepicker onChange={handleRangeChange} value={rangeValue} />
            </El.DatepickerWrapper>
          </El.RightCorner> */}
        </El.FiltersWrapper>
        <IDPartnerTable
          locale={{
            emptyText: 'No Event Logs',
          }}
          dataSource={filteredTransactions}
          columns={columns}
        />
      </El.LogListWrapper>
    </AsyncComponent>
  );
};

export const LogsList = connect(mapStateToProps, mapDispatchToProps)(LogsListComponent);

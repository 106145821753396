import styled from 'styled-components';
import { Modal } from 'antd';

const modalBorderRadius = '12px';

export const IDPartnerModalStyled = styled(Modal)`
  .ant-modal-content {
    background: ${props => props.theme.white};
    border-radius: ${modalBorderRadius};
  }

  .ant-modal-header {
    padding: 40px 40px 0 40px;
    background: #fff;
    border: none;
    border-radius: ${modalBorderRadius} ${modalBorderRadius} 0 0;
    .ant-modal-title {
      color: var(--base-graphite-0, #000);
      font-family: Visby CF, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 113%;
    }
  }

  .ant-modal-close {
    font-size: 0;
    .ant-modal-close-x {
      height: 24px;
      width: 24px;
      line-height: 24px;
      margin: 16px;
    }
  }

  .ant-modal-body {
    padding: 40px;
  }

  .ant-modal-footer {
    padding: 0 32px 24px 32px;

    border: none;
    border-radius: 0 0 ${modalBorderRadius} ${modalBorderRadius};
  }
`;

export const IDPartnerModalDefaultFooter = styled.div``;

import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import * as El from './reset-password-form-redesign.css';
import { IDPartnerInput } from '../../components/ui/idpartner-input/idpartner-input';
import { IDPartnerButton } from '../../components/ui/idpartner-button/idpartner-button';
import { IDPartnerForm } from '../../components/ui/idpartner-form/idpartner-form';
import { reset } from '../../redux/auth/async';
import { IDPartnerAlert } from '../../components/ui/idpartner-alert/idpartner-alert';
import { BackButton } from '../../components/back-button/back-button';

const initialData = {
  email: '',
};
export const ResetPasswordFormRedesign = () => {
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [error, setError] = useState<string | undefined>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalId);
      setIsButtonDisabled(false);
    }
  }, [timer]);

  const handleSendClick = async () => {
    setIsButtonDisabled(true);
    setError(undefined);
    const validationResult = await form.validateFields();
    const isValid = !validationResult.errorFields || !validationResult.errorFields.length;
    if (!timer && isValid) {
      const { email } = form.getFieldsValue();
      try {
        await reset(email);
        setTimer(30);
        const id = setInterval(() => {
          setTimer(seconds => seconds - 1);
        }, 1000);
        setIntervalId(id);
      } catch (e) {
        setError('Could not reset your password');
      }
    }
  };

  const handleBackClick = () => {
    history.push('/');
  };

  return (
    <>
      <El.FormTitle>Don’t worry, happens to best of us</El.FormTitle>
      <El.FormDescription>Just type your email below and if we have it in our system — we’ll send the recovery mail</El.FormDescription>
      {error && <IDPartnerAlert showIcon description={error} type="error" />}
      <IDPartnerForm>
        <Form layout="vertical" initialValues={initialData} form={form} requiredMark="optional">
          <Row>
            <Col span="24">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is not valid',
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  },
                ]}
              >
                <IDPartnerInput />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </IDPartnerForm>

      <El.ButtonsWrapper>
        <BackButton onClick={handleBackClick} />
        <IDPartnerButton block type="primary" onClick={handleSendClick} disabled={isButtonDisabled}>
          {timer ? `Re-send in: ${timer}s` : 'Submit'}
        </IDPartnerButton>
      </El.ButtonsWrapper>
    </>
  );
};

import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import * as El from './dns-check.css';
import { DNS_CHECK_STEPS, VerificationsListProps } from './types';
import { ApplicationState } from '../../redux/store';
import { StepsProgress } from '../../components/ui/steps-progress/steps-progress';
import { IDPartnerInput } from '../../components/ui/idpartner-input/idpartner-input';
import { IDPartnerAlert } from '../../components/ui/idpartner-alert/idpartner-alert';
import { IDPartnerButton } from '../../components/ui/idpartner-button/idpartner-button';
import { IDPartnerForm } from '../../components/ui/idpartner-form/idpartner-form';
import { generateDomainToken, verifyDomainToken } from '../../redux/auth/async';

const dnsCheckFlow = [DNS_CHECK_STEPS.DOMAIN, DNS_CHECK_STEPS.OWNERSHIP];

const DnsCheckComponent: FC<VerificationsListProps> = ({ account }) => {
  const [currentStep, setCurrentStep] = useState(dnsCheckFlow[0]);
  const [token, setToken] = useState<string | undefined>(account.domain_verification_token);
  const [error, setError] = useState<string | undefined>();
  const [form] = Form.useForm();

  const initialValues = {
    domain_name: account.domain_name,
  };

  const onNextStep = async () => {
    setError(undefined);
    const validationResult = await form.validateFields();
    const isCurrentStepValid = !validationResult.errorFields || !validationResult.errorFields.length;
    if (isCurrentStepValid) {
      if (token) {
        setCurrentStep(prev => {
          const currentStepIndex = dnsCheckFlow.indexOf(prev);
          return dnsCheckFlow[currentStepIndex + 1];
        });
      } else {
        try {
          const domainToken = await generateDomainToken(form.getFieldValue('domain_name'));
          if (domainToken) {
            const { domain_verification_token } = domainToken.data;
            setToken(domain_verification_token);
            setCurrentStep(prev => {
              const currentStepIndex = dnsCheckFlow.indexOf(prev);
              return dnsCheckFlow[currentStepIndex + 1];
            });
          } else {
            setError('Something went wrong');
          }
        } catch (e) {
          console.error(e);
          const {
            response: { data },
          } = e;
          setError(data.error_description);
        }
      }
    }
  };

  const onPreviousStep = () => {
    setCurrentStep(prev => {
      const currentStepIndex = dnsCheckFlow.indexOf(prev);
      return currentStepIndex === 0 ? dnsCheckFlow[0] : dnsCheckFlow[currentStepIndex - 1];
    });
  };

  const onVerify = async () => {
    try {
      await verifyDomainToken();
    } catch (e) {
      console.error(e);
      const {
        response: { data },
      } = e;
      setError(data.error_description);
    }
  };

  const footerActions = {
    [DNS_CHECK_STEPS.DOMAIN]: [
      <Link to="/">
        <IDPartnerButton>BACK</IDPartnerButton>
      </Link>,
      <IDPartnerButton type="primary" onClick={onNextStep}>
        NEXT
      </IDPartnerButton>,
    ],
    [DNS_CHECK_STEPS.OWNERSHIP]: [
      <IDPartnerButton onClick={onPreviousStep}>BACK</IDPartnerButton>,
      <IDPartnerButton type="primary" onClick={onVerify}>
        VERIFY
      </IDPartnerButton>,
    ],
  };

  return (
    <El.DnsCheckContainer>
      <El.DnsCheckForm>
        <El.FormTitle>DNS Check</El.FormTitle>
        <El.FormDescription>
          To ensure the security of user&apos;s personal data, we follow a domain ownership verification process during onboarding. To complete this verification, please follow the steps outlined
          below.
        </El.FormDescription>

        {error && (
          <El.ErrorWrapper>
            <IDPartnerAlert type="error" showIcon description={error} />
          </El.ErrorWrapper>
        )}

        <El.FormContentWrapper isHidden={currentStep !== DNS_CHECK_STEPS.DOMAIN}>
          <IDPartnerForm>
            <Form layout="vertical" requiredMark="optional" form={form} initialValues={initialValues}>
              <Form.Item
                label="Enter your domain name without the subdomain (e.g., idpartner.com)"
                name="domain_name"
                rules={[
                  {
                    required: true,
                    pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                    message: 'Please enter an origin URL',
                  },
                ]}
              >
                <IDPartnerInput allowClear addonBefore="https://" readOnly={Boolean(token)} />
              </Form.Item>
            </Form>
          </IDPartnerForm>
        </El.FormContentWrapper>
        <El.FormContentWrapper isHidden={currentStep !== DNS_CHECK_STEPS.OWNERSHIP}>
          <El.VerifyTextWrapper>
            <ol>
              <li>
                <El.FormText>Log In to Your Domain Provider Account: (e.g., godaddy.com or namecheap.com)</El.FormText>
              </li>
              <li>
                <El.FormText>Generate a TXT DNS Record: Create a TXT DNS record with the subdomain _idpartner (e.g., _idpartner.idpartner.com)</El.FormText>
              </li>
              <li>
                <El.FormText>Input the Verification Text: Copy and paste the following text as the content for the TXT record, then click &apos;Verify&apos;:</El.FormText>
              </li>
            </ol>
          </El.VerifyTextWrapper>
          <El.TokenWrapper>{token}</El.TokenWrapper>
          <IDPartnerAlert
            showIcon
            type="info"
            description="Please, keep in mind that the verification may take from a few minutes to a few hours. If we can’t verify you immediately, use the Verify Later and try again later."
          />
        </El.FormContentWrapper>
      </El.DnsCheckForm>
      <El.Footer>
        {footerActions[currentStep][0]}
        <El.StepsWrapper>
          <StepsProgress steps={['Domain Name', 'Verify Ownership']} currentStep={dnsCheckFlow.indexOf(currentStep) + 1} />
        </El.StepsWrapper>
        {footerActions[currentStep][1]}
      </El.Footer>
    </El.DnsCheckContainer>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  account: auth.account,
});

export const DnsCheckForm = connect(mapStateToProps, null)(DnsCheckComponent);

import React, { FC } from 'react';
import { IDPartnerTextareaProps } from './types';
import { Counter, CurrentCount, IDPartnerTextareaContainer, IDPartnerTextareaStyled, TotalCount } from './idpartner-textarea.css';

export const IDPartnerTextarea: FC<IDPartnerTextareaProps> = props => {
  const { value, maxLength, isShowCounter } = props;
  return (
    <IDPartnerTextareaContainer>
      {isShowCounter && (
        <Counter>
          <CurrentCount>{value?.toString().length || 0}</CurrentCount>
          {' / '}
          <TotalCount>{maxLength}</TotalCount>
        </Counter>
      )}
      <IDPartnerTextareaStyled {...props} />
    </IDPartnerTextareaContainer>
  );
};

import React, { FC } from 'react';
import { PageHeader } from '../components/page-header/page-header';
import { MembersList } from '../components/members-list/members-list';
import { PageWrapper } from '../components/layouts/main-layout/main-layout/main-layout.css';

export const Team: FC = () => (
  <PageWrapper>
    <PageHeader title="Team" />
    <MembersList />
  </PageWrapper>
);

import React, { FC } from 'react';
import { Select } from 'antd';
import { StyledSelect, SelectArrow } from './idpartner-multiselect.css';
import { IDPartnerSelectProps } from './types';

const { Option } = Select;

export const IDPartnerMultiselect: FC<IDPartnerSelectProps> = props => {
  const { options } = props;
  return (
    <StyledSelect {...props} popupClassName="idpartner-popup" suffixIcon={<SelectArrow />}>
      {options.map(item => (
        <Option className="idpartner-option" value={item.value}>
          {item.name}
        </Option>
      ))}
    </StyledSelect>
  );
};

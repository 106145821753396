import styled, { css } from 'styled-components';
import { bodyLargeRegular, h3 } from '../../common.css';

export const InvoiceWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 2px solid ${props => props.theme['border-100']};
  border-radius: 16px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ItemStatus = styled.div<{ status: string }>`
  background: ${props => props.theme.warning};
  border-radius: 6px;
  padding: 0 8px;
  ${props =>
    props.status === 'paid' &&
    css`
      background: ${props => props.theme.success};
    `}
`;

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 16px;
`;

export const ItemTitle = styled.div`
  ${h3};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 4px;
`;

export const ItemDescription = styled.div`
  color: ${props => props.theme['gray-400']};
  ${bodyLargeRegular};
`;

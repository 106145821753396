import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import { ErrorText, ModalItem } from './add-member-modal.css';
import { AddMemberModalProps } from './types';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { IDPartnerModal } from '../ui/idpartner-modal/idpartner-modal';
import { IDPartnerInput } from '../ui/idpartner-input/idpartner-input';

export const AddMemberModal: FC<AddMemberModalProps> = props => {
  const { onCancel, onSubmit } = props;
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValid) {
      setIsError(true);
      return;
    }
    onSubmit(email);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setEmail(e.target.value);
  };
  return (
    <IDPartnerModal {...props} key="add-member" title="Add member" footer={null} destroyOnClose open>
      <ModalItem>Send invite to email</ModalItem>

      <ModalItem>
        <IDPartnerInput placeholder="johndoe@mail.com" value={email} onChange={handleChange} />
        {isError && <ErrorText>Invalid email</ErrorText>}
      </ModalItem>

      <Row justify="space-between">
        <Col>
          <IDPartnerButton size="large" type="default" onClick={onCancel}>
            Cancel
          </IDPartnerButton>
        </Col>

        <Col>
          <IDPartnerButton size="large" type="primary" onClick={handleSubmit}>
            Send Invite
          </IDPartnerButton>
        </Col>
      </Row>
    </IDPartnerModal>
  );
};

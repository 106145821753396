import React, { FC } from 'react';
import c from 'classnames';
import { StepsProgressProps } from './types';
import { Step, StepName, StepPoint, StepsProgressStyled, Line } from './steps-progress.css';

export const StepsProgress: FC<StepsProgressProps> = ({ steps, currentStep }) => (
  <StepsProgressStyled>
    {steps.map((step, index) => (
      <>
        <Step key={step}>
          <StepName isNotPassed={currentStep < index + 1} isFirstStep={index === 0} isLastStep={steps.length === index + 1}>
            {step}
          </StepName>
          <StepPoint className={c({ current: currentStep - 1 === index, passed: index < currentStep - 1 })} />
        </Step>
        {index !== steps.length - 1 && <Line className={c({ passed: index < currentStep - 1 })} />}
      </>
    ))}
  </StepsProgressStyled>
);

import React, { FC } from 'react';
import { AuthSuccessProps } from './types';
import { AuthSuccessStyled, Illustration, Message, Title } from './signup-success.css';
import signupSuccess from '../../assets/signup-success.svg';

export const SignupSuccess: FC<AuthSuccessProps> = () => (
  <AuthSuccessStyled>
    <Illustration>
      <img src={signupSuccess} alt="success" />
    </Illustration>
    <Title>Account Created</Title>
    <Message>Please check your email inbox for a verification link to complete your account setup.</Message>
  </AuthSuccessStyled>
);

import React, { FC, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as El from './reset-submit-form-redesign.css';
import { IDPartnerButton } from '../../components/ui/idpartner-button/idpartner-button';
import { IDPartnerForm } from '../../components/ui/idpartner-form/idpartner-form';
import { passwordChange } from '../../redux/auth/async';
import { IDPartnerAlert } from '../../components/ui/idpartner-alert/idpartner-alert';
import { IDPartnerInput } from '../../components/ui/idpartner-input/idpartner-input';
import { UpdatePasswordSuccess } from '../../components/update-password-success/update-password-success';
import { ResetSubmitFormProps } from './types';

const initialData = {
  password: '',
};
const ResetSubmitForm: FC<RouteComponentProps<ResetSubmitFormProps>> = props => {
  const {
    match: {
      params: { token },
    },
    history,
  } = props;
  const [form] = Form.useForm();
  const [error, setError] = useState<string>('');
  const [complete, setComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangePassword = async () => {
    const validationResult = await form.validateFields();
    const isValid = !validationResult.errorFields || !validationResult.errorFields.length;
    if (isValid) {
      try {
        const values = form.getFieldsValue();
        setLoading(true);
        await passwordChange(token, values.password);
        setLoading(false);
        setComplete(true);
      } catch (e) {
        console.error(e);
        setError('Something went wrong');
        setLoading(false);
      }
    }
  };

  const handleContinue = () => {
    history.push('/login');
  };

  if (complete) {
    return (
      <El.ResetContainer>
        <El.FormWrapper>
          <UpdatePasswordSuccess onContinue={handleContinue} />
        </El.FormWrapper>
      </El.ResetContainer>
    );
  }

  return (
    <>
      <El.FormTitle>Now we know that it’s you!</El.FormTitle>
      <El.FormDescription>Let’s update your password now. Please, make sure you’ve saved it in some safe place</El.FormDescription>
      {error && <IDPartnerAlert showIcon description={error} type="error" />}
      <IDPartnerForm>
        <Form layout="vertical" initialValues={initialData} form={form} requiredMark="optional">
          <Row gutter={10}>
            <Col span="24">
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter password!',
                  },
                  {
                    required: true,
                    message: 'Password is too weak',
                    pattern: /^(?=.*\d)(?=.*\w)(?=.*[^\d\w])(?!.*\s).{8,}$/,
                  },
                ]}
              >
                <IDPartnerInput type="password" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span="24">
              <Form.Item
                label="Repeat Password"
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <IDPartnerInput type="password" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </IDPartnerForm>

      <El.ButtonsWrapper>
        <IDPartnerButton block type="primary" onClick={handleChangePassword} disabled={loading}>
          Update Password
        </IDPartnerButton>
      </El.ButtonsWrapper>
    </>
  );
};

export const ResetSubmitFormRedesign = withRouter(ResetSubmitForm);

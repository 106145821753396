import styled from 'styled-components';
import noAppsIcon from '../../../../assets/icons/no-apps.svg';
import prodAccessIcon from '../../../../assets/icons/prod-access.svg';

export const ApplicationsGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
`;

export const AppCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #d9dde5;
  background: #fff;
  max-width: 363px;
  width: 363px;
  box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, 0.05);
  padding: 32px;
  gap: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    background: #f0f2f5;
    transform: scale(1.05);
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  &:hover {
    background: #c0c6c6;
  }
`;

export const AppTitle = styled.div`
  color: #101319;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.div`
  color: #9da3b2;
  font-family: Public Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

const getColorByType = (type: 'common' | 'warning' | 'error' | 'success') => {
  switch (type) {
    case 'warning':
      return '#D2A106';
    case 'error':
      return '#DA1E28';
    case 'success':
      return '#24A148';
    default:
      return '#101319';
  }
};

export const InfoDescription = styled.div<{ type?: 'common' | 'warning' | 'error' | 'success' }>`
  color: ${props => getColorByType(props.type)};
  font-family: Public Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 21.56px */
  display: flex;
`;
export const NoAppsScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 554px;
  margin: auto;
  margin-top: 150px;
`;

export const NoAppsIcon = styled.div`
  background: url('${noAppsIcon}') center no-repeat;
  width: 268px;
  height: 160px;
  margin-bottom: 26px;
`;

export const NoAppsTitle = styled.div`
  color: #424242;
  font-family: Public Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const NoAppsText = styled(NoAppsTitle)`
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
`;

export const NotReadyForProdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 744px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 554px;
  margin: 0 auto;
`;

export const ProdAccessIcon = styled.div`
  background: url('${prodAccessIcon}') center no-repeat;
  width: 268px;
  height: 180px;
  margin-bottom: 26px;
`;

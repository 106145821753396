import React, { FC, useMemo, useState } from 'react';
import { FormInstance, TabsProps } from 'antd';
import * as El from './app-settings.css';
import { QuickStart } from './quick-start/quick-start';
import { IDPartnerTabs } from '../../../components/ui/idpartner-tabs/idpartner-tabs';
import { Settings } from './settings/settings';
import { IDPartnerButton } from '../../../components/ui/idpartner-button/idpartner-button';
import { Icon } from '../../../components/icon/icon';
import plusWhiteIcon from '../../../assets/icons/add-button-white.svg';
import { Application, LanguagesTypes } from '../../../redux/applications/types';
import { Certificates } from './certificates/certificates';
import { AddCertificateModal } from '../../applications/components/add-certificate-modal/add-certificate-modal';
import { SettingsModeType } from '../application-wizard';

type AppSettingsProps = {
  application: Application | undefined;
  form: FormInstance<any>;
  settingsMode: SettingsModeType;
  selectedLanguage: LanguagesTypes;
  onSettingsChange: (key: string) => void;
};
export const AppSettings: FC<AppSettingsProps> = ({ application, form, selectedLanguage, settingsMode, onSettingsChange }) => {
  const [isAddCertModalVisible, setIsAddCertModalVisible] = useState(false);

  const items: TabsProps['items'] = useMemo(() => {
    const preparedItems = [];
    if (application?.language) {
      preparedItems.push({
        key: 'quick_start',
        label: 'Quick Start',
        children: <QuickStart selectedLanguage={selectedLanguage} application={application} />,
      });
    }
    preparedItems.push({
      key: 'settings',
      label: 'Settings',
      children: <Settings form={form} application={application} />,
    });

    if (application?.jwks_behaviour === 'self_hosted_jwks' || application?.certificates?.length) {
      preparedItems.push({
        key: 'certificates',
        label: 'Certificates',
        children: <Certificates application={application} />,
      });
    }
    return preparedItems;
  }, [application]);

  const handleAddModalClose = () => {
    setIsAddCertModalVisible(false);
  };

  const OperationsSlot: Record<any, React.ReactNode> = {
    right: (
      <IDPartnerButton type="primary" icon={<Icon url={plusWhiteIcon} iconWidth="24" iconHeight="24" />} onClick={() => setIsAddCertModalVisible(true)}>
        NEW CERTIFICATE
      </IDPartnerButton>
    ),
  };

  return (
    <El.AppSettingsWrapper>
      <El.TitleWrapper>
        <El.TitleText>{application ? application?.name : 'Create new application'}</El.TitleText>
        <El.SubtitleText>{application ? `Client ID: ${application?.client_id}` : ''}</El.SubtitleText>
        <El.TabsWrapper>
          <IDPartnerTabs defaultActiveKey={settingsMode} items={items} onChange={onSettingsChange} tabBarExtraContent={settingsMode === 'certificates' ? OperationsSlot : {}} />
        </El.TabsWrapper>
      </El.TitleWrapper>
      {isAddCertModalVisible && <AddCertificateModal open application={application} onCancel={handleAddModalClose} />}
    </El.AppSettingsWrapper>
  );
};

import React, { FC } from 'react';
import { CustomPicker } from 'react-color';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import Dropdown from 'antd/lib/dropdown';
import * as El from './idpartner-colorpicker.css';
import { IDPartnerInput } from '../idpartner-input/idpartner-input';
import { IDPartnerColorpickerProps } from './types';

const CustomisedPicker = CustomPicker(({ hex, onChange, ...rest }) => {
  const inputStyles = {
    input: {
      border: 'none',
      width: '100%',
    },
  };
  return (
    <El.StyledPanel>
      <El.ColorBlockWrapper>
        <El.ColorBlock style={{ background: hex }} />
        <El.InputWrapper>
          <EditableInput style={inputStyles} value={hex} onChange={onChange} {...rest} />
        </El.InputWrapper>
      </El.ColorBlockWrapper>

      <El.SaturationWrapper>
        <Saturation onChange={onChange} {...rest} />
      </El.SaturationWrapper>

      <El.HueWrapper>
        <Hue onChange={onChange} {...rest} />
      </El.HueWrapper>
    </El.StyledPanel>
  );
});

export const IDPartnerColorpicker: FC<IDPartnerColorpickerProps> = ({ onChange, value, ...rest }) => {
  const [internalColor, setInternalColor] = React.useState(value);

  const handleChange = (changingColor: any) => {
    setInternalColor(changingColor.hex);

    if (onChange) {
      onChange(changingColor.hex);
    }
  };

  const onInputChange = (e: any) => {
    setInternalColor(e.target.value);
    onChange(e.target.value);
  };

  const custom = <CustomisedPicker color={internalColor} onChange={handleChange} />;

  return (
    <IDPartnerInput
      {...rest}
      value={value}
      onChange={onInputChange}
      prefix={
        <Dropdown trigger={['click']} overlay={custom}>
          <El.StyledButton style={{ background: internalColor }}> </El.StyledButton>
        </Dropdown>
      }
    />
  );
};

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { IDPartnerModal } from '../../../../components/ui/idpartner-modal/idpartner-modal';
import { ModalText } from './delete-provider-modal.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { DeleteProviderModalProps } from './types';

export const DeleteProviderModal: FC<DeleteProviderModalProps> = props => {
  const { onCancel, onSubmit, provider } = props;
  return (
    <IDPartnerModal {...props} key="delete-provider" title={`Delete ${provider.name}?`} footer={null} destroyOnClose>
      <ModalText>Are you sure you want to delete {provider.name}?</ModalText>
      <Row justify="space-between">
        <Col>
          <IDPartnerButton size="large" type="default" onClick={onCancel}>
            Cancel
          </IDPartnerButton>
        </Col>

        <Col>
          <IDPartnerButton size="large" danger onClick={onSubmit}>
            Delete
          </IDPartnerButton>
        </Col>
      </Row>
    </IDPartnerModal>
  );
};

import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import { TableHeadingProps } from './types';
import { SearchCol, SearchIcon, TableHeadingStyled } from './table-heading.css';
import { IDPartnerInput } from '../../../../components/ui/idpartner-input/idpartner-input';
import { CreateProviderModal } from '../create-provider-modal/create-provider-modal';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { Provider } from '../../../../redux/providers/types';
import { Icon } from '../../../../components/icon/icon';
import plusWhiteIcon from '../../../../assets/icons/add-button-white.svg';

export const TableHeading: FC<TableHeadingProps> = ({ createProvider, handleSearch }) => {
  const [isCreateAppVisible, setIsCreateAppVisible] = useState(false);

  const handleSubmit = (formData: Provider) => {
    createProvider(formData);
    // TODO: add real action to show processing and success states;
    setTimeout(() => {
      setIsCreateAppVisible(false);
    }, 500);
  };

  return (
    <TableHeadingStyled>
      <Row align="middle" justify="space-between">
        <Col>
          <SearchCol>
            <IDPartnerInput onChange={handleSearch} allowClear prefix={<SearchIcon />} width="100%" size="large" placeholder="Search..." />
          </SearchCol>
        </Col>
        <Col>
          <Row>
            <Col>
              <IDPartnerButton icon={<Icon url={plusWhiteIcon} iconWidth="24" iconHeight="24" />} type="primary" onClick={() => setIsCreateAppVisible(true)} style={{ marginRight: 16 }}>
                REGISTER TRUST PLATFORM
              </IDPartnerButton>
            </Col>
            <Col>{isCreateAppVisible ? <CreateProviderModal open={isCreateAppVisible} onCancel={() => setIsCreateAppVisible(false)} onSubmit={handleSubmit} /> : null}</Col>
          </Row>
        </Col>
      </Row>
    </TableHeadingStyled>
  );
};

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { ModalText } from './delete-member-modal.css';
import { DeleteAppModalProps } from './types';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { IDPartnerModal } from '../ui/idpartner-modal/idpartner-modal';

export const DeleteMemberModal: FC<DeleteAppModalProps> = props => {
  const { onCancel, onSubmit } = props;
  return (
    <IDPartnerModal {...props} key="delete-app" title="Delete member?" footer={null} destroyOnClose open>
      <ModalText>Are you sure you want to delete the member from team?</ModalText>
      <Row justify="space-between">
        <Col>
          <IDPartnerButton size="large" type="default" onClick={onCancel}>
            Cancel
          </IDPartnerButton>
        </Col>

        <Col>
          <IDPartnerButton size="large" danger onClick={onSubmit}>
            Delete member
          </IDPartnerButton>
        </Col>
      </Row>
    </IDPartnerModal>
  );
};

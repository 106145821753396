import { css } from 'styled-components';

export const h1 = css`
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0;
`;

export const h2 = css`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const h3 = css`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const bodyLarge = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
`;

export const bodyLargeRegular = css`
  ${bodyLarge};
  font-weight: 400;
`;

export const bodyLargeMedium = css`
  ${bodyLarge};
  font-weight: 500;
`;

export const bodySmall = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
`;

export const bodySmallRegular = css`
  ${bodySmall};
  font-weight: 400;
`;

export const bodySmallMedium = css`
  ${bodySmall};
  font-weight: 500;
`;

export const bodyTiny = css`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
`;

export const bodyTinyRegular = css`
  ${bodyTiny};
  font-weight: 400;
`;

export const bodyTinyMedium = css`
  ${bodyTiny};
  font-weight: 500;
`;

export const digitsSmall = css`
  font-size: 10px;
  line-height: 14px;
`;

export const digitsSmallMedium = css`
  ${digitsSmall};
  font-weight: 500;
`;

import React, { FC, Fragment, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { ColumnsType } from 'antd/es/table';
import { connect } from 'react-redux';
import { Tooltip, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IDPartnerTable } from '../ui/idpartner-table/idpartner-table';
import * as El from './members-list.css';
import { AsyncComponent } from '../async-component/async-component';
import { ApplicationState } from '../../redux/store';
import { VerificationEvent } from '../../redux/logs/types';
import { fetchMembers as fetchMembersRedux, fetchMoreMembers as fetchMoreMembersRedux } from '../../redux/group/async';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';
import { DeleteMemberModal } from '../delete-member-modal/delete-member-modal';
import { AddMemberModal } from '../add-member-modal/add-member-modal';
import { Api } from '../../modules/utils/api';

const mapStateToProps = ({ group }: ApplicationState) => ({
  members: group.members,
  hasMore: group.hasMore,
  nextCursor: group.nextCursor,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMembers: bindActionCreators(fetchMembersRedux, dispatch),
  fetchMoreMembers: bindActionCreators(fetchMoreMembersRedux, dispatch),
});

type AllProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const LogsListComponent: FC<AllProps> = props => {
  const [deletingMember, setDeletingMember] = useState(null);
  const [isShowAddMemberModal, setIsShowAddMemberModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const { fetchMembers, fetchMoreMembers, members, hasMore, nextCursor } = props;

  const handleGetMore = () => {
    fetchMoreMembers(nextCursor, pageSize);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    fetchMembers(undefined, pageSize);
  };

  const onRemoveClick = (entity: any) => {
    setDeletingMember(entity);
  };

  const onCloseRemoveModal = () => setDeletingMember(null);

  const onAddMemberClick = () => setIsShowAddMemberModal(true);

  const onCloseAddMemberModal = () => setIsShowAddMemberModal(false);

  const handleAddMemberModal = async (email: string) => {
    const api = new Api();
    try {
      await api.sendGroupInvitation(email);
      fetchMembers(nextCursor, pageSize);
      notification.success({ message: 'Invite sent successfully' });
      setIsShowAddMemberModal(false);
    } catch (e) {
      const errorMsg = e.response?.data?.error || 'Please check email and try again.';
      notification.error({ message: 'Invite sending failed.', description: errorMsg });
    }
  };

  const handleDeleteMember = async () => {
    const api = new Api();
    try {
      await api.deleteGroupUser(deletingMember.id);
      fetchMembers(undefined, pageSize);
      notification.success({ message: 'Member deleted successfully' });
      setIsShowAddMemberModal(false);
    } catch {
      notification.error({ message: 'Member delete failed' });
    }
    setDeletingMember(null);
  };

  const columns: ColumnsType<VerificationEvent> = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.identity_provider_name.localeCompare(b.identity_provider_name),
    },
    {
      title: 'Status',
      dataIndex: 'invite_accepted_at',
      render: acceptedAt => <El.Status status={acceptedAt}>{acceptedAt ? 'Accepted' : 'Invited'}</El.Status>,
    },
    {
      title: '',
      render: (entity: any) => (
        <Fragment>
          <Tooltip placement="top" title="Delete member">
            <DeleteOutlined style={{ fontSize: 18 }} onClick={() => onRemoveClick(entity)} />
          </Tooltip>
        </Fragment>
      ),
    },
  ];

  return (
    <AsyncComponent message="Loading Group..." getAsyncActions={() => [fetchMembers(undefined, pageSize)]}>
      <El.MembersListWrapper>
        <El.FiltersWrapper>
          <IDPartnerButton type="primary" onClick={onAddMemberClick}>
            Add member
          </IDPartnerButton>
        </El.FiltersWrapper>
        <IDPartnerTable
          locale={{
            emptyText: 'no data',
          }}
          dataSource={members}
          columns={columns}
          onGetMore={handleGetMore}
          onPageSizeChange={handlePageSizeChange}
          hasMore={hasMore}
        />
        {deletingMember && <DeleteMemberModal onSubmit={handleDeleteMember} onCancel={onCloseRemoveModal} />}
        {isShowAddMemberModal && <AddMemberModal onSubmit={handleAddMemberModal} onCancel={onCloseAddMemberModal} />}
      </El.MembersListWrapper>
    </AsyncComponent>
  );
};

export const MembersList = connect(mapStateToProps, mapDispatchToProps)(LogsListComponent);

import React, { FC } from 'react';
import { RevokingStates } from './types';
import * as CommonEl from '../../../../components/modal-app-state/modal-loader.css';
import * as El from './revoke-certificate-modal.css';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import appErrorIcon from '../../../../assets/icons/app-error.svg';
import certSuccessIcon from '../../../../assets/icons/cert-success.svg';
import { IDPartnerAlert } from '../../../../components/ui/idpartner-alert/idpartner-alert';

type StatesProps = {
  state: RevokingStates;
  onClose: (e: any) => void;
  onRevoke: () => void;
  revokeReason: string;
  onReasonChange: (reason: string) => void;
};

const revokeReasonsMap = {
  UNSPECIFIED: 'Unspecified',
  SUPERSEDED: 'Superseded',
  PRIVILEGE_WITHDRAWN: 'Privilege Withdraw',
  KEY_COMPROMISE: 'Key Compromised',
  CESSATION_OF_OPERATION: 'Cessation of Operation',
  AFFILIATION_CHANGED: 'Affiliation changed',
  CERTIFICATE_AUTHORITY_COMPROMISE: 'Certificate authority compromised',
};

const revokeReasons = Object.entries(revokeReasonsMap).map(entry => ({ value: entry[0], name: entry[1] }));
export const States: FC<StatesProps> = ({ state, onClose, onRevoke, revokeReason, onReasonChange }) => {
  switch (state) {
    case 'loading':
      return (
        <CommonEl.AppStateWrapper>
          <CommonEl.LoaderAnimation />
          <CommonEl.LoaderText>Processing</CommonEl.LoaderText>
          <CommonEl.Description>Your certificate has been successfully revoked.</CommonEl.Description>
          <IDPartnerButton block onClick={onClose} type="primary">
            CLOSE
          </IDPartnerButton>
        </CommonEl.AppStateWrapper>
      );
    case 'error':
      return (
        <CommonEl.AppStateWrapper>
          <CommonEl.AppStateIllustration url={appErrorIcon} />
          <CommonEl.Description>Unfortunately, we were unable to revoke your certificate. Please try again.</CommonEl.Description>
          <El.ButtonsWrapper>
            <IDPartnerButton block onClick={onClose}>
              CANCEL
            </IDPartnerButton>
            <IDPartnerButton block onClick={onRevoke} type="primary">
              TRY AGAIN
            </IDPartnerButton>
          </El.ButtonsWrapper>
        </CommonEl.AppStateWrapper>
      );
    case 'success':
      return (
        <CommonEl.AppStateWrapper>
          <CommonEl.AppStateIllustration url={certSuccessIcon} />
          <CommonEl.Description>Your certificate revocation process has started. To reflect this change, you may need to refresh the page until the process is completed.</CommonEl.Description>
          <IDPartnerButton block onClick={onClose} type="primary">
            CLOSE
          </IDPartnerButton>
        </CommonEl.AppStateWrapper>
      );
    default:
      return (
        <CommonEl.AppStateWrapper>
          <IDPartnerAlert showIcon type="warning" description="Attention! This action can't be undone." />
          <El.Text>
            You are about to revoke the following certificate: <b>Fingerprint:ABCD1234efgKLmnop9012QRSTuvwx3456yz</b>
          </El.Text>
          <El.StyledDropdown items={revokeReasons} onSelect={item => onReasonChange(item)} />
          <El.ButtonsWrapper>
            <IDPartnerButton size="large" type="default" block onClick={onClose}>
              CANCEL
            </IDPartnerButton>
            <IDPartnerButton size="large" block type="primary" onClick={onRevoke} disabled={!revokeReason}>
              REVOKE
            </IDPartnerButton>
          </El.ButtonsWrapper>
        </CommonEl.AppStateWrapper>
      );
  }
};

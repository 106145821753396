import React, { FC } from 'react';
import { PageHeader } from '../components/page-header/page-header';
import { LogsList } from '../components/logs-list/logs-list';
import { PageWrapper } from '../components/layouts/main-layout/main-layout/main-layout.css';

export const Logs: FC = () => (
  <PageWrapper>
    <PageHeader title="Logs" />
    <LogsList />
  </PageWrapper>
);

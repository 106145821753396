import styled, { css } from 'styled-components';
import { bodyLargeRegular, h1 } from '../../common.css';
import arrowIcon from '../../assets/icons/arrow-left-gray.svg';

export const DnsCheckContainer = styled.div`
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
`;

export const Footer = styled.div`
  width: 100%;
  box-shadow: 0px -3px 50px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 34px 60px;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export const DnsCheckForm = styled.div`
  max-width: 743px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-900']};
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const FormDescription = styled(FormText)`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
  margin-bottom: 32px;
`;

export const FormContentWrapper = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `};
`;

export const StepsWrapper = styled.div`
  width: 100%;
  max-width: 718px;
  margin: 0 64px;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
`;

export const VerifyTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 24px;
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-900']};

  & ol {
    margin: 0;
  }
`;

export const TokenWrapper = styled.div`
  padding: 12px 16px;
  border: 2px solid ${props => props.theme['border-200']};
  border-radius: 8px;
  ${bodyLargeRegular};
  margin-bottom: 24px;
`;

export const ArrowIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background: url('${arrowIcon}') center no-repeat;
`;

import { Descope, getSessionToken } from '@descope/react-sdk';
import { Form, Spin } from 'antd';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { SignupSuccess } from '../../components/signup-success/signup-success';
import { createAccount, createAccountWithToken } from '../../redux/auth/async';
import { addProtocolToUrl } from '../../utils/url';
import * as El from './signup-form-redesign.css';
import { SIGNUP_STEPS } from './types';

const signupFlow = [SIGNUP_STEPS.SECURITY, SIGNUP_STEPS.INFORMATION, SIGNUP_STEPS.SUCCESS];

type RouterProps = {
  token: string;
  idpartnerSignup?: string;
};

export const SignupForm: FC<RouteComponentProps<RouterProps>> = props => {
  const {
    history,
    match: {
      params: { token },
    },
  } = props;
  const options = {
    clientId: process.env.SIGN_UP_CLIENT_ID,
    buttonScriptUri: process.env.BUTTON_SCRIPT_URL,
  };
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [currentStep, setCurrentStep] = useState(signupFlow[0]);
  const [authDataForm] = Form.useForm();
  const [additionalDataForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [disableName, setDisableName] = useState<boolean>(false);

  const emailByQuery = params.get('email');
  const idpartnerSignup = params.get('idpartnerSignup');

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(`${process.env.DASHBOARD_API_SERVICE_URL}/accounts/idpartner/oauth/identity_details`, { withCredentials: true });
        const { email = '', given_name = '', family_name = '' } = data;
        if (given_name && email) {
          setDisableName(true);
          authDataForm.setFieldValue('first_name', given_name);
          authDataForm.setFieldValue('last_name', family_name);
          authDataForm.setFieldValue('email', email);
        }
      } catch (e) {
        console.error('Error fetching data:', e);
      }
    }
    fetchData();
  }, []);

  const defaultValuesAuthData = useMemo(() => {
    let defaultData: any = {
      first_name: '',
      last_name: '',
      email: emailByQuery || '',
      password: '',
      confirm: '',
      terms_agreed: false,
    };

    if (!token) {
      defaultData = { ...defaultData, company_name: '', company_url: '' };
    }

    return defaultData;
  }, [token]);

  const onPreviousStep = () => {
    if (signupFlow.indexOf(currentStep) !== 0) {
      setError(undefined);
      setCurrentStep(prev => {
        const currentStepIndex = signupFlow.indexOf(prev);
        return currentStepIndex === 0 ? signupFlow[0] : signupFlow[currentStepIndex - 1];
      });
    } else {
      history.push('/');
    }
  };

  const onAgree = async () => {
    try {
      const authValues = authDataForm.getFieldsValue();
      const additionalValues = await additionalDataForm.validateFields();
      const handledCompanyUrl = addProtocolToUrl(additionalValues.company_url);

      setIsLoading(true);

      const newAccount = { ...authValues, ...additionalValues, confirm: undefined, company_url: handledCompanyUrl };

      if (token) {
        await createAccountWithToken(token, newAccount);
        mixpanel.track('dashboard_client:click:signup_group_user:successful');
      } else {
        await createAccount(newAccount);
        mixpanel.track('dashboard_client:click:signup:successful');
      }
      setCurrentStep(SIGNUP_STEPS.SUCCESS);

      setError(undefined);
    } catch (e) {
      const {
        response: { data },
      } = e;
      console.error(data);
      setError('Could not create a new account');
      mixpanel.track('dashboard_client:click:signup:unsuccessful');
    } finally {
      setIsLoading(false);
    }
  };

  const descopeOnSuccess = (e: any) => {
    const sessionToken = getSessionToken();
    axios
      .post(
        `${process.env.DASHBOARD_API_SERVICE_URL}/accounts/descope/signup/callback`,
        {
          user: e.detail.user,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionToken}`,
          },
        },
      )
      .then(response => {
        console.log('Signup successful:', response.data);
        setCurrentStep(SIGNUP_STEPS.SUCCESS);
        setError(undefined);
      })
      .catch(err => {
        console.error('Error posting to backend:', err);
        setError('Could not create a new account');
      });
  };

  const descopeOnError = (err: any) => {
    console.error('Error posting to backend:', err);
    setError('Could not create a new account');
  };

  if (isLoading) {
    return (
      <El.Loading>
        <Spin />
      </El.Loading>
    );
  }

  if (currentStep === SIGNUP_STEPS.SECURITY) {
    return (
      <SignUpForm
        error={error}
        idpartnerSignup={idpartnerSignup}
        options={options}
        currentStep={currentStep}
        disableName={disableName}
        emailByQuery={emailByQuery}
        authDataForm={authDataForm}
        defaultValuesAuthData={defaultValuesAuthData}
        onAgree={onAgree}
        onPreviousSte={onPreviousStep}
        descopeOnSuccess={descopeOnSuccess}
        descopeOnError={descopeOnError}
      />
    );
  }
  if (currentStep === SIGNUP_STEPS.SUCCESS) {
    return <SignupSuccess />;
  }
};

const SignUpForm: FC<any> = ({ descopeOnSuccess, descopeOnError }) => {
  const history = useHistory();
  return (
    <El.ContentWrapper>
      <El.FormTitle>Sign Up</El.FormTitle>
      <El.FormSubTitle>Create your account and set up bank-based ID verification to secure and streamline your application process.</El.FormSubTitle>
      <Descope flowId="idpartner-passkey-signup" theme="light" onSuccess={descopeOnSuccess} onError={descopeOnError} />
      <El.LinkToSignIn onClick={() => history.push('/login')}>
        <El.ArrowIcon />
        <span>Back to Login</span>
      </El.LinkToSignIn>
    </El.ContentWrapper>
  );
};

export const SignupFormRedesign = withRouter(SignupForm);

import styled from 'styled-components';
import { Spin } from 'antd';
import { FrownOutlined, LoadingOutlined } from '@ant-design/icons';

export const Spinner = styled(Spin as any)`
  display: block !important;
  left: 40% !important;
  position: absolute !important;
  top: 40% !important;
`;
export const ErrorIcon = styled(FrownOutlined as any)`
  font-size: 30px !important;
`;

export const LoadingIcon = styled(LoadingOutlined as any)`
  font-size: 30px !important;
`;

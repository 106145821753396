import styled from 'styled-components';

export const PageHeader = styled.div``;

export const PageTitle = styled.div`
  font-family: Visby CF, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

export const PageDescription = styled.div`
  color: ${props => props.theme['gray-400']};
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

import styled from 'styled-components';
import { IDPartnerDropdown } from '../../../../components/ui/idpartner-dropdown/idpartner-dropdown';

export const Text = styled.div`
  color: var(--system-secondary-text, #434343);
  /* Body Medium */
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 40px;
  margin-top: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;
  width: 100%;
`;

export const StyledDropdown = styled(IDPartnerDropdown)`
  &.ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    width: 100% !important;
    height: 48px !important;
  }
`;

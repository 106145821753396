import styled, { css } from 'styled-components';
import { Button } from 'antd';

const buttonType = (theme: any, type = 'default') =>
  ({
    primary: css`
      background: ${theme['button-primary-bg']};
      color: ${theme['button-primary-text']};
      border: 1px solid ${theme['button-primary-border']};
      &:hover {
        background: ${theme['button-primary-bg-hover']};
        color: ${theme['button-primary-text-hover']};
        border: 1px solid ${theme['button-primary-border-hover']};
      }
      &:active {
        background: ${theme['button-primary-bg-active']};
        color: ${theme['button-primary-text-active']};
        border: 1px solid ${theme['button-primary-border-active']};
      }
      &:focus {
        background: ${theme['button-primary-bg-focus']};
        border: 1px solid ${theme['button-primary-border-focus']};
        box-shadow: 0 0 0 3px ${theme['button-primary-shadow-focus']};
      }
      &[disabled] {
        background: ${theme['button-primary-bg-disabled']};
        color: ${theme['button-primary-text-disabled']};
        border: 1px solid ${theme['button-primary-border-disabled']};
      }
    `,
    default: css`
      background: transparent;
      color: ${theme['button-default-text']};
      border: 1px solid ${theme['button-default-border']};
      &:hover {
        background: ${theme['button-default-bg-hover']};
        color: ${theme['button-default-text-hover']};
        border: 1px solid ${theme['button-default-border-hover']};
      }
      &:active {
        background: ${theme['button-default-bg-active']};
        color: ${theme['button-default-text-active']};
        border: 1px solid ${theme['button-default-border-active']};
      }
      &:focus {
        background: ${theme['button-default-bg-hover']};
        border: 1px solid ${theme['button-primary-border-focus']};
        box-shadow: 0 0 0 3px ${theme['button-primary-shadow-focus']};
      }
      &[disabled] {
        background: transparent;
        color: ${theme['button-default-text']};
        border: 1px solid ${theme['button-default-border']};
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
          background: transparent;
          color: ${theme['button-default-text']};
          border: 1px solid ${theme['button-default-border']};
        }
      }
    `,
  }[type]);

const buttonSize = (theme: any, size = 'middle') =>
  ({
    large: css`
      height: 48px;
      font-size: 16px;
      line-height: 24px;
    `,
    middle: css`
      height: 40px;
      font-size: 16px;
      line-height: 24px;
    `,
  }[size]);

export const IDPartnerButton = styled(Button)`
  ${props => buttonType(props.theme, props.type)};
  ${props => buttonSize(props.theme, props.size)};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Public Sans, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;

  &.ant-btn-dangerous {
    background: ${props => props.theme['button-red-bg']};
    color: ${props => props.theme['button-red-text']};
    border: 1px solid ${props => props.theme['button-red-border']};
    &:hover {
      background: ${props => props.theme['button-red-bg-hover']};
      color: ${props => props.theme['button-red-text-hover']};
      border: 1px solid ${props => props.theme['button-red-border-hover']};
    }
    &:active {
      background: ${props => props.theme['button-red-bg-active']};
      color: ${props => props.theme['button-red-text-active']};
      border: 1px solid ${props => props.theme['button-red-border-active']};
    }
    &:focus {
      background: ${props => props.theme['button-red-bg-active']};
      color: ${props => props.theme['button-red-text-active']};
      border: 1px solid ${props => props.theme['button-red-border']};
      box-shadow: 0 0 0 3px ${props => props.theme['button-red-shadow-focus']};
    }
    &.disabled {
      background: ${props => props.theme['button-red-bg-disabled']};
      color: ${props => props.theme['button-red-text-disabled']};
      border: 1px solid ${props => props.theme['button-red-border-disabled']};
    }
  }
`;

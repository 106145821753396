import React, { FC } from 'react';
import { TabsProps } from 'antd';
import { TableHeadingProps } from './types';
import * as El from './table-heading.css';
import { IDPartnerInput } from '../../../../components/ui/idpartner-input/idpartner-input';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import plusWhiteIcon from '../../../../assets/icons/add-button-white.svg';
import { Icon } from '../../../../components/icon/icon';
import { IDPartnerTabs } from '../../../../components/ui/idpartner-tabs/idpartner-tabs';

export const TableHeading: FC<TableHeadingProps> = ({ handleSearch, onNewAppButtonClick, onSettingsClick, currentAppType, onChangeAppType, applications, account }) => {
  const items: TabsProps['items'] = [
    {
      key: 'sandbox',
      label: 'Sandbox',
    },
    {
      key: 'production',
      label: 'Production',
    },
  ];

  const getOperationSlot = () => {
    const isOnboardingPassed = account.domain_verified_at && account.lei_verified_at && account.merchant_code;
    if (currentAppType === 'production' && !isOnboardingPassed) return null;
    if (currentAppType === 'production' && !applications.length) {
      return {
        right: (
          <El.RightCorner>
            <IDPartnerButton onClick={onSettingsClick}>SETTINGS</IDPartnerButton>
          </El.RightCorner>
        ),
      };
    }
    return {
      right: (
        <El.RightCorner>
          <IDPartnerInput style={{ minWidth: 240 }} onChange={handleSearch} allowClear prefix={<El.SearchIcon />} width="100%" placeholder="Search..." />
          <IDPartnerButton icon={<Icon url={plusWhiteIcon} iconWidth="24" iconHeight="24" />} type="primary" onClick={onNewAppButtonClick}>
            {currentAppType === 'production' ? 'CREATE PRODUCTION APPLICATION' : 'CREATE SANDBOX APPLICATION'}
          </IDPartnerButton>
        </El.RightCorner>
      ),
    };
  };

  return (
    <El.TableHeadingStyled>
      <IDPartnerTabs activeKey={currentAppType} items={items} onChange={onChangeAppType} tabBarExtraContent={getOperationSlot()} style={{ width: '100%' }} />
    </El.TableHeadingStyled>
  );
};

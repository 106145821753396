import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { Route, Switch, RouteProps, Redirect, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { ApplicationState } from '../redux/store';
import { ApplicationsContainer } from '../modules/applications/containers/applications.container';
import { NotFoundPage } from '../pages/404';
import { MainLayoutContainer } from '../components/layouts/main-layout/main-layout/main-layout';
import { AccountType } from '../redux/auth/types';
import { featureFlags } from '../utils/featureFlags';
import { Logs } from '../pages/logs';
import { Onboarding } from '../modules/onboarding/onboarding';
import { Billing } from '../pages/billing';
import { TrustPlatformContainer } from '../modules/trust-platform/containers/trust-platform.container';
import { Team } from '../pages/team';

interface PropsFromDispatch {
  isAuthenticated: boolean;
  isTermsAgreed: boolean;
  isEmailVerified: boolean;
  isTotpEnabled: boolean;
}
interface DashboardPropsFromDispatch {
  isAuthenticated: boolean;
  accountId: AccountType['id'];
  isTermsAgreed: boolean;
  isEmailVerified: boolean;
  isTotpEnabled: boolean;
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  isAuthenticated: auth.account.email !== null,
  accountId: auth.account.id,
  isTermsAgreed: auth.account.terms_agreed,
  isEmailVerified: Boolean(auth.account.email_verified_at),
  isTotpEnabled: Boolean(auth.account.totp_enabled),
});

type AllProps = PropsFromDispatch & RouteProps;

interface TAppRouter extends AllProps {
  component: React.ComponentType<React.PropsWithChildren<RouteProps>>;
}

const PrivateRoute: React.FC<React.PropsWithChildren<TAppRouter>> = ({ component: Component, isAuthenticated, isTermsAgreed, isEmailVerified, isTotpEnabled, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!isAuthenticated) {
        return <Redirect to="/login" />;
      }
      if (!isTermsAgreed) {
        return <Redirect to="/terms" />;
      }
      if (!isEmailVerified) {
        return <Redirect to="/email-verification" />;
      }
      if (featureFlags.isMFAEnabled && !isTotpEnabled) {
        return <Redirect to="/otp/register" />;
      }

      return <Component {...props} />;
    }}
  />
);

export const PrivateDashboard: React.FC<React.PropsWithChildren<DashboardPropsFromDispatch>> = ({ isAuthenticated, accountId, isTermsAgreed, isEmailVerified, isTotpEnabled }) => {
  const location = useLocation();
  if (accountId) {
    mixpanel.identify(accountId.toString());
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('email_verification') === 'success') {
      notification.success({
        message: 'Email successfully verified',
      });
    }
  }, []);

  return (
    <MainLayoutContainer>
      <Switch>
        <PrivateRoute
          isTotpEnabled={isTotpEnabled}
          isEmailVerified={isEmailVerified}
          isTermsAgreed={isTermsAgreed}
          isAuthenticated={isAuthenticated}
          path="/"
          exact
          component={ApplicationsContainer}
        />
        <PrivateRoute
          isTotpEnabled={isTotpEnabled}
          isEmailVerified={isEmailVerified}
          isTermsAgreed={isTermsAgreed}
          isAuthenticated={isAuthenticated}
          path="/applications"
          exact
          component={ApplicationsContainer}
        />
        {featureFlags.isLogsEnabled && (
          <PrivateRoute isTotpEnabled={isTotpEnabled} isEmailVerified={isEmailVerified} isAuthenticated={isAuthenticated} isTermsAgreed={isTermsAgreed} exact path="/logs" component={Logs} />
        )}
        {featureFlags.isOnboardingEnabled && (
          <PrivateRoute isTotpEnabled={isTotpEnabled} isEmailVerified={isEmailVerified} isAuthenticated={isAuthenticated} isTermsAgreed={isTermsAgreed} path="/onboarding" component={Onboarding} />
        )}
        {featureFlags.isBillingEnabled && (
          <PrivateRoute isTotpEnabled={isTotpEnabled} isEmailVerified={isEmailVerified} isAuthenticated={isAuthenticated} isTermsAgreed={isTermsAgreed} exact path="/billing" component={Billing} />
        )}
        {featureFlags.isTrustPlatformEnabled && (
          <PrivateRoute
            isTotpEnabled={isTotpEnabled}
            isEmailVerified={isEmailVerified}
            isAuthenticated={isAuthenticated}
            isTermsAgreed={isTermsAgreed}
            exact
            path="/platform"
            component={TrustPlatformContainer}
          />
        )}
        {featureFlags.isTeamsEnabled && (
          <PrivateRoute isTotpEnabled={isTotpEnabled} isEmailVerified={isEmailVerified} isAuthenticated={isAuthenticated} isTermsAgreed={isTermsAgreed} exact path="/team" component={Team} />
        )}
        <PrivateRoute isTotpEnabled={isTotpEnabled} isEmailVerified={isEmailVerified} isTermsAgreed={isTermsAgreed} isAuthenticated={isAuthenticated} component={NotFoundPage} />
      </Switch>
    </MainLayoutContainer>
  );
};

export const Dashboard = connect(mapStateToProps)(PrivateDashboard);

import styled from 'styled-components';
import { bodyLargeRegular, h1 } from '../common.css';

export const TermsWrapper = styled.div`
  padding: 40px 64px;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
  text-align: center;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
`;

export const TermsButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  & button:not(:last-child) {
    margin-right: 7px;
  }
`;

export const TermsConditionsText = styled.div`
  width: 100%;
  color: ${props => props.theme['gray-800']};
  text-align: center;
`;

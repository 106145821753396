import styled from 'styled-components';
import { Radio } from 'antd';

export const RadioStyled = styled(Radio)`
  & .ant-radio {
    & .ant-radio-inner {
      border-width: 1px;
      border-color: ${props => props.theme['system-secondary-stroke']};

      width: 16px;
      height: 16px;
    }

    &.ant-radio-checked {
      & .ant-radio-inner {
        border-color: ${props => props.theme['system-primary-stroke']};
        &::after {
          background: ${props => props.theme['system-primary-stroke']};
          transform: scale(0.4);
        }
      }
    }
  }

  & span {
    font-family: Public Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%;
    color: ${props => props.theme['system-secondary-text']};
  }
`;

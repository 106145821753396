import styled from 'styled-components';
import { bodyLargeRegular, h1 } from '../../common.css';

export const LeiCheckContainer = styled.div`
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
`;

export const LeiCheckFormWrapper = styled.div`
  max-width: 743px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
`;

export const FormDescription = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
  margin-bottom: 32px;
`;

export const FormContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  width: 100%;
  box-shadow: 0px -3px 50px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 34px 60px;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

import { createAction } from 'typesafe-actions';
import { ApplicationsTypes, Application, Certificate } from './types';

export const fetchApplicationsAction = createAction(ApplicationsTypes.FETCH_APPLICATIONS, resolve => (data: { data: Application[]; hasLoaded: boolean }) => resolve(data));

export const updateApplicationAction = createAction(ApplicationsTypes.UPDATE_APPLICATION, resolve => (model: Application) => resolve(model));

export const createApplicationAction = createAction(ApplicationsTypes.CREATE_APPLICATION, resolve => (model: Application) => resolve(model));

export const deleteApplicationAction = createAction(ApplicationsTypes.DELETE_APPLICATION, resolve => (id: number) => resolve(id));

export const addCertificateAction = createAction(ApplicationsTypes.ADD_CERTIFICATE, resolve => (certificate: Certificate) => resolve(certificate));
export const updateCertificateAction = createAction(ApplicationsTypes.UPDATE_CERTIFICATE, resolve => (certificate: Certificate) => resolve(certificate));

import React, { FC, useState } from 'react';
import { Col, Form, Radio, Row } from 'antd';
import { noop } from 'lodash';
import { ApplicationFormStyled, Footer, TooltipIcon } from './edit-application-form.css';
import { EditApplicationFormProps, FieldStatus } from './types';
import { Application } from '../../../../redux/applications/types';
import { IDPartnerInput } from '../../../../components/ui/idpartner-input/idpartner-input';
import { IDPartnerTextarea } from '../../../../components/ui/idpartner-textarea/idpartner-textarea';
import { IDPartnerForm } from '../../../../components/ui/idpartner-form/idpartner-form';
import { IDPartnerButton } from '../../../../components/ui/idpartner-button/idpartner-button';
import { featureFlags } from '../../../../utils/featureFlags';
import { IDPartnerRadio } from '../../../../components/ui/idpartner-radio/idpartner-radio';
import { validateJWKS } from '../../../../components/ui/idpartner-modal/utils';

export const EditApplicationForm: FC<EditApplicationFormProps> = props => {
  const { application, form, onValuesChange = noop, onCancel = noop, formProps = {}, onSubmit, isHideFormButtons } = props;
  const authMethod = Form.useWatch('token_endpoint_auth_method', form);
  const jwksBehaviour = Form.useWatch('jwks_behaviour', form);
  const [isFormValid, setIsFormValid] = useState(true);
  const [jwksUriFieldStatus, setJwksUriFieldStatus] = useState<FieldStatus>({
    validateStatus: '',
    errorMsg: null,
    isDisabled: false,
  });
  const [jwksFieldStatus, setJwksFieldStatus] = useState<FieldStatus>({
    validateStatus: '',
    errorMsg: null,
    isDisabled: false,
  });

  const isEditForm = !!application;

  const defaultValues: Application = application || {
    name: '',
    logo_url: '',
    redirect_url: '',
    origin_url: '',
    logo: '',
    registration_redirect_url: '',
    authentication_callback: '',
    account_id: null,
    client_id: '',
    client_secret: '',
    created_at: '',
    id: null,
    skip_consent: false,
    updated_at: '',
    metadata: [],
    auth_mode: 'redirect',
    jwks: undefined,
    jwks_uri: undefined,
    jwks_behaviour: 'no_jwks',
    test: true,
    token_endpoint_auth_method: 'client_secret_basic',
    language: 'javascript',
  };

  const validateForm = async (): Promise<[boolean, boolean]> => {
    // eslint-disable-next-line no-underscore-dangle
    let _isFirstStepValid = false;
    // eslint-disable-next-line no-underscore-dangle
    let _isFormValid = false;

    if (!form) {
      return [_isFirstStepValid, _isFormValid];
    }

    try {
      const validationResult = await form.validateFields();
      _isFirstStepValid = !validationResult.errorFields || !validationResult.errorFields.length;
      if (isEditForm) {
        const jwksValidationResults = validateJWKS({ form, application, defaultValues });
        const isJwksValid = jwksValidationResults.jwks.validateStatus === 'success' && jwksValidationResults.jwks_uri.validateStatus === 'success';
        _isFormValid = (!validationResult.errorFields || !validationResult.errorFields.length) && isJwksValid;
        setJwksFieldStatus(jwksValidationResults.jwks);
        setJwksUriFieldStatus(jwksValidationResults.jwks_uri);
        setIsFormValid(_isFormValid);
      }
    } catch (e) {
      _isFirstStepValid = !e.errorFields.length;
      _isFormValid = !e.errorFields.length;
      setIsFormValid(_isFormValid);
    }

    return [_isFirstStepValid, _isFormValid];
  };

  const handleValuesChange = async (data: any) => {
    await validateForm();
    onValuesChange();
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    const [, _isFormValid] = await validateForm();

    if (_isFormValid) {
      onSubmit(e);
    }
  };

  const isShowJWKSSelector = authMethod === 'tls_client_auth';
  const isShowJWKSFields = jwksBehaviour === 'manual_jwks' || !isShowJWKSSelector;

  return (
    <ApplicationFormStyled>
      <IDPartnerForm>
        <Form layout="vertical" initialValues={defaultValues} form={form} onValuesChange={handleValuesChange} requiredMark="optional" {...formProps} preserve={false}>
          <Form.Item label="Sandbox Application" name="test" hidden>
            <IDPartnerInput allowClear />
          </Form.Item>

          <Row gutter={20}>
            <Col span="24">
              <Form.Item
                name="token_endpoint_auth_method"
                label="Auth method"
                tooltip={{ title: 'Select the auth method that your application will use. Once set this can not be edited.', icon: <TooltipIcon /> }}
                rules={[{ required: true }]}
              >
                <Radio.Group disabled={isEditForm}>
                  <IDPartnerRadio value="client_secret_basic">Client Secret</IDPartnerRadio>
                  <IDPartnerRadio value="tls_client_auth">Mutual TLS</IDPartnerRadio>
                  {isEditForm && <IDPartnerRadio value="private_key_jwt">Private Key JWT</IDPartnerRadio>}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span="24">
              <Form.Item
                label="Application name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name of your application!',
                  },
                ]}
              >
                <IDPartnerInput allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span="24">
              <Form.Item
                label="Application Logo"
                name="logo_url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a logo',
                  },
                ]}
              >
                <IDPartnerInput allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span="24">
              <Form.Item
                label="Origin URL"
                tooltip={{ title: 'When clicking the IDPartner button you will be redirected to this url to begin end user identity verification', icon: <TooltipIcon /> }}
                name="origin_url"
                rules={[
                  {
                    pattern:
                      defaultValues.test || featureFlags.isAllowPortForAppUrls
                        ? /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))/
                        : /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                    message: 'Please enter an origin URL',
                  },
                ]}
              >
                <IDPartnerInput allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span="24">
              <Form.Item
                label="Redirect URL"
                name="redirect_url"
                tooltip={{
                  title: (
                    <div>
                      After the user authenticates we will callback to this URL.
                      <br />
                      Insert comma separated URLs
                    </div>
                  ),
                  icon: <TooltipIcon />,
                }}
                rules={[
                  {
                    required: true,
                    pattern:
                      defaultValues.test || featureFlags.isAllowPortForAppUrls
                        ? /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))/
                        : /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                    message: 'Please enter a Redirect URL',
                  },
                ]}
              >
                <IDPartnerInput allowClear />
              </Form.Item>
            </Col>
          </Row>
          {featureFlags.isAllowAuthModeSelection && (
            <Row gutter={20}>
              <Col span="12">
                <Form.Item name="auth_mode" label="Auth flow mode">
                  <Radio.Group>
                    <IDPartnerRadio value="redirect">Redirect</IDPartnerRadio>
                    <IDPartnerRadio value="popup">Popup</IDPartnerRadio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}

          {isShowJWKSSelector && (
            <Row gutter={20}>
              <Col span="24">
                <Form.Item name="jwks_behaviour" label="JWKS behaviour" rules={[{ required: true }]}>
                  <Radio.Group>
                    <IDPartnerRadio value="no_jwks">No JWKS</IDPartnerRadio>
                    <IDPartnerRadio value="manual_jwks">Manual</IDPartnerRadio>
                    <IDPartnerRadio value="self_hosted_jwks">Self Hosted JWKS</IDPartnerRadio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}

          {isShowJWKSFields && (
            <>
              <Row gutter={20}>
                <Col span="24">
                  <Form.Item requiredMark={false} rules={[]} label="JWKS URI" name="jwks_uri" validateStatus={jwksUriFieldStatus.validateStatus} help={jwksUriFieldStatus.errorMsg}>
                    <IDPartnerInput allowClear disabled={jwksUriFieldStatus.isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span="24">
                  <Form.Item requiredMark={false} rules={[]} label="JWKS" name="jwks" validateStatus={jwksFieldStatus.validateStatus} help={jwksFieldStatus.errorMsg}>
                    <IDPartnerTextarea isShowCounter autoSize={{ minRows: 3, maxRows: 3 }} disabled={jwksFieldStatus.isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </IDPartnerForm>
      {!isHideFormButtons && (
        <Footer>
          <IDPartnerButton size="large" block type="default" onClick={onCancel}>
            CANCEL
          </IDPartnerButton>

          <IDPartnerButton size="large" block type="primary" onClick={handleFormSubmit} disabled={!isFormValid}>
            SAVE CHANGES
          </IDPartnerButton>
        </Footer>
      )}
    </ApplicationFormStyled>
  );
};

import axios, { AxiosPromise, AxiosInstance } from 'axios';
import { notification } from 'antd';
import { Application, Certificate } from '../../redux/applications/types';
import { AccountType } from '../../redux/auth/types';
import { VerificationEventsRequest, VerificationEventsResponse } from '../../redux/logs/types';
import { Provider } from '../../redux/providers/types';

export class Api {
  apiInstance: AxiosInstance;

  authorizedApiInstance: AxiosInstance;

  constructor(token?: string) {
    this.apiInstance = axios.create({
      baseURL: process.env.DASHBOARD_API_SERVICE_URL,
      withCredentials: true,
    });
    this.authorizedApiInstance = axios.create({
      baseURL: process.env.DASHBOARD_API_SERVICE_URL,
      withCredentials: true,
    });
    this.authorizedApiInstance.interceptors.response.use(
      response => response,
      error => {
        if ([401, 403].includes(error.response.status)) {
          notification.error({
            message: 'Session timed out',
            description: 'You will be redirected to the login page',
          });
          window.location.replace('/#/login');
        }
        throw error;
      },
    );
  }

  descopeLoginUser(sessionToken: string, email: string): AxiosPromise<{ data: AccountType }> {
    return this.apiInstance.post(
      '/auth/descope/login',
      { email },
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      },
    );
  }

  loginUser(email: string, password: string): AxiosPromise<{ data: AccountType }> {
    return this.apiInstance.post('/auth/login', { password, username: email });
  }

  resetPasswordRequest(email: string): AxiosPromise {
    return this.apiInstance.post('/auth/forgot', { email });
  }

  changePassword(token: string, password: string): AxiosPromise {
    return this.apiInstance.post(`/auth/reset/${token}`, { password });
  }

  createAccount(account: AccountType): AxiosPromise {
    return this.apiInstance.post('/accounts', account);
  }

  createGroupMember(token: string, user: any): AxiosPromise {
    return this.apiInstance.post(`/users/create/${token}`, user);
  }

  fetchAccount(id: number): AxiosPromise {
    return this.apiInstance.get(`/accounts/${id}`);
  }

  fetchSessionAccount(): AxiosPromise {
    return this.apiInstance.get('/accounts/');
  }

  updateAccount(account: AccountType): AxiosPromise {
    return this.authorizedApiInstance.put(`/accounts/${account.id}`, account);
  }

  createApplication(application: Application): AxiosPromise {
    return this.authorizedApiInstance.post('/applications', application);
  }

  fetchApplications(): AxiosPromise {
    return this.authorizedApiInstance.get('/applications');
  }

  deleteApplication(id: number): AxiosPromise {
    return this.authorizedApiInstance.delete(`/applications/${id}`);
  }

  updateApplication(application: Application): AxiosPromise {
    return this.authorizedApiInstance.put(`/applications/${application.id}`, application);
  }

  createProvider(application: Provider): AxiosPromise {
    return this.authorizedApiInstance.post('/providers', application);
  }

  fetchProviders(): AxiosPromise {
    return this.authorizedApiInstance.get('/providers');
  }

  updateProvider(id: number, provider: Provider): AxiosPromise {
    return this.authorizedApiInstance.put(`/providers/${id}`, provider);
  }

  deleteProvider(id: number): AxiosPromise {
    return this.authorizedApiInstance.delete(`/providers/${id}`);
  }

  fetchSessions(): AxiosPromise {
    return this.authorizedApiInstance.get('/sessions');
  }

  fetchMerchantCodes(): AxiosPromise<Array<{ category: string; mcc: string }>> {
    return this.authorizedApiInstance.get('/merchant_codes');
  }

  verifyEmail(token: string): AxiosPromise<{ verified: boolean }> {
    return this.apiInstance.post(`/accounts/verify/${token}`);
  }

  resendVerificationEmail(): AxiosPromise<{ verified: boolean }> {
    return this.apiInstance.post('/accounts/resend');
  }

  verifyLei(lei: string): AxiosPromise<{ verified: boolean }> {
    return this.authorizedApiInstance.post('/lei_verifications/verify_lei', { lei });
  }

  generateDomainToken(domainName: string): AxiosPromise<{ domain_verification_token: string }> {
    return this.authorizedApiInstance.post('/domain_verifications/generate_token', { domain_name: domainName });
  }

  verifyDomainToken(): AxiosPromise {
    return this.authorizedApiInstance.post('/domain_verifications/verify_token');
  }

  fetchVerificationEvents({ page, page_size, identity_provider_id, application_id }: VerificationEventsRequest): AxiosPromise<VerificationEventsResponse> {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page?.toString() || '1');

    if (page_size) {
      searchParams.append('page_size', page_size.toString());
    }
    if (identity_provider_id) {
      searchParams.append('identity_provider_id', identity_provider_id);
    }
    if (application_id) {
      searchParams.append('application_id', application_id.toString());
    }
    return this.authorizedApiInstance.get(`/verification_events?${searchParams.toString()}`);
  }

  getPaymentAccount(): AxiosPromise {
    return this.authorizedApiInstance.get('/payments/account');
  }

  getPaymentMethodsList(): AxiosPromise {
    return this.authorizedApiInstance.get('/payments/methods');
  }

  getOffersList(): AxiosPromise {
    return this.authorizedApiInstance.get('/payments/offers');
  }

  getUserSubscription(): AxiosPromise {
    return this.authorizedApiInstance.get('/payments/user_subscription');
  }

  subscribeUser(payload: any): AxiosPromise {
    return this.authorizedApiInstance.post('/payments/subscribe', payload);
  }

  cancelSubscription(subscriptionId: string): AxiosPromise {
    return this.authorizedApiInstance.delete(`/payments/subscribe/${subscriptionId}`);
  }

  createSetupIntent(payload: any): AxiosPromise {
    return this.authorizedApiInstance.post('/payments/setup_intent', payload);
  }

  getUserInvoicesList(): AxiosPromise {
    return this.authorizedApiInstance.get('/payments/user_invoices');
  }

  getCertificateUploadURL(applicationId: number): AxiosPromise {
    return this.apiInstance.post(`/applications/${applicationId}/certificates/upload_url`);
  }

  createCertificate(application: Application, payload: any): AxiosPromise {
    return this.apiInstance.post(`/applications/${application.id}/certificates`, payload);
  }

  getCertificate(application: Application, certId: Certificate['id']): AxiosPromise {
    return this.apiInstance.get(`/applications/${application.id}/certificates/${certId}`);
  }

  revokeCertificate(applicationId: Application['id'], certId: Certificate['id'], reason: string): AxiosPromise {
    return this.apiInstance.put(`/applications/${applicationId}/certificates/${certId}/revoke`, {
      revocation_reason: reason,
    });
  }

  getGroupMembers(cursor: string | undefined, pageSize: number): AxiosPromise {
    const search = new URLSearchParams();
    if (cursor) {
      search.set('cursor', cursor);
    }
    if (pageSize) {
      search.set('page_size', pageSize.toString());
    }
    return this.apiInstance.get(`/users?${search.toString()}`);
  }

  sendGroupInvitation(email: string): AxiosPromise {
    return this.apiInstance.post('/users/invite', { email });
  }

  deleteGroupUser(id: number): AxiosPromise {
    return this.apiInstance.delete(`/users/${id}`);
  }
}

import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { Provider } from './types';
import { Api } from '../../modules/utils/api';

import { withAuth } from '../util';
import { createProviderAction, deleteProviderAction, fetchProvidersAction, updateProviderAction } from './actions';

export const fetchProviders = () => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.fetchProviders().then((result: AxiosResponse<Provider[]>) => {
      dispatch(fetchProvidersAction({ data: result.data, hasLoaded: true }));
    }),
  );

export const updateProvider = (id: number, application: Provider) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.updateProvider(id, application).then((result: AxiosResponse<Provider>) => {
      dispatch(updateProviderAction(result.data));
    }),
  );

export const createProvider = (application: Provider) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.createProvider(application).then((result: AxiosResponse<Provider>) => {
      dispatch(createProviderAction(result.data));
    }),
  );

export const deleteProvider = (id: number) => (dispatch: Dispatch) =>
  withAuth(dispatch, (api: Api) =>
    api.deleteProvider(id).then((result: AxiosResponse<Provider>) => {
      dispatch(deleteProviderAction(id));
    }),
  );

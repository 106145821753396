import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { VerificationEventsRequest, VerificationEventsResponse } from './types';
import { Api } from '../../modules/utils/api';

import { withAuth } from '../util';
import { fetchVerificationEventsAction } from './actions';

export const fetchVerificationEvents =
  (params: VerificationEventsRequest = {}) =>
  (dispatch: Dispatch) =>
    withAuth(dispatch, (api: Api) =>
      api.fetchVerificationEvents(params).then((result: AxiosResponse<VerificationEventsResponse>) => {
        dispatch(fetchVerificationEventsAction({ data: result.data?.data, hasLoaded: true }));
      }),
    );

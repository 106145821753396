import styled from 'styled-components';
import { Select } from 'antd';
import { bodyLargeRegular } from '../../../common.css';
import arrowDownSvg from '../../../assets/icons/arrow-down.svg';
import arrowUpBlueSvg from '../../../assets/icons/arrow-up-blue.png';

export const SelectArrow = styled.div`
  width: 20px;
  height: 20px;
  background: url('${arrowDownSvg}') center no-repeat;
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    & .ant-select-selector {
      border: 2px solid ${props => props.theme['border-200']};
      border-radius: 8px;
      padding: 0px 16px;
      width: 100%;
      ${bodyLargeRegular};
      color: ${props => props.theme['gray-900']};
      min-height: 48px;
    }

    .ant-select-selection-item {
      background: white;
      border-radius: 8px;
      border: 2px solid ${props => props.theme['border-200']};
    }

    &.ant-select-open {
      & ${SelectArrow} {
        background: url('${arrowUpBlueSvg}') center no-repeat;
      }
    }

    &.ant-select-focused {
      & .ant-select-selector {
        border: 2px solid ${props => props.theme['light-blue-200']} !important;
        box-shadow: 0 0 0 3px rgba(74, 134, 232, 0.2) !important;
        color: ${props => props.theme['gray-900']} !important;
      }
    }

    & .ant-select-selection-item {
      ${bodyLargeRegular};
    }
    &:hover {
      & .ant-select-selector {
        border: 2px solid ${props => props.theme['border-300']};
      }
    }
  }
`;

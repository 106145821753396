import React, { FC, useEffect, useState } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { InputsMatrixProps } from './types';
import * as El from './inputs-matrix.css';
import { IDPartnerInput } from '../ui/idpartner-input/idpartner-input';
import { IDPartnerButton } from '../ui/idpartner-button/idpartner-button';

export const InputsMatrix: FC<InputsMatrixProps> = ({ value = {}, onChange }) => {
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    if (value) {
      setCurrentValue(Object.entries(value));
    }
  }, []);

  const handleAddRow = () => {
    if (currentValue.length) {
      if (currentValue[currentValue.length - 1][0] !== '') {
        setCurrentValue(prevState => [...prevState, ['', '']]);
      }
    } else {
      setCurrentValue(prevState => [...prevState, ['', '']]);
    }
  };

  const handleChange = (row: number, column: number, fieldValue: string) => {
    setCurrentValue(prev => {
      const changedPrev = prev;
      changedPrev[row][column] = fieldValue;
      if (onChange) {
        onChange(Object.fromEntries(changedPrev));
      }
      return changedPrev;
    });
  };

  const handleRemoveRow = (row: number) => {
    setCurrentValue(prev => {
      const mutationValue = prev;
      mutationValue.splice(row, 1);
      if (onChange) {
        onChange(Object.fromEntries(mutationValue));
      }
      return mutationValue;
    });
  };

  return (
    <El.InputsMatrixGrid key={`grid_${currentValue.length}`}>
      {currentValue.length ? (
        currentValue.map((pair, index) => (
          <>
            <IDPartnerInput key={`row_${index}_column_1`} defaultValue={pair[0]} onChange={e => handleChange(index, 0, e.target.value)} />
            <IDPartnerInput key={`row_${index}_column_2`} defaultValue={pair[1]} onChange={e => handleChange(index, 1, e.target.value)} />
            <IDPartnerButton key={`row_${index}_column_3`} danger onClick={() => handleRemoveRow(index)} block>
              <CloseOutlined />
            </IDPartnerButton>
          </>
        ))
      ) : (
        <El.NoDataRow>No data</El.NoDataRow>
      )}
      <El.AddRow>
        <IDPartnerButton onClick={handleAddRow} block>
          <span>Add Row</span>
          <PlusOutlined />
        </IDPartnerButton>
      </El.AddRow>
    </El.InputsMatrixGrid>
  );
};

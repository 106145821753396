import styled, { css } from 'styled-components';
import { bodyLargeRegular, h1, h2, h3 } from '../../common.css';
import arrowIcon from '../../assets/icons/arrow-left-gray.svg';

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  width: 1024px;
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.div`
  ${h1};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 16px;
  text-align: center;
`;

export const FormText = styled.div`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-900']};
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const FormSubtitle = styled.div`
  ${h3};
  color: ${props => props.theme['gray-400']};
  text-align: center;
  margin-bottom: 32px;
  font-weight: 400;
`;

export const FormDescription = styled(FormText)`
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
  text-align: center;
  margin-bottom: 32px;
`;

export const FormContentWrapper = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `};
`;

export const StepsWrapper = styled.div`
  width: 384px;
  padding: 8px 64px;
  margin: 0 auto;
  margin-bottom: 32px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

export const OfferInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

export const ItemTitle = styled.div`
  ${h2};
  color: ${props => props.theme['gray-900']};
  margin-bottom: 10px;
`;

export const ItemDescription = styled.div`
  color: ${props => props.theme['gray-400']};
  ${bodyLargeRegular};
`;

export const Price = styled.div`
  color: ${props => props.theme['gray-400']};
  width: 100%;
  margin-bottom: 1em;
  margin-top: 1em;
  text-align: center;
  ${h2};
`;

export const PaymentMethodsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  & button:not(:last-child) {
    margin-right: 7px;
  }
`;

export const NewPaymentMethodWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 2px solid ${props => props.theme['border-100']};
  border-radius: 16px;
  padding: 20px;

  & > div {
    width: 100%;
  }
`;

export const LinkToSignIn = styled.div`
  display: flex;
  align-items: center;
  align-self: baseline;
  margin-bottom: 24px;
  ${bodyLargeRegular};
  color: ${props => props.theme['gray-400']};
  cursor: pointer;
`;

export const ArrowIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background: url('${arrowIcon}') center no-repeat;
`;

import styled from 'styled-components';
import copyIcon from '../../assets/icons/copy-icon-white.svg';
import checkIcon from '../../assets/icons/copy-check-white.svg';

export const CodeWrapper = styled.div`
  border-radius: 8px;
  background: #232733;
  padding: 8px 28px 8px 16px;
  position: relative;
  cursor: pointer;
`;

export const CodeTextWrapper = styled.div`
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #f0f2f5;
`;

export const CopyButton = styled.div<{ isChecked: boolean }>`
  width: 24px;
  height: 24px;
  background: url('${props => (props.isChecked ? checkIcon : copyIcon)}') center no-repeat;
  cursor: pointer;

  position: absolute;
  top: 4px;
  right: 4px;
`;

import { Divider } from 'antd';
import styled from 'styled-components';
import logoIcon from '../../assets/logo_darkblue.svg';
import arrowIcon from '../../assets/icons/arrow-left-gray.svg';

export const ContentWrapper = styled.div`
  width: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    width: 100%;
  }
`;

export const IDPartnerButton = styled.div`
  width: 100%;
  margin-bottom: 14px;
`;
export const SignupDivider = styled(Divider)`
  .ant-divider-inner-text {
    font-size: 18px;
  }
  margin-bottom: 20px !important;
`;

export const Logo = styled.div`
  background-image: url('${logoIcon}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 154px;
  height: 45px;
  margin-bottom: 115px;
`;

export const FormTitle = styled.div`
  font-family: Public Sans, sans-serif;
  font-size: 28px;
  font-style: normal;
  margin-bottom: 30px;
  font-weight: 600;
  color: ${props => props.theme['gray-900']};
  width: 100%;
  text-align: center;
`;
export const FormSubTitle = styled.div`
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme['gray-900']};
  margin-bottom: 38px;
  width: 100%;
  text-align: center;
`;

export const FormInstructions = styled.div`
  font-family: Public Sans, sans-serif;
  font-size: 0.85em;
  color: #555;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.4;
  text-align: center;
`;
export const SignupSuccessTitle = styled.div`
  font-family: Public Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 113%;
  color: ${props => props.theme['gray-400']};
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
`;

export const SignupSuccessSubTitle = styled(SignupSuccessTitle)`
  font-size: 16px;
  margin-bottom: 50px;
  color: ${props => props.theme['gray-500']};
`;

export const ArrowIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background: url('${arrowIcon}') center no-repeat;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 64px;
`;

export const Loading = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

export const LinkToSignIn = styled.div`
  display: flex;
  align-items: center;
  align-self: baseline;
  margin-bottom: 24px;
  color: ${props => props.theme['gray-400']};
  cursor: pointer;
`;

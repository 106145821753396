import styled from 'styled-components';
import searchIcon from '../../../../assets/icons/search-icon.svg';

export const TableHeadingStyled = styled.div`
  margin-bottom: 40px;
`;

export const Col = styled.div``;

export const SearchCol = styled(Col)`
  width: 360px;
`;

export const SearchIcon = styled.div`
  width: 18px;
  height: 18px;
  background: url('${searchIcon}') center no-repeat;
  margin-right: 10px;
`;

export const SearchInput = styled.div``;

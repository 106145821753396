import { createAction } from 'typesafe-actions';
import { BillingTypes, BillingOfferType, UserSubscriptionType, PaymentProfileType, UserInvoice } from './types';
import { PaymentMethod } from '@stripe/stripe-js';

export const fetchOffersAction = createAction(BillingTypes.FETCH_OFFERS, resolve => (data: { offers: BillingOfferType[] }) => resolve(data));

export const fetchPaymentMethodsAction = createAction(BillingTypes.FETCH_PAYMENT_METHODS, resolve => (data: { paymentMethods: PaymentMethod[] }) => resolve(data));

export const fetchUserSubscriptionAction = createAction(BillingTypes.FETCH_USER_SUBSCRIPTION, resolve => (data: { userSubscription: UserSubscriptionType }) => resolve(data));

export const fetchPaymentProfileAction = createAction(BillingTypes.FETCH_PAYMENT_PROFILE, resolve => (data: { paymentProfile: PaymentProfileType }) => resolve(data));

export const fetchUserInvoicesAction = createAction(BillingTypes.FETCH_USER_INVOICES, resolve => (data: { userInvoices: UserInvoice[] }) => resolve(data));

import styled from 'styled-components';

export const CardsWrapper = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
  margin-bottom: 48px;
`;

export const QuickStartTitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
`;
export const InstructionsParagraph = styled.p`
  margin-bottom: 5px;
`;

export const Card = styled.div`
  border-radius: 10px;
  border: 1px solid var(--colors-gray-gray-3, #d9dde5);
  background: #fff;
  box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 40px;
  color: #000;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const CardTitle = styled.h3`
  margin-bottom: 52px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  img {
    margin-right: 5px;
  }
`;

export const CardItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  & .markdown {
    ol {
      padding-left: 0;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  gap: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
`;

export const ConfigArticle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  color: #000;
  font-family: Visby CF, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 113%; /* 27.12px */
  margin-bottom: 24px;
`;

export const Text = styled.div`
  color: #434343;
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

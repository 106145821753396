import styled from 'styled-components';
import { Switch } from 'antd';

export const IDPartnerSwitchStyled = styled(Switch)`
  & {
    background-color: transparent;
    border: 2px solid ${props => props.theme['border-200']};
    box-sizing: content-box;

    &.ant-switch-checked {
      background-color: ${props => props.theme['blue-primary-400']};

      &:focus {
        box-shadow: none;
      }
    }
  }
`;

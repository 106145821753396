import styled, { css } from 'styled-components';
import { Input } from 'antd';
import closeIcon from '../../../assets/icons/close-icon.svg';

const variantStyles = (theme: any, size = 'regular') =>
  ({
    small: css`
      height: 40px;
      padding: 8px 16px;
    `,
    large: css`
      height: 48px;
      padding: 12px 16px;
    `,
    regular: css`
      height: 40px;
      padding: 8px 16px;
    `,
  }[size]);

export const IDPartnerInputStyled = styled(Input)`
  ${props => variantStyles(props.theme, props.size)};
  border: 1px solid ${props => props.theme['border-200']};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: none;
  border-radius: 8px;
  background-color: ${props => props.theme['system-input-background']} !important;

  & input {
    background-color: ${props => props.theme['system-input-background']};
  }

  // if we have addonBefore or addonAfter props
  &.ant-input-group-wrapper {
    padding: 0;
    border: none;

    & > .ant-input-wrapper.ant-input-group {
      ${props => variantStyles(props.theme, props.size)};
      padding: 0;
      border: none;
      box-shadow: none;

      & > .ant-input-group-addon {
        color: ${props => props.theme['gray-300']};
        background: ${props => props.theme['gray-50']};
        border: 1px solid ${props => props.theme['border-200']};
        border-radius: 8px 0 0 8px;
      }

      & > .ant-input-affix-wrapper {
        ${props => variantStyles(props.theme, props.size)};
        border: 1px solid ${props => props.theme['border-200']};
        background-color: ${props => props.theme['system-input-background']} !important;
        border-left: 0;
        box-shadow: none;
        border-radius: 0 8px 8px 0;
      }
    }
  }

  // clear input button
  & .ant-input-clear-icon {
    width: 20px;
    height: 20px;
    background: url('${closeIcon}') center no-repeat;
    & .anticon-close-circle {
      display: none;
    }
  }

  // error state
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless)&,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless)&:hover {
    border-color: ${props => props.theme.error};
  }

  &:focus,
  &.ant-input-focused,
  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    border-color: ${props => props.theme['blue-primary-400']};
    box-shadow: none;
    border-right-width: 2px;
    outline: 0;
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  &:hover {
    border-color: ${props => props.theme['border-300']};
    border-right-width: 2px;
  }

  &::placeholder {
    color: ${props => props.theme['gray-300']};
  }
`;

import styled from 'styled-components';
import { bodySmallMedium } from '../../../../common.css';
import tooltipIcon from '../../../../assets/icons/tooltip-icon.svg';

export const ApplicationFormStyled = styled.div`
  & .ant-form-item-optional {
    font-size: 0;
    display: flex !important;
    align-items: end !important;

    &:after {
      content: 'Optional';
      ${bodySmallMedium};
      color: ${props => props.theme['gray-300']};
    }
  }
`;

export const Steps = styled.div`
  margin-bottom: 32px;
  padding: 0 65px;
`;

export const TooltipIcon = styled.div`
  background: url('${tooltipIcon}') center no-repeat;
  width: 16px;
  height: 16px;
`;

export const Footer = styled.div``;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const PreviewTitle = styled.div`
  margin-bottom: 6px;
  ${bodySmallMedium};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const AlertWrapper = styled.div`
  margin-bottom: 18px;
`;

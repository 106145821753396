import styled from 'styled-components';
import { Tabs } from 'antd';

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav::before {
    border-bottom: none;
  }
  & .ant-tabs-nav {
    margin-bottom: 32px;
  }
  & .ant-tabs-tab {
    color: #9da3b2;
    font-family: Public Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
`;
